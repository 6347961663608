import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const SingleAccordion = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        background: "transparent",
        maxWidth: "1000px",
        width: "100%",
        flexDirection: "column",
        gap: "23px",
        paddingBottom: "4rem",
        margin: "0 auto", // ✅ Centers the accordion horizontally
        justifyContent: "center", // ✅ Ensures it aligns properly
        alignItems: "center",
      }}
    >
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{
          width: "100%",
          borderRadius: "4px",
          background: "linear-gradient(180deg, #21174F 16.75%, #0E0B0E 140.05%)",
          boxShadow: "none",
          color: "#FFFFFF",
        }}
      >
        {/* Header */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#72ECEA" }} />}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "24px",
            gap: "32px",
            width: "100%",
            height: "78px",
            borderRadius: "4px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontStyle: "normal",
              fontWeight: 800,
              fontSize: "24px",
              lineHeight: "30px",
              textAlign: "left", // ✅ Ensures text is left-aligned
              width: "100%",
            }}
          >
            Terms and Conditions
          </Typography>
        </AccordionSummary>

        {/* Body */}
        <AccordionDetails
          sx={{
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start", // ✅ Left aligns text inside
            padding: "20px",
            width: "100%",
            borderRadius: "4px",
            background: "linear-gradient(180deg, rgba(33, 23, 79, 0.25) 16.75%, rgba(14, 11, 14, 0.25) 140.05%)",
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "26px",
              color: "#FFFFFF",
              width: "100%", // ✅ Fixes text overflow issues
              maxWidth: "950px",
              textAlign: "left",
            }}
          >
            Satellite Terms & Conditions:
            <br /> Stay up to date with our WhatsApp group, to be added contact 067 029 8025.
            <br /> Events with 20min levels may go down to 15min after the late registration period.
            <br /> A 10% rake and 4% gratuity/staffing fee will be charged for all events.
            <br /> Players may do a Re-buy/Double Re-buy when they are equal to or under starting stack. Double Add-ons are not available.
            <br /> Build your Bankroll Satellite – the accumulated prize pool will be shared between the top 3, with first place set at R5,000 minimum.
            <br /> Bankroll Satellite – R12,000 GTD: the accumulated prize pool will be shared between the top 3, with first place set at R6,000 minimum.
            <br /> Super High Roller Satellite: R50,000 Super High Roller Seat. Should we not reach R50,000, the next highest valued seat will be given.
            <br /> A game will start once we have 5 players. Should there not be 5 players at the start of play, a grace period of the first blind level will be given.
            <br /> Initial seats for an individual event won at the satellites are NOT TRANSFERABLE FOR CASH/ANOTHER PLAYER. Should a player be unable to attend the event, a voucher will be issued for future ShuffleUp Poker Events.
            <br /> Should a player win more than one seat into the same event, that seat can be used as a re-entry/rebuy into that event, be used towards other events, transferred to another player at no cost, or kept as a credit for future SUP/Montecasino events.
            <br /> FREE ROLL seats are not transferable. Should a player be unable to attend, a voucher will be issued that the player can use at future ShuffleUp Poker Events (including secondary seats).
            <br /> Shuffle Up Poker and Montecasino reserve the right to change or cancel an event at any time.
            <br /> Seats carried over from previous Series may not be used in Day 1A of the SA Poker Open Main Event. They can only be used in secondary days or the SA Poker Open Main Event.
            <br /> Montecasino Poker rules APPLY.
            <br /> For more information call Demi on 067 029 8025 / demi@shuffleuppoker.com.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SingleAccordion;
