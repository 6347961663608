import React, { useState, useEffect, useRef } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Button,
  Divider,
  Radio,
  CircularProgress,
  Avatar,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import SignatureCanvas from 'react-signature-canvas';
import { styled } from '@mui/material/styles';
import styles from './CheckoutStyles.module.css';

const CustomStepIconRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 22,
  alignItems: 'center',
}));

const CustomStepIcon = (props) => {
  const { active, completed, icon } = props;

  const iconClass = completed
    ? styles.stepCompleted
    : active
      ? styles.stepActive
      : styles.stepInactive;

  return (
    <CustomStepIconRoot>
      {completed ? (
        <CheckIcon className={`${styles.stepIcon} ${iconClass}`} />
      ) : (
        <Typography className={`${styles.stepNumber} ${iconClass}`} variant="body2">
          {icon}
        </Typography>
      )}
    </CustomStepIconRoot>
  );
};

const CanvasLayoutCheckout = () => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    'Product Selection',
    'Account & Billing Details',
    'Terms & Conditions',
    'Payment',
    'Debit Order Details',
  ];

  const packageMapping = {
    'family-plan': 0,
    'individual-plan': 1,
    'online-doctor-consultation': 2,
  };

  const [signatureData, setSignatureData] = useState(null);
  const signaturePadRef = useRef(null);
  const [searchParams] = useSearchParams();
  const packageParam = searchParams.get('package');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showProductDialog, setShowProductDialog] = useState(false);
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [dialogTimer, setDialogTimer] = useState(null);
  const [userId, setUserId] = useState(null);

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productDialogClosed, setProductDialogClosed] = useState(false);

  const [authToken, setAuthToken] = useState('');
  const [user, setUser] = useState(null);
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [sameAsPhysical, setSameAsPhysical] = useState(false);

  const [billingDetails, setBillingDetails] = useState({
    name: '',
    surname: '',
    email: '',
    mobile: '',
    physicalAddress: '',
    billingAddress: '',
  });

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('payfast');
  const [paymentInfo, setPaymentInfo] = useState({
    cardholderName: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
  });

  const [debitOrderDetails, setDebitOrderDetails] = useState({
    idNumber: '',
    debitDate: '',
    bankName: '',
    bankAccountType: '',
    bankAccountNumber: '',
    ipAddress: '',
    acceptedAt: '',
    mandateAccepted: false,
    electronicSignature: '',
  });

  const termsRef = useRef(null);
  const navigate = useNavigate();
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

  const [createAccountName, setCreateAccountName] = useState('');
  const [createAccountSurname, setCreateAccountSurname] = useState('');
  const [createAccountEmail, setCreateAccountEmail] = useState('');
  const [createAccountPassword, setCreateAccountPassword] = useState('');
  const [createAccountMobile, setCreateAccountMobile] = useState('');
  const [createAccountPhysicalAddress, setCreateAccountPhysicalAddress] = useState('');
  const [createAccountBillingAddress, setCreateAccountBillingAddress] = useState('');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
        const response = await axios.get(`${baseApi}/products`);
        const sortedProducts = [...response.data].sort((a, b) => a.name.localeCompare(b.name));
        setProducts(sortedProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
        setErrorList(['Failed to fetch products. Please try again later.']);
        setErrorDialogueBoxOpen(true);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    if (products.length > 0 && packageParam) {
      const index = packageMapping[packageParam];
      if (index !== undefined && index < products.length) {
        selectProduct(products[index]);
      } else {
        console.warn(`Invalid package parameter: ${packageParam}`);
      }
    }
  }, [products, packageParam]);

  useEffect(() => {
    if (products.length > 0) {
      const ids = products.map((product) => product._id);
      const uniqueIds = new Set(ids);
      if (uniqueIds.size !== ids.length) {
        console.warn('Duplicate IDs detected in products.');
      }
    }
  }, [products]);

  useEffect(() => {
    if (sameAsPhysical) {
      setBillingDetails((prev) => ({
        ...prev,
        billingAddress: prev.physicalAddress,
      }));
    } else {
      setBillingDetails((prev) => ({
        ...prev,
        billingAddress: '',
      }));
    }
  }, [sameAsPhysical, billingDetails.physicalAddress]);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const { data } = await axios.get('https://api.ipify.org?format=json');
        setDebitOrderDetails((prev) => ({ ...prev, ipAddress: data.ip }));
      } catch (error) {
        console.error('Error retrieving IP address:', error);
      }
    };

    fetchIpAddress();
  }, []);

  const clearSignature = () => {
    signaturePadRef.current.clear();
    setSignatureData(null);
  };

  const saveSignature = () => {
    if (!signaturePadRef.current.isEmpty()) {
      setSignatureData(signaturePadRef.current.toDataURL());
    } else {
      alert('Please provide a signature.');
    }
  };

  const handleNext = async () => {
    if (activeStep === 0 && !selectedProduct) {
      setErrorList(['Please select a product/package to proceed.']);
      setErrorDialogueBoxOpen(true);
      return;
    }

    if (activeStep === 1) {
      const requiredFields = [
        'name',
        'surname',
        'email',
        'mobile',
        'physicalAddress',
        'billingAddress',
      ];

      const missingFields = requiredFields.filter((field) => !billingDetails[field]);
      if (missingFields.length > 0) {
        setErrorList(['Please fill in all required billing details.']);
        setErrorDialogueBoxOpen(true);
        return;
      }

      if (billingDetails.password !== billingDetails.confirmPassword) {
        setErrorList(['Passwords do not match.']);
        setErrorDialogueBoxOpen(true);
        return;
      }
    }

    if (activeStep === 2 && !termsAccepted) {
      setErrorList(['You must accept the terms and conditions to proceed.']);
      setErrorDialogueBoxOpen(true);
      return;
    }

    if (activeStep === 3 || activeStep === 4) {
      if (!userId) {
        try {
          const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
          const userPayload = {
            email: billingDetails.email,
            mobile: billingDetails.mobile,
            username: billingDetails.username,
            password: billingDetails.password,
            address: billingDetails.physicalAddress,
            idNumber: debitOrderDetails.idNumber,
            confirmPassword: billingDetails.confirmPassword,
            firstName: billingDetails.name,
            lastName: billingDetails.surname,
            userType: 'Patient',
          };

          const response = await axios.post(`${baseApi}/users`, userPayload);
          const { user: newUserData } = response.data;
          setUser(newUserData);
          setUserId(newUserData._id);
        } catch (error) {
          console.error('Error creating or retrieving user:', error);
          setErrorList(['Unable to proceed due to user creation error.']);
          setErrorDialogueBoxOpen(true);
          return;
        }
      }
    }

    if (activeStep === 3) {
      if (paymentMethod === 'debitOrder') {
        setActiveStep((prev) => prev + 1);
        return;
      } else {
        handleFinish();
        return;
      }
    }

    if (activeStep === 4) {
      if (!debitOrderDetails.idNumber || !debitOrderDetails.mandateAccepted) {
        setErrorList(['Please complete the Debit Order details and accept the mandate.']);
        setErrorDialogueBoxOpen(true);
        return;
      }

      handleFinish();
      return;
    }

    if (activeStep === 0 && selectedProduct && !productDialogClosed) {
      setShowProductDialog(true);
      return;
    }

    setActiveStep((prev) => prev + 1);

    if (activeStep === 2) {
      setHasScrolledToBottom(false);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
    if (activeStep === 3 || activeStep === 4) {
      setHasScrolledToBottom(false);
    }
  };

  const handleFinish = async () => {
    try {
      setLoading(true);
      console.log('Starting checkout process...');
  
      if (!userId) {
        console.log('User is not logged in. Creating a new user...');
  
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
        const userPayload = {
          email: billingDetails.email,
          mobile: billingDetails.mobile,
          username: billingDetails.username,
          password: billingDetails.password,
          address: billingDetails.physicalAddress,
          idNumber: debitOrderDetails.idNumber,
          confirmPassword: billingDetails.confirmPassword,
          firstName: billingDetails.name,
          lastName: billingDetails.surname,
          userType: 'Patient',
        };
  
        try {
          const response = await axios.post(`${baseApi}/users`, userPayload);
          const { user: newUserData } = response.data;
  
          if (newUserData && newUserData._id && /^[a-fA-F0-9]{24}$/.test(newUserData._id)) {
            setUser(newUserData);
            setUserId(newUserData._id);
            console.log('New user created successfully with userId:', newUserData._id);
          } else {
            throw new Error('Invalid user data received from server during user creation.');
          }
        } catch (error) {
          console.error('Error creating a new user:', error);
          setErrorList(['Unable to create a new user. Please try again.']);
          setErrorDialogueBoxOpen(true);
          return;
        }
      }
  
      const orderPayload = {
        userId: userId,
        email: billingDetails.email || '',
        productId: selectedProduct?._id || null,
        productName: selectedProduct?.name || '',
        paid: false,
        status: 'pending',
        orderType: paymentMethod === 'debitOrder' ? 'debitOrder' : 'onlinePayment',
        price: selectedProduct?.price || 0,
        subscriptionInt: selectedProduct?.billingFrequency || 'Monthly',
        vat: calculateVAT(selectedProduct?.price || 0),
        total: calculateTotal(selectedProduct?.price || 0),
        timestamp: new Date(),
        bookingDetails: {
          bookingId: 'N/A',
        },
        billingDetails: {
          name: billingDetails.name || '',
          surname: billingDetails.surname || '',
          email: billingDetails.email || '',
          mobile: billingDetails.mobile || '',
          physicalAddress: billingDetails.physicalAddress || '',
          billingAddress: billingDetails.billingAddress || '',
        },
        paymentDetails: {
          transactionId: '',
          paymentMethod: paymentMethod || 'credit_card',
        },
        debitOrderDetails: paymentMethod === 'debitOrder'
          ? {
              idNumber: debitOrderDetails.idNumber || '',
              debitDate: debitOrderDetails.debitDate || '',
              bankName: debitOrderDetails.bankName || '',
              bankAccountType: debitOrderDetails.bankAccountType || '',
              bankAccountNumber: debitOrderDetails.bankAccountNumber || '',
              ipAddress: debitOrderDetails.ipAddress || '',
              mandateAccepted: debitOrderDetails.mandateAccepted || false,
              acceptedAt: debitOrderDetails.acceptedAt || '',
              electronicSignature: signatureData || '',
            }
          : {},
      };
  
      console.log('Submitting order with payload:', orderPayload);
  
      const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
      const orderResponse = await axios.post(`${baseApi}/orders`, orderPayload);
      console.log('Order created successfully:', orderResponse.data);
  
      if (paymentMethod === 'debitOrder') {
        navigate('/checkout/confirmation');
      } else {
        console.log('Initiating PayFast payment...');
        const paymentPayload = {
          ...orderPayload,
          return_url: `${window.location.origin}/payment/success`,
          cancel_url: `${window.location.origin}/payment/cancel`,
          notify_url: `${baseApi}/payfast/notify`,
        };
  
        const paymentResponse = await axios.post(`${baseApi}/payfast/initiate`, paymentPayload);
        console.log('Payment initiated:', paymentResponse.data);
  
        const parser = new DOMParser();
        const doc = parser.parseFromString(paymentResponse.data, 'text/html');
        const form = doc.querySelector('form');
  
        if (form) {
          const payfastForm = document.createElement('form');
          payfastForm.setAttribute('action', 'https://payfast.co.za/eng/process');
          payfastForm.setAttribute('method', 'POST');
  
          const inputs = form.querySelectorAll('input');
          inputs.forEach((input) => {
            payfastForm.appendChild(input.cloneNode(true));
          });
  
          document.body.appendChild(payfastForm);
          payfastForm.submit();
        } else {
          throw new Error('Payment form not found in the response.');
        }
      }
    } catch (error) {
      console.error('Error during checkout process:', error);
      setErrorList(['An unexpected error occurred. Please try again later.']);
      setErrorDialogueBoxOpen(true);
    } finally {
      setLoading(false);
      console.log('Checkout process completed.');
    }
  };
  

  const handleError = (error, defaultMessage) => {
    const errorMessage = error.response?.data?.message || defaultMessage;
    setErrorList([errorMessage]);
    setErrorDialogueBoxOpen(true);
  };

  const handleInputChange = (field) => (event) => {
    setBillingDetails((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleDebitOrderChange = (field) => (event) => {
    setDebitOrderDetails((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const selectProduct = (product) => {
    setSelectedProduct(product);
    if (!productDialogClosed) {
      setShowProductDialog(true);
    }
  };

  const handleCloseProductDialog = () => {
    setShowProductDialog(false);
    setProductDialogClosed(true);

    if (dialogTimer) {
      clearTimeout(dialogTimer);
      setDialogTimer(null);
    }
  };

  useEffect(() => {
    if (showProductDialog) {
      const timer = setTimeout(() => {
        handleCloseProductDialog();
      }, 5000);
      setDialogTimer(timer);
      return () => clearTimeout(timer);
    }
  }, [showProductDialog]);

  const calculateVAT = (price) => {
    const vatRate = 0.15;
    return price * vatRate;
  };

  const calculateTotal = (price) => {
    return price + calculateVAT(price);
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

      const loginResponse = await axios.post(`${baseApi}/login`, {
        email: loginEmail,
        password: loginPassword,
      });

      const { user: userData, token } = loginResponse.data;

      setAuthToken(token);
      setUser(userData);

      setBillingDetails((prev) => ({
        ...prev,
        username: userData.username,
        name: userData.name,
        surname: userData.surname,
        email: userData.email,
        mobile: userData.mobile,
        physicalAddress: userData.physicalAddress,
        billingAddress: userData.billingAddress,
      }));

      setShowLoginModal(false);
      setLoginEmail('');
      setLoginPassword('');
    } catch (error) {
      console.error('Login failed:', error);
      if (error.response && error.response.status === 404) {
        setShowCreateAccountModal(true);
      } else if (error.response && error.response.status === 401) {
        setErrorList(['Incorrect password. Please try again.']);
        setErrorDialogueBoxOpen(true);
      } else {
        setErrorList(['Login failed. Please try again later.']);
        setErrorDialogueBoxOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCreateAccount = async () => {
    try {
      setLoading(true);
      const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

      const createUserResponse = await axios.post(`${baseApi}/users`, {
        username: billingDetails.username,
        password: billingDetails.password,
        name: createAccountName,
        surname: createAccountSurname,
        email: createAccountEmail,
        mobile: createAccountMobile,
        physicalAddress: createAccountPhysicalAddress,
        billingAddress: createAccountBillingAddress,
      });

      const { user: newUserData, token } = createUserResponse.data;

      setAuthToken(token);
      setUser(newUserData);

      setBillingDetails((prev) => ({
        ...prev,
        username: newUserData.username,
        name: newUserData.name,
        surname: newUserData.surname,
        email: newUserData.email,
        mobile: newUserData.mobile,
        physicalAddress: newUserData.physicalAddress,
        billingAddress: newUserData.billingAddress,
      }));

      setShowCreateAccountModal(false);

      setCreateAccountName('');
      setCreateAccountSurname('');
      setCreateAccountEmail('');
      setCreateAccountPassword('');
      setCreateAccountMobile('');
      setCreateAccountPhysicalAddress('');
      setCreateAccountBillingAddress('');
    } catch (error) {
      console.error('Account creation failed:', error);
      if (error.response && error.response.status === 409) {
        setErrorList(['Email already exists. Please login instead.']);
        setErrorDialogueBoxOpen(true);
        setShowLoginModal(true);
      } else {
        setErrorList(['Account creation failed. Please try again later.']);
        setErrorDialogueBoxOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const headerImg = '../../assets/img/header.png';
  const footerImg = '../../assets/img/footer.png';

  const generateMandatePDF = () => {
    const doc = new jsPDF();

    doc.addImage(headerImg, 'PNG', 10, 10, 190, 30);

    let currentY = 50;

    doc.setFontSize(16);
    doc.text('Debit Order Mandate', 105, currentY, { align: 'center' });

    currentY += 10;

    doc.setFontSize(12);
    doc.text('Order Summary', 14, currentY);
    doc.autoTable({
      startY: currentY + 5,
      theme: 'grid',
      styles: { halign: 'center' },
      head: [['Field', 'Value']],
      body: [
        ['Product', selectedProduct?.name || 'N/A'],
        ['Price', `R${selectedProduct?.price.toFixed(2) || '0.00'}`],
        ['VAT (15%)', `R${calculateVAT(selectedProduct?.price || 0).toFixed(2)}`],
        ['Total', `R${calculateTotal(selectedProduct?.price || 0).toFixed(2)}`],
      ],
    });

    currentY = doc.lastAutoTable.finalY + 10;

    doc.text('Mandate Details', 14, currentY);
    doc.autoTable({
      startY: currentY + 5,
      theme: 'grid',
      styles: { halign: 'center' },
      head: [['Field', 'Value']],
      body: [
        ['Name', `${billingDetails.name} ${billingDetails.surname}`],
        ['ID Number', debitOrderDetails.idNumber],
        ['Billing Address', billingDetails.billingAddress],
        ['Bank', debitOrderDetails.bankName],
        ['Account Type', debitOrderDetails.banAccountType],
        ['Account Number', debitOrderDetails.bankAccountNumber],
        ['Debit Date', debitOrderDetails.debitDate],
        ['Email', billingDetails.email],
        ['Mobile', billingDetails.mobile],
        ['IP Address', debitOrderDetails.ipAddress],
        ['Accepted At', debitOrderDetails.acceptedAt],
      ],
    });

    currentY = doc.lastAutoTable.finalY + 10;

    doc.text('Terms and Conditions', 14, currentY);
    const termsText = `<h1>Docotela™ Debit Check and Debit Order Mandate</h1>

  <p>
    By subscribing to Docotela™’s monthly service, you {billingDetails.name} {billingDetails.surname} hereby authorize Docotela™ (or its authorized payment agent) to process debit orders or debit check instructions against your designated bank account for the purposes of collecting your monthly subscription fees. Please read the terms carefully:
  </p>

  <!-- Rest of your terms and conditions -->

  <h2>Helping Us to Help You:</h2>
  <p>
    You can help us to meet our commitments to you by:
  </p>
  <ul>
    <li>Being courteous, polite, and respectful of our employees.</li>
    <li>Being open and honest with us by providing accurate and complete details.</li>
    <li>Letting us know when your contact details change, for example, your address or personal details.</li>
    <li>Contacting the employee referred on any correspondence sent to you and quoting the reference number – if applicable.</li>
    <li>Using appropriate channels for requests, complaints, and compliments while using our services.</li>
    <li>Working with us to help resolve any problems that may arise in your use of our Platform.</li>
    <li>Telling us where we fall short on our service in any aspect so that we may improve our services to you.</li>
    <li>Helping us recognize our employees by telling us when you have received excellent service.</li>
  </ul>`;

    const splitText = doc.splitTextToSize(termsText, 180);
    doc.text(splitText, 14, currentY + 5);

    currentY = doc.lastAutoTable ? doc.lastAutoTable.finalY + 15 : currentY + 5 + splitText.length * 10;

    doc.addImage(footerImg, 'PNG', 10, 280, 190, 15);

    if (signatureData) {
      const imgProps = signatureData.match(/^data:image\/(\w+);base64,(.+)$/);
      if (imgProps) {
        const [_, type, data] = imgProps;
        doc.addImage(data, type.toUpperCase(), 14, currentY + 10, 50, 20);
      }
    }

    doc.save('Debit_Order_Mandate.pdf');
  };

const getImageDataUrl = async (imagePath) => {
  try {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error converting image to Base64:', error);
    throw error;
  }
};

  const handleTermsScroll = () => {
    if (!termsRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setHasScrolledToBottom(true);
    }
  };

  const renderDebitOrderStep = () => {
    return (
      <Box sx={{ backgroundColor: 'white' }}>
        <Typography variant="h5" mb={2}>
          Debit Order Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              value={billingDetails.name}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Surname"
              value={billingDetails.surname}
              disabled
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="ID Number"
              fullWidth
              value={debitOrderDetails.idNumber}
              onChange={handleDebitOrderChange('idNumber')}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label="IP Address"
              fullWidth
              disabled
              value={debitOrderDetails.ipAddress}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Debit Date"
              fullWidth
              value={debitOrderDetails.debitDate}
              onChange={handleDebitOrderChange('debitDate')}
              SelectProps={{
                native: true,
              }}
              required
            >
              <option value="1st">1st</option>
              <option value="7th">7th</option>
              <option value="15th">15th</option>
              <option value="25th">25th</option>
              <option value="31st">31st</option>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              value={debitOrderDetails.bankName}
              onChange={handleDebitOrderChange('bankName')}
              SelectProps={{
                native: true,
              }}
              required
            >
              <option value="" disabled>Select A Bank</option>
              <option value="Access Bank">Access Bank</option>
              <option value="African Bank">African Bank</option>
              <option value="ABSA">ABSA</option>
              <option value="Bidvest Bank">Bidvest Bank</option>
              <option value="Capitec Bank">Capitec Bank</option>
              <option value="Discovery Bank">Discovery Bank</option>
              <option value="First National Bank">First National Bank</option>
              <option value="Investec">Investec</option>
              <option value="Mercantile Bank">Mercantile Bank</option>
              <option value="Nedbank">Nedbank</option>
              <option value="Old Mutual Bank">Old Mutual Bank</option>
              <option value="Standard Bank">Standard Bank</option>
              <option value="Tymebank">Tymebank</option>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              value={debitOrderDetails.bankAccountType}
              onChange={handleDebitOrderChange('bankAccountType')}
              SelectProps={{
                native: true,
              }}
              required
            >
              <option value="" disabled>Select Account Type</option>
              <option value="cheque">Cheque</option>
              <option value="savings">Savings</option>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Bank account number"
              value={debitOrderDetails.bankAccountNumber}
              onChange={handleDebitOrderChange('bankAccountNumber')}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                maxHeight: 200,
                overflowY: 'auto',
                backgroundColor: '#efefef',
              }}
            >
              <Typography variant="body2">
                <h1>Docotela™ Debit Check and Debit Order Mandate</h1>

                <p>
                  By subscribing to Docotela™’s monthly service, you {billingDetails.name} {billingDetails.surname} hereby authorize Docotela™ (or its authorized payment agent) to process debit orders or debit check instructions against your designated bank account for the purposes of collecting your monthly subscription fees. Please read the terms carefully:
                </p>

                <h2>Authority:</h2>
                <p>
                  You authorize Docotela™ to arrange with your bank to debit your account in terms of this mandate. The debit will be processed in accordance with the agreed monthly subscription fee for your chosen plan.
                </p>

                <h2>Account Details:</h2>
                <p>
                  I will ensure that the account details provided are accurate, and I will notify Docotela™ of any changes to my banking details.
                </p>

                <h2>Frequency and Amount:</h2>
                <p>
                  Debit orders will be processed on a recurring basis according to your subscription plan. Debits will be processed on your chosen debit date each month.
                </p>

                <h2>Cancellation:</h2>
                <p>
                  You may cancel this mandate at any time by providing written notice at least 30 days before the next scheduled debit date. Please note that cancellation of this mandate may result in the termination of your subscription services.
                </p>
                <p>
                  If you wish to cancel your virtual consultation with our Healthcare Practitioner either prior to making a booking or prior to the scheduled time, we shall charge you an administration fee in the amount of <strong>R150</strong> and shall accordingly refund the remaining service fee.
                </p>
                <p>
                  If you cancel your virtual consultation within 12 (twelve) hours of your booked appointment, if our Healthcare Practitioner cannot contact you, or if you fail to attend the virtual consultation by virtue of experiencing a technical failure on your end or providing incorrect contact details, you will not be eligible for a refund for the service fees paid.
                </p>
                <p>
                  Should your virtual consultation not take place as a result of the attending Healthcare Practitioner not being available, you agree to allow Docotela™ to reschedule your appointment within 2 (two) business days. Should Docotela™ not be able to facilitate an appropriate alternative consultation time within the 2 (two) business days with a Healthcare Practitioner on the Platform, a full refund of your service fees will be available to you upon request.
                </p>
                <p>
                  All service fees charged on a subscription basis are not eligible for a refund.
                </p>

                <h2>Changes to the Agreement:</h2>
                <p>
                  Should the subscription amount change due to pricing adjustments or upgrades to your selected plan, Docotela™ will notify you at least 90 days before the new amount is debited. You have the right to dispute any changes by contacting Docotela™ support.
                </p>

                <h2>Failure of Payment:</h2>
                <p>
                  If the debit order fails due to insufficient funds or other reasons, Docotela™ reserves the right to attempt to debit the account again or suspend your service until payment is received. Additional fees may apply for unsuccessful debit orders.
                </p>

                <h2>Dispute Rights:</h2>
                <p>
                  You have the right to dispute any unauthorized debit or incorrect debit amount within 30 days of the transaction. Disputes can be raised directly with your bank or by contacting Docotela™ at <a href="mailto:admin@docotela.co.za">admin@docotela.co.za</a> or <a href="tel:+27710960092">+27 71 096 0092</a>.
                </p>

                <h2>Confidentiality:</h2>
                <p>
                  All personal and banking information provided for this debit order is protected and will be used solely for the purpose of processing your subscription payments.
                </p>

                <h2>Validity:</h2>
                <p>
                  This mandate is valid for the duration of your subscription and will remain in force until cancelled by you in writing.
                </p>
                <p>
                  By confirming your subscription, you agree to the terms outlined in this Debit Check and Debit Order Mandate and understand your rights and obligations as outlined above.
                </p>

                <h2>For Assistance, Contact Docotela™ Support:</h2>
                <p class="contact-info">
                  Email: <a href="mailto:admin@docotela.co.za">admin@docotela.co.za</a><br />
                  Phone: <a href="tel:+27710960092">+27 71 096 0092</a>
                </p>

                <h2>Non-solicitation:</h2>
                <p>
                  Under no circumstances can the Patient organise a private arrangement with one of our Healthcare Practitioners. All appointments must be made through the Platform. In the event that such a situation is to occur, the Patient shall be held liable for the full amount of the applicable virtual consultation and should expect an injunction against them to stop from this happening again.
                </p>

                <h2>Customer Service Feedback:</h2>
                <p>
                  As we strive to deliver exceptional customer service, we encourage you to provide feedback. Whether you have a request for action, a compliment or a complaint, we would like to hear from you. Please do not hesitate to contact us if you would like to suggest ways in which we can improve our services at:
                </p>
                <p class="contact-info">
                  WhatsApp: <strong>+27 71 096 0092</strong><br />
                  E-mail: <a href="mailto:info@docotela.co.za">info@docotela.co.za</a>
                </p>

                <h2>Helping Us to Help You:</h2>
                <p>
                  You can help us to meet our commitments to you by:
                </p>
                <ul>
                  <li>Being courteous, polite, and respectful of our employees.</li>
                  <li>Being open and honest with us by providing accurate and complete details.</li>
                  <li>Letting us know when your contact details change, for example, your address or personal details.</li>
                  <li>Contacting the employee referred on any correspondence sent to you and quoting the reference number – if applicable.</li>
                  <li>Using appropriate channels for requests, complaints, and compliments while using our services.</li>
                  <li>Working with us to help resolve any problems that may arise in your use of our Platform.</li>
                  <li>Telling us where we fall short on our service in any aspect so that we may improve our services to you.</li>
                  <li>Helping us recognize our employees by telling us when you have received excellent service.</li>
                </ul>
              </Typography>

            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
              Electronic Signature
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Please provide your electronic signature below to complete the debit order mandate.
            </Typography>
            <SignatureCanvas
              ref={signaturePadRef}
              canvasProps={{
                width: 650,
                height: 200,
                className: 'signatureCanvas',
                style: { border: '1px solid #ccc', borderRadius: '5px' },
              }}
            />
            <Box sx={{ mt: 2, display: 'flex', gap: 2, backgroundColor: 'white' }}>
              <Button variant="outlined" onClick={clearSignature}>
                Clear
              </Button>
              <Button variant="contained" onClick={saveSignature}>
                Save Signature
              </Button>
            </Box>
            {signatureData && (
              <Typography variant="body2" sx={{ mt: 2, color: 'green' }}>
                Signature saved successfully.
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={debitOrderDetails.mandateAccepted}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setDebitOrderDetails((prev) => ({
                      ...prev,
                      mandateAccepted: checked,
                      acceptedAt: checked ? new Date().toString() : '',
                    }));
                  }}
                  sx={{
                    color: '#170B3B',
                    '&.Mui-checked': {
                      color: '#603f8b',
                    },
                  }}
                />
              }
              label="I accept the debit order mandate"
            />
            {debitOrderDetails.acceptedAt && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Accepted at: {debitOrderDetails.acceptedAt}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={generateMandatePDF}
            >
              Download Debit Order Mandate (PDF)
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ backgroundColor: 'white', marginBottom: 0, marginTop: 0 }}>
            <Typography variant="h5" mb={2}>
              Select a Product
            </Typography>
            {products.length > 0 ? (
              <Grid container spacing={2} mb={0}>
                {products.map((product) => (
                  <Grid item xs={12} sm={6} md={4} key={product._id}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: 2,
                        textAlign: 'center',
                        backgroundColor: 'white',
                        border:
                          String(selectedProduct?._id) === String(product._id)
                            ? '2px solid #603f8b'
                            : '2px solid transparent',
                        cursor: 'pointer',
                        transition: 'border 0.3s',
                        '&:hover': {
                          boxShadow: 6,
                          border: '2px solid #603f8b',
                        },
                      }}
                      onClick={() => selectProduct(product)}
                    >
                      <Avatar
                        src={product.productImage || 'https://via.placeholder.com/150'}
                        alt={product.name}
                        sx={{ width: 80, height: 80, margin: 'auto', mb: 2 }}
                      />
                      <Typography variant="h6">{product.name}</Typography>
                      <Typography variant="body2" mt={1}>
                        {product.description}
                      </Typography>
                      <Typography variant="body1" mt={2}>
                        R{product.price}
                        {product.subscriptionInt && ` / ${product.subscriptionInt}`}
                      </Typography>
                      <Box sx={{ textAlign: 'center', mt: 0, mb: 2, p: 4, backgroundColor: 'white' }}>
                        {product.inclusions && product.inclusions.length > 0 ? (
                          <ul style={{ listStyle: 'none', padding: 0 }}>
                            {product.inclusions.map((inclusion, index) => (
                              <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                                <CheckIcon sx={{ color: '#603f8b', marginRight: 1, fontSize: 16 }} />
                                <Typography variant="body2">{inclusion}</Typography>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <Typography variant="body2">No inclusions available.</Typography>
                        )}
                        <Typography>
                          <strong>**T&C’s apply in Terms of Use.</strong>
                        </Typography>
                      </Box>
                      <Button
                        variant={
                          String(selectedProduct?._id) === String(product._id) ? 'contained' : 'outlined'
                        }
                        sx={
                          String(selectedProduct?._id) === String(product._id)
                            ? buttonStyle
                            : {
                              borderColor: '#170B3B',
                              color: '#170B3B',
                              '&:hover': {
                                borderColor: '#603f8b',
                                backgroundColor: '#603f8b',
                                color: '#fff',
                              },
                            }
                        }
                        startIcon={
                          String(selectedProduct?._id) === String(product._id) ? <CheckIcon /> : null
                        }
                      >
                        {String(selectedProduct?._id) === String(product._id)
                          ? 'Selected'
                          : 'Select'}
                      </Button>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0, backgroundColor: 'white' }}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        );

      case 1:
        return (
          <Box sx={{ backgroundColor: 'white' }}>
            <Button variant="contained" onClick={() => setShowLoginModal(true)} sx={{ mb: 3, ...buttonStyle }}>
              {user ? 'Edit Login Details' : 'Login to Auto-Fill'}
            </Button>

            <Typography variant="h5" mb={2}>
              Account & Billing Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  fullWidth
                  value={billingDetails.name}
                  onChange={handleInputChange('name')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Surname"
                  fullWidth
                  value={billingDetails.surname}
                  onChange={handleInputChange('surname')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  type="email"
                  fullWidth
                  value={billingDetails.email}
                  onChange={handleInputChange('email')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Mobile"
                  type="mobile"
                  fullWidth
                  value={billingDetails.mobile}
                  onChange={handleInputChange('mobile')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Username"
                  fullWidth
                  value={billingDetails.username}
                  onChange={handleInputChange('username')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Password"
                  type="password"
                  fullWidth
                  value={billingDetails.password}
                  onChange={handleInputChange('password')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Confirm Password"
                  type="password"
                  fullWidth
                  value={billingDetails.confirmPassword}
                  onChange={handleInputChange('confirmPassword')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Physical Address"
                  fullWidth
                  value={billingDetails.physicalAddress}
                  onChange={handleInputChange('physicalAddress')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sameAsPhysical}
                      onChange={(e) => setSameAsPhysical(e.target.checked)}
                      sx={{
                        color: '#170B3B',
                        '&.Mui-checked': {
                          color: '#603f8b',
                        },
                      }}
                    />
                  }
                  label="Billing address same as physical address"
                />
                {!sameAsPhysical && (
                  <TextField
                    label="Billing Address"
                    fullWidth
                    value={billingDetails.billingAddress}
                    onChange={handleInputChange('billingAddress')}
                    required
                    sx={{ mt: 2 }}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        );

      case 2:
        return (
          <Box sx={{ backgroundColor: 'white' }}>
            <Typography variant="h5" mb={2}>
              Terms & Conditions
            </Typography>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                maxHeight: 300,
                overflowY: 'auto',
                backgroundColor: '#efefef',
              }}
              onScroll={handleTermsScroll}
              ref={termsRef}
            >
              <Typography variant="body1">
                <h1>Welcome to Shuffle Up Poker</h1>
                <p>
                  Some text here
                </p>
                <p><strong>Let’s Get Started!</strong></p>

                <h2>What is a virtual consultation?</h2>
                <p>
                  This is a meeting that takes place remotely between a patient and a Healthcare Practitioner via video conferencing or telephonic calls for purposes of allowing patients to discuss their health and well-being concerns, receive medical advice, medical prescriptions and sick notes without needing to physically visit a clinic or hospital, thus saving you time and money.
                </p>

                <h2>When should I opt for a virtual consultation?</h2>
                <ul>
                  <li>Initial assessments for non-emergency medical conditions, such as a sore throat, cold, rash, mild headaches and minor sprains.</li>
                  <li>Follow-up assessments.</li>
                  <li>Routine check-ups.</li>
                  <li>General health inquiries.</li>
                  <li>Concerns regarding exposure to contagious diseases such as COVID-19, monkeypox, and influenza.</li>
                  <li>Prescription refills.</li>
                  <li>Contraceptive management.</li>
                  <li>Specialist and Lab test referrals.</li>
                </ul>

                <h2>What do I need to do to gain access to Docotela’s services?</h2>
                <p>
                  Providing a platform that allows you to attend virtual consultations with a qualified, licensed Healthcare Practitioner requires the following:
                </p>
                <ol>
                  <li>Sign up and create an account as you have already done.</li>
                  <li>Carefully read and understand Docotela’s policies and guidelines regarding the platform and agree as well as undertake to abide by the provisions thereof.</li>
                </ol>

                <h2>What do I need for the virtual consultation?</h2>
                <ul>
                  <li>An electronic gadget that allows you to access Docotela’s platform.</li>
                  <li>A stable internet connection. Please also ensure that you check if you have enough data should you not be using Wi-Fi.</li>
                  <li>A supported browser.</li>
                  <li>A quiet space, free from distractions and noise to allow for meaningful engagement with the Healthcare Practitioner.</li>
                  <li>To be ready 10 (ten) minutes before the appointment time to ensure that everything is working in good order (i.e., check your camera, test your sound and ensure that your electronic gadget has enough battery power) and to ensure that you are not late for the virtual consultation.</li>
                </ul>

                <h2>What happens during the virtual consultation?</h2>
                <ol>
                  <li>Inform the Healthcare Practitioner about the reason(s) for your virtual consultation, including whether it is your first time, a follow-up appointment, or a routine check-up.</li>
                  <li>Alert the Healthcare Practitioner if there is any other individual with you in the room during the virtual consultation for confidentiality and privacy purposes.</li>
                  <li>Explain your symptoms in detail – it is better to overshare than undershare so that the Healthcare Practitioner can diagnose your symptoms correctly.</li>
                  <li>Let the Healthcare Practitioner know of any medication or supplements you are currently taking or have previously taken prior to the virtual consultation.</li>
                  <li>Ask as many questions as you can regarding any medical advice the Healthcare Practitioner may provide you with, including how to reach them should you have further questions, unexpected side effects, or worsening symptoms after the virtual consultation.</li>
                  <li>Write down the Healthcare Practitioner’s medical advice while the information is still fresh in your head.</li>
                </ol>

                <h2>What happens after the virtual consultation?</h2>
                <ul>
                  <li>Receive your medical prescription from the Healthcare Practitioner.</li>
                  <li>Receive a sick note from the Healthcare Practitioner if necessary.</li>
                  <li>Receive a R100 medication voucher from Clicks, Dischem, or Medirite if necessary.</li>
                  <li>Order the medication prescribed online or in person.</li>
                  <li>Go for any medical tests and/or physical examinations based on the required referral received from the Healthcare Practitioner you consulted with.</li>
                  <li>Take the applicable medication so that you heal your body effectively.</li>
                </ul>

                <h2>SERVICE UNDERTAKINGS</h2>
                <p>
                  We wish to share our undertakings for services with you hereunder:
                </p>

                <h3>Our Patients</h3>
                <ul>
                  <li>We respect our Patients and provide prompt, friendly, courteous, and efficient client service.</li>
                  <li>We will take ownership of your enquiry, follow-up and keep you informed of progress to completion.</li>
                  <li>We will be realistic about what we can do within realistic timeframes and provide you with accurate and consistent information.</li>
                  <li>We will show respect for your privacy in your dealings with us and the confidentiality of information discussed.</li>
                </ul>

                <h3>Our Healthcare Practitioners</h3>
                <p>
                  We introduce exceptional Healthcare Practitioners to you who have been thoroughly vetted by us. We verify their credentials and their compliance with the healthcare laws and regulations to ensure that you are dealing with ethical and competent Healthcare Practitioners. Our administration team will ensure that your request for a Healthcare Practitioner is met efficiently and that a suitable Healthcare Practitioner is made available to communicate with you.
                </p>

                <h3>Business Communication</h3>
                <p>
                  We use our WhatsApp line and our email address as our main communication channels and we will endeavour to respond promptly. Please contact us on <strong>+27 71 096 0096</strong> and/or <a href="mailto:info@docotela.co.za">info@docotela.co.za</a>. We will ensure that all communication directed to you is in clear, concise language that you can easily understand; we will not burden you with medical jargon.
                </p>

                <h3>Billing and Payment</h3>
                <p>
                  Our services are paid for before scheduling your appointment through our trusted payment gateway on our platform, which can be accessed <a href="#">here</a> to ensure a secure and efficient transaction, whether you are engaging in a once-off consultation or subscribing to our monthly subscriptions which allow you to have unlimited virtual consultations with our Healthcare Practitioners. Please note that payments are never made directly to the Healthcare Practitioner by the Patient.
                </p>

                <h3>Cancellation and Rescheduling Policy</h3>
                <p>
                  You are required to take your virtual consultation within 90 (Ninety) days of successfully submitting payment through the platform. Should you not attend your virtual consultation with our Healthcare Practitioner within this time period, your order shall be deemed to have been cancelled and you will not be eligible for a refund.
                </p>
                <p>
                  If you wish to cancel your virtual consultation with our Healthcare Practitioner either prior to making a booking or prior to the scheduled time, we shall charge you an administration fee in the amount of <strong>R150</strong> and shall accordingly refund the remaining service fee.
                </p>
                <p>
                  If you cancel your virtual consultation within 12 (twelve) hours of your booked appointment, if our Healthcare Practitioner cannot contact you, or if you fail to attend the virtual consultation by virtue of experiencing a technical failure on your end or providing incorrect contact details, you will not be eligible for a refund for the service fees paid.
                </p>
                <p>
                  Should your virtual consultation not take place as a result of the attending Healthcare Practitioner not being available, you agree to allow Docotela to reschedule your appointment within 2 (two) business days. Should Docotela not be able to facilitate an appropriate alternative consultation time within the 2 (two) business days with a Healthcare Practitioner on the Platform, a full refund of your service fees will be available to you upon request.
                </p>
                <p>
                  All service fees charged on a subscription basis are not eligible for a refund.
                </p>

                <h3>Non-solicitation</h3>
                <p>
                  Under no circumstances can the Patient organise a private arrangement with one of our Healthcare Practitioners. All appointments must be made through the Platform. In the event that such a situation is to occur, the Patient shall be held liable for the full amount of the applicable virtual consultation and should expect an injunction against them to stop from this happening again.
                </p>

                <h2>Customer Service Feedback</h2>
                <p>
                  As we strive to deliver exceptional customer service, we encourage you to provide feedback. Whether you have a request for action, a compliment or a complaint, we would like to hear from you. Please do not hesitate to contact us if you would like to suggest ways in which we can improve our services at:
                </p>
                <p class="contact-info">
                  WhatsApp: <strong>+27 71 096 0092</strong><br />
                  E-mail: <a href="mailto:info@docotela.co.za">info@docotela.co.za</a>
                </p>

                <h2>Helping us to help you</h2>
                <p>
                  You can help us to meet our commitments to you by:
                </p>
                <ul>
                  <li>Being courteous, polite and respectful of our employees.</li>
                  <li>Being open and honest with us by providing accurate and complete details.</li>
                  <li>Letting us know when your contact details change, for example, your address or personal details.</li>
                  <li>Contacting the employee referred on any correspondence sent to you and quoting the reference number – if applicable.</li>
                  <li>Using appropriate channels for requests, complaints and compliments while using our services.</li>
                  <li>Working with us to help resolve any problems that may arise in your use of our Platform.</li>
                  <li>Telling us where we fall short on our service in any aspect so that we may improve our services to you.</li>
                  <li>Helping us recognize our employees by telling us when you have received excellent service.</li>
                </ul>
              </Typography>
            </Paper>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  color="primary"
                  disabled={!hasScrolledToBottom}
                  sx={{
                    color: '#170B3B',
                    '&.Mui-checked': {
                      color: '#603f8b',
                    },
                  }}
                />
              }
              label="I have read and accept the Terms and Conditions"
              sx={{ mt: 2 }}
            />
            {!hasScrolledToBottom && (
              <Typography variant="caption" color="textSecondary">
                Please scroll to the bottom to accept the terms and conditions.
              </Typography>
            )}
          </Box>
        );

      case 3:
        return (
          <Box sx={{ backgroundColor: 'white' }}>
            <Typography variant="h5" mb={2}>
              Payment Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 2,
                    backgroundColor: '#f7f7f7',
                    borderRadius: 2,
                    mb: 2,
                  }}
                >
                  <img
                    src="https://payfast.io/wp-content/uploads/2024/12/Payfast-logo.svg"
                    alt="Payfast Logo"
                    style={{ height: '40px', marginRight: '16px' }}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={paymentMethod === 'payfast'}
                        onChange={handlePaymentMethodChange}
                        value="payfast"
                        sx={{
                          color: '#603f8b',
                          '&.Mui-checked': {
                            color: '#603f8b',
                          },
                        }}
                      />
                    }
                    label={
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Pay with Payfast
                      </Typography>
                    }
                    sx={{ flex: 1 }}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 2,
                    backgroundColor: '#f7f7f7',
                    borderRadius: 2,
                  }}
                >
                  <img
                    src="https://docotela.co.za/wp-content/uploads/2023/02/Docotela-new-logo-600x171.webp"
                    alt="Docotela Logo"
                    style={{ height: '40px', marginRight: '8px' }}
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={paymentMethod === 'debitOrder'}
                        onChange={handlePaymentMethodChange}
                        value="debitOrder"
                        sx={{
                          color: '#603f8b',
                          '&.Mui-checked': {
                            color: '#603f8b',
                          },
                        }}
                      />
                    }
                    label={
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Sign a debit order mandate
                      </Typography>
                    }
                    sx={{ flex: 1 }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        );

      case 4:
        return renderDebitOrderStep();

      default:
        return null;
    }
  };

  const buttonStyle = {
    backgroundColor: '#170B3B',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#603f8b',
    },
  };

  const stepLabelStyles = {
    '& .MuiStepLabel-label': {
      color: '#170B3B !important',
    },
    '& .Mui-active .MuiStepLabel-label': {
      color: '#603f8b !important',
    },
    '& .Mui-completed .MuiStepLabel-label': {
      color: '#603f8b !important',
    },
    '& .MuiStepConnector-line': {
      borderColor: '#603f8b !important',
    },
  };

  return (
    <Box
      sx={{
        padding: 4,
        maxWidth: '100%',
        minHeight: '100vh',
        margin: 'auto',
        backgroundColor: 'white',
      }}
    >
      <Grid container spacing={4}>
        {activeStep < steps.length && (
          <>
            <Grid
              item
              xs={12}
              md={selectedProduct && activeStep >= 1 ? 6 : 12}
              sx={{ backgroundColor: 'white' }}
            >
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={stepLabelStyles}
                StepIconComponent={CustomStepIcon}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {selectedProduct && activeStep >= 1 && (
                <Box
                  sx={{
                    mt: 4,
                    mb: 4,
                    p: 2,
                    color: 'white',
                    backgroundColor: '#603f8b',
                    borderRadius: 2,
                  }}
                >
                  <Typography variant="h6">
                    Selected Product: {selectedProduct.name}
                  </Typography>
                  <Typography variant="subtitle1">
                    Price: R{selectedProduct.price}
                  </Typography>
                </Box>
              )}

              <Box sx={{ mt: 0, mb: 4, backgroundColor: 'white' }}>
                {renderStepContent(activeStep)}
              </Box>

              {activeStep < steps.length - 1 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: activeStep === 0 ? 'flex-end' : 'space-between',
                    backgroundColor: 'white',
                  }}
                >
                  {activeStep !== 0 && (
                    <Button
                      variant="outlined"
                      onClick={handleBack}
                      sx={{
                        borderColor: '#170B3B',
                        color: '#170B3B',
                        '&:hover': {
                          borderColor: '#603f8b',
                          backgroundColor: '#603f8b',
                          color: '#fff',
                        },
                      }}
                    >
                      Back
                    </Button>
                  )}
                  <Button variant="contained" onClick={handleNext} sx={buttonStyle}>
                    Next
                  </Button>
                </Box>
              )}

              {activeStep === steps.length - 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'white' }}>
                  <Button
                    variant="outlined"
                    onClick={handleBack}
                    sx={{
                      borderColor: '#170B3B',
                      color: '#170B3B',
                      '&:hover': {
                        borderColor: '#603f8b',
                        backgroundColor: '#603f8b',
                        color: '#fff',
                      },
                    }}
                  >
                    Previous
                  </Button>
                  <Button variant="contained" onClick={handleFinish} disabled={loading} sx={buttonStyle}>
                    {loading ? <CircularProgress size={24} /> : 'Submit Payment'}
                  </Button>
                </Box>
              )}
            </Grid>

            {activeStep >= 1 && selectedProduct && (
              <Grid item xs={12} md={6}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 4,
                    position: { xs: 'static', md: 'sticky' },
                    top: 20,
                    height: 'fit-content',
                    backgroundColor: '#603f8b',
                    color: 'white',
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Order Summary
                  </Typography>

                  <Box sx={{ backgroundColor: '#603f8b' }}>
                    <Grid container spacing={1} sx={{ mb: 2 }}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Product:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} textAlign="right">
                        <Typography variant="subtitle1">{selectedProduct.name}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Description:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} textAlign="right">
                        <Typography variant="subtitle1">{selectedProduct.description}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Price:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} textAlign="right">
                        <Typography variant="subtitle1">R{selectedProduct.price}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Billing Frequency:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} textAlign="right">
                        <Typography variant="subtitle1">{selectedProduct.subscriptionInt}</Typography>
                      </Grid>
                    </Grid>

                    {activeStep >= 1 && (
                      <Box sx={{ backgroundColor: '#603f8b', mb: 2 }}>
                        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                          Billing Details:
                        </Typography>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Typography variant="body2">Username:</Typography>
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <Typography variant="body2">{billingDetails.username}</Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <Typography variant="body2">Name:</Typography>
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <Typography variant="body2">
                              {billingDetails.name} {billingDetails.surname}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <Typography variant="body2">Email:</Typography>
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <Typography variant="body2">{billingDetails.email}</Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <Typography variant="body2">Mobile:</Typography>
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <Typography variant="body2">{billingDetails.mobile}</Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <Typography variant="body2">Physical Address:</Typography>
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <Typography variant="body2">{billingDetails.physicalAddress}</Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <Typography variant="body2">Billing Address:</Typography>
                          </Grid>
                          <Grid item xs={6} textAlign="right">
                            <Typography variant="body2">{billingDetails.billingAddress}</Typography>
                          </Grid>
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                      </Box>
                    )}

                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          VAT (15%):
                        </Typography>
                      </Grid>
                      <Grid item xs={6} textAlign="right">
                        <Typography variant="subtitle1">
                          R{calculateVAT(selectedProduct.price).toFixed(2)}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography variant="subtitle1" fontWeight="bold">
                          Total:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} textAlign="right">
                        <Typography variant="subtitle1">
                          R{calculateTotal(selectedProduct.price).toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            )}
          </>
        )}
      </Grid>

      <Dialog open={showProductDialog} onClose={handleCloseProductDialog}>
        <DialogTitle>Product Selected</DialogTitle>
        <DialogContent>
          <Typography>
            You have selected <strong>{selectedProduct?.name}</strong>.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleCloseProductDialog} sx={buttonStyle}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showLoginModal} onClose={() => setShowLoginModal(false)}>
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <TextField
            label="Email"
            fullWidth
            value={loginEmail}
            onChange={(e) => setLoginEmail(e.target.value)}
            sx={{ mb: 2, mt: 2 }}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            value={loginPassword}
            onChange={(e) => setLoginPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setShowLoginModal(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleLogin} disabled={loading} sx={buttonStyle}>
            {loading ? <CircularProgress size={24} /> : 'Login'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showCreateAccountModal} onClose={() => setShowCreateAccountModal(false)}>
        <DialogTitle>Create New Account</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            value={createAccountName}
            onChange={(e) => setCreateAccountName(e.target.value)}
            sx={{ mb: 2, mt: 2 }}
            required
          />
          <TextField
            label="Surname"
            fullWidth
            value={createAccountSurname}
            onChange={(e) => setCreateAccountSurname(e.target.value)}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            label="Email"
            type="email"
            fullWidth
            value={createAccountEmail}
            onChange={(e) => setCreateAccountEmail(e.target.value)}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            value={createAccountPassword}
            onChange={(e) => setCreateAccountPassword(e.target.value)}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            label="Mobile Number"
            fullWidth
            value={createAccountMobile}
            onChange={(e) => setCreateAccountMobile(e.target.value)}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            label="Physical Address"
            fullWidth
            value={createAccountPhysicalAddress}
            onChange={(e) => setCreateAccountPhysicalAddress(e.target.value)}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            label="Billing Address"
            fullWidth
            value={createAccountBillingAddress}
            onChange={(e) => setCreateAccountBillingAddress(e.target.value)}
            sx={{ mb: 2 }}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setShowCreateAccountModal(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleCreateAccount} disabled={loading} sx={buttonStyle}>
            {loading ? <CircularProgress size={24} /> : 'Create Account'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={errorDialogueBoxOpen} onClose={() => setErrorDialogueBoxOpen(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          {errorList.map((error, index) => (
            <Typography key={index} color="error">
              {error}
            </Typography>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialogueBoxOpen(false)} sx={buttonStyle}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CanvasLayoutCheckout;
