import React from 'react';
import ButtonTabsSection from '../../components/Frontend/Sections/ButtonTabSection';
import Navbar from '../../components/Frontend/Header/Navbar';
import Footer from '../../components/Frontend/Footer/PrimaryFooter';
import FullWidthBackgroundSection from '../../components/Frontend/Sections/FullWidthBackgroundSection';
import NewsRoomBanner from '../../components/Frontend/Banner/NewsRoomBanner';
import ThinBanner from '../../components/Frontend/Banner/ThinBanner';
import backgroundImage from "../../assets/img/frontend/productAndPolicies.webp"
function WebsitePoliciesPage(){

    return(
        <>
        <Navbar/>
        <NewsRoomBanner title="Policies" backgroundImage={backgroundImage}/>
        <ButtonTabsSection/>
        <ThinBanner/>
        <Footer/>
        </>
    );
}
export default WebsitePoliciesPage;