import React, { useState, useEffect } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CookieIcon from "@mui/icons-material/Cookie";
import SmallTealButton from "./Button/SmallTealButton";
import SmallBorderTealButton from "./Button/SmallBorderTealButton";

const CookiePopup = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem("cookieConsent");
    if (!consentGiven) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setOpen(false);
  };

  const handleDeny = () => {
    localStorage.setItem("cookieConsent", "denied");
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!open) return null;

  return (
    <Box
      sx={{
        width: "400px",
        height: "300px",
        background: "#000000",
        color: "#fff",
        position: "fixed",
        bottom: "20px",
        right: "20px",
        padding: "1.5rem",
        border: "1px solid",
        borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
        borderRadius: "8px",
        boxShadow: "0px 0px 45px rgba(174, 61, 239, 0.5)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        zIndex: 1000,
      }}
    >
      {/* Close Button */}
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: "10px", right: "10px", color: "#fff" }}
      >
        <CloseIcon />
      </IconButton>

      {/* Title with Icon */}
      <Box sx={{ background: "transparent", display: "flex", alignItems: "center", gap: "8px", marginBottom: "0.25rem" }}>
        <Typography variant="h6" fontWeight="bold">
          Cookie Consent
        </Typography>
        <CookieIcon fill="#72ECEA" />
      </Box>

      {/* Consent Paragraph */}
      <Typography variant="body2" sx={{ opacity: 1, marginBottom: "0.25rem", maxWidth: "320px" }}>
      We use cookies to personalise content and ads, and analyse our traffic. We also share information about your use of the website with our social media, advertising, and analytics partners. You can learn more about the cookies we use from our Privacy Notice. </Typography>

      {/* Accept & Deny Buttons */}
      <Box sx={{ background: "transparent", display: "flex", gap: 2 }}>
        <SmallTealButton variant="contained" color="primary" onClick={handleAccept}>
          Accept
        </SmallTealButton>
        <SmallBorderTealButton variant="outlined" color="secondary" onClick={handleDeny}>
          Deny
        </SmallBorderTealButton>
      </Box>
    </Box>
  );
};

export default CookiePopup;
