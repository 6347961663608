import React, { useState } from "react";
import {
  Box,
  Modal,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
  IconButton,
  Rating,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../Button/CustomButton"; // Ensure this button exists

const ReviewModal = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    review: "",
    rating: 0,
    marketingOptIn: false,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Review Submitted:", formData);
    handleClose(); // Close modal after submission
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="review-modal-title">
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          height: "600px",
          overflowY: "scroll",
          transform: "translate(-50%, -50%)",
          background: "#0E0B0E",
          boxShadow: "0px 4px 45px rgba(174, 61, 239, 0.45)",
          borderRadius: "4px",
          padding: "72px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "39px",
          color: "#fff",
        }}
      >
        {/* Close Button */}
        <IconButton
          sx={{ position: "absolute", top: "16px", right: "16px", color: "#fff" }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        {/* Title */}
        <Typography variant="h4" fontWeight="bold" id="review-modal-title">
          Leave a Review
        </Typography>

        {/* Form */}
        <Box component="form" onSubmit={handleSubmit} sx={{ background: "transparent", width: "100%" }}>
          <Grid container spacing={3}>
            {/* Name */}
            <Grid item xs={12} sm={3}>
              <Typography variant="h6">Name</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                name="name"
                label="Your Name"
                variant="outlined"
                value={formData.name}
                onChange={handleChange}
                sx={inputStyles}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={3}>
              <Typography variant="h6">Email</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                name="email"
                label="Your Email"
                variant="outlined"
                value={formData.email}
                onChange={handleChange}
                sx={inputStyles}
              />
            </Grid>

            {/* Review Text Box */}
            <Grid item xs={12}>
              <Typography variant="h6">Your Review</Typography>
              <TextField
                fullWidth
                name="review"
                label="Write your review..."
                variant="outlined"
                multiline
                rows={4}
                value={formData.review}
                onChange={handleChange}
                sx={inputStyles}
              />
            </Grid>

            {/* Star Rating */}
            <Grid item xs={12}>
              <Typography variant="h6">Rating</Typography>
              <Rating
                name="rating"
                value={formData.rating}
                onChange={(event, newValue) => setFormData({ ...formData, rating: newValue })}
                size="large"
                sx={{ color: "#AE3DEF" }}
              />
            </Grid>

            {/* Marketing Opt-in */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="marketingOptIn"
                    checked={formData.marketingOptIn}
                    onChange={handleChange}
                    sx={{ color: "#fff" }}
                  />
                }
                label="I agree to receive marketing emails"
                sx={{ color: "#fff" }}
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <CustomButton type="submit" variant="contained" color="primary">
                Submit Review
              </CustomButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

// Common styles for input fields
const inputStyles = {
  borderRadius: "4px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "#ffffff" },
    "&:hover fieldset": { borderColor: "#AE3DEF" },
    "&.Mui-focused fieldset": { borderColor: "#AE3DEF" },
  },
  "& .MuiInputLabel-root": {
    color: "#ffffff",
    "&.Mui-focused": { color: "#AE3DEF" },
  },
};

export default ReviewModal;
