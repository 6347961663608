import React from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Logo from "../../../assets/img/frontend/sup-logo.svg";
import PokerLogo from "../../../assets/img/frontend/poker-sa-logo.svg";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#0E0B0E",
        color: "white",
        padding: "2rem",
      }}
    >
      {/* Main Footer Content */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
          flexWrap: "wrap",
          background: "transparent",
        }}
      >
        {/* Column 1 - Menu List */}
        <Box sx={{ flex: 1, minWidth: "200px", background: "transparent" }}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Menu
          </Typography>
          <Link href="/events" color="inherit" underline="hover" display="block">
            Events
          </Link>
          <Link href="/team" color="inherit" underline="hover" display="block">
            Team ShuffleUp
          </Link>
          <Link
            href="/live-feed"
            color="inherit"
            underline="hover"
            display="block"
          >
            Live Reporting
          </Link>
          <Link href="/news" color="inherit" underline="hover" display="block">
            News
          </Link>
          <Link href="/shop" color="inherit" underline="hover" display="block">
            Shop
          </Link>
        </Box>

        {/* Column 2 - Contact Information */}
        <Box sx={{ flex: 1, minWidth: "200px", background: "transparent" }}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body2">
            info@shuffleuppoker.co.za
          </Typography>
          <Typography variant="body2">+27 67 029 8025</Typography>
          <Typography variant="body2">
            Riley Road, Bedfordview, JHB, SA
          </Typography>
        </Box>

        {/* Column 3 - Social Media Icons */}
        <Box
          sx={{
            flex: 1,
            minWidth: "200px",
            display: "flex",
            alignItems: "center",
            gap: 1,
            background: "transparent",
          }}
        >
          <IconButton
            color="inherit"
            component="a"
            href="https://www.facebook.com/Shuffleup/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            component="a"
            href="https://x.com/shuffleupsa"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            component="a"
            href="https://www.instagram.com/shuffleuppoker/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </IconButton>
        </Box>

        {/* Column 4 - Inline Logos */}
        <Box
          sx={{
            flex: 1,
            minWidth: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 4,
            background: "transparent",
          }}
        >
          <img src={Logo} alt="Logo 1" style={{ height: "40px" }} />
          <img src={PokerLogo} alt="Logo 2" style={{ height: "40px" }} />
        </Box>
      </Box>

      {/* Copyright Section */}
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          marginTop: "2rem",
          opacity: 0.7,
          background: "transparent",
        }}
      >
        <Typography variant="body2">
          © {new Date().getFullYear()} ShuffleUp Poker. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
