import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  IconButton,
  CircularProgress,
  Chip
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UserContext } from "../../Context/UserContext";
import BackgroundImage from "../../assets/img/frontend/background-purple-fullSize.png";

function Login() {
  const navigate = useNavigate();
  const { signInUser } = useContext(UserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAuthDisabled, setIsAuthDisabled] = useState(false);

  useEffect(() => {
    // Check if authentication is disabled in the environment
    if (process.env.REACT_APP_DISABLE_AUTH === "true") {
      setIsAuthDisabled(true);
      console.warn("REACT_APP_DISABLE_AUTH is enabled: Automatically logging in.");
      // Automatically sign in a dummy user and redirect
      const dummyUser = {
        id: "dummy-id",
        email: "dummy@example.com",
        userType: "Admin", // Change this as needed for your protected routes
      };
      signInUser(dummyUser, "dummy-token");
      navigate("/account");
    }
  }, [navigate, signInUser]);

  // Normal login UI (only shown when auth is NOT disabled)
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Your existing login logic goes here
    // For example, a call to your API for login verification...

    // Reset submitting state at the end
    setIsSubmitting(false);
  };

  // If auth is disabled, the component will automatically sign in and redirect,
  // so you can optionally render a loading state or nothing.
  if (isAuthDisabled) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={48} sx={{ color: "#fff" }} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Login Box */}
      <Box
        sx={{
          position: "relative",
          width: "300px",
          height: "500px",
          background: "#0E0B0E",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
          boxShadow: "0px 4px 45px rgba(174, 61, 239, 0.45)",
        }}
      >
        {/* Optional: Display a badge that indicates auth is active */}
        <Chip 
          label="Coming Soon" 
          sx={{ 
            position: "absolute", 
            top: "10px", 
            left: "0px", 
            backgroundColor: "#ff9800", 
            color: "#fff", 
            borderRadius: 0,
            fontWeight: "bold" 
          }} 
        />

        {/* Close Button */}
        <IconButton
          sx={{ position: "absolute", top: "10px", right: "10px", color: "#fff" }}
          onClick={() => navigate("/")}
        >
          <CloseIcon />
        </IconButton>

        {/* Login Title */}
        <Typography variant="h5" sx={{ color: "#fff", fontWeight: "bold", marginBottom: "1rem" }}>
          Login
        </Typography>

        {/* Login Form */}
        <form onSubmit={handleSubmit} style={{ width: "100%", textAlign: "center" }}>
          {/* Your email and password input fields would normally go here */}
          {/* For example:
          <TextField ... />
          <TextField ... />
          */}
          <Button
            type="submit"
            variant="contained"
            sx={{
              width: "100%",
              marginTop: "1rem",
              background: "linear-gradient(88.1deg, #72ECEA 47.05%, #AE3DEF 116.99%)",
              fontWeight: "bold",
              borderRadius: "8px",
              color: "#000"
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={24} sx={{ color: "#000" }} /> : "Login"}
          </Button>
        </form>

        {/* Additional UI elements for forgot password and sign up */}
        <Button
          variant="text"
          sx={{ color: "#72ECEA", textTransform: "none", marginTop: "0.5rem" }}
          onClick={() => {}}
        >
          Forgot Password?
        </Button>
        <Typography sx={{ color: "#fff", fontSize: "0.9rem", marginTop: "1rem" }}>
          Don't have an account?{" "}
          <Button
            variant="text"
            sx={{ color: "#72ECEA", textTransform: "none" }}
            onClick={() => navigate("/signup")}
          >
            Sign up
          </Button>
        </Typography>
      </Box>
    </Box>
  );
}

export default Login;
