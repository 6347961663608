import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Button,
  IconButton,
  Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FullWidthGradientButton from '../Button/FullWidthGradientButton';

// Dummy cart items for demonstration
const initialCartItems = [
  { id: 1, image: 'https://via.placeholder.com/80', title: 'Poker Set', price: 49.99, quantity: 1 },
  { id: 2, image: 'https://via.placeholder.com/80', title: 'Card Deck', price: 9.99, quantity: 2 },
];

const CheckoutSection = () => {
  // Left column state (for simplicity, only a few fields are managed)
  const [contact, setContact] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [billingAddress, setBillingAddress] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });
  const [useBillingForShipping, setUseBillingForShipping] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('credit');

  // Right column: cart items state
  const [cartItems, setCartItems] = useState(initialCartItems);

  // Handler to remove a cart item
  const handleRemoveCartItem = (id) => {
    setCartItems(cartItems.filter(item => item.id !== id));
  };

  // Calculate cart total
  const cartTotal = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  // Handlers for updating form fields (for brevity, using inline updates)
  const handleContactChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const handleBillingChange = (e) => {
    setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value });
  };

  // Reusable TextField style: white border by default, purple border on focus.
  const fieldSX = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#fff',
      },
      '&:hover fieldset': {
        borderColor: '#fff',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#AC3DED', // Purple outline on focus
      },
    },
  };

  return (
    <Box sx={{ padding: '2rem', background: '#0E0A0F', color: 'white' }}>
      <Grid container spacing={4} sx={{ padding: "2rem 10rem" }}>
        {/* Left Column: Checkout Form */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>Checkout</Typography>

          {/* Contact Information */}
          <Box sx={{ background: "transparent", marginBottom: '2rem' }}>
            <Typography variant="h6" gutterBottom>Contact Information</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={contact.firstName}
                  onChange={handleContactChange}
                  variant="outlined"
                  sx={fieldSX}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={contact.lastName}
                  onChange={handleContactChange}
                  variant="outlined"
                  sx={fieldSX}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={contact.email}
                  onChange={handleContactChange}
                  variant="outlined"
                  sx={fieldSX}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone"
                  name="phone"
                  type="tel"
                  value={contact.phone}
                  onChange={handleContactChange}
                  variant="outlined"
                  sx={fieldSX}
                />
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ marginY: '2rem' }} />

          {/* Billing Address */}
          <Box sx={{ marginBottom: '2rem', background: "transparent" }}>
            <Typography variant="h6" gutterBottom>Billing Address</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Address Line 1"
                  name="address1"
                  value={billingAddress.address1}
                  onChange={handleBillingChange}
                  variant="outlined"
                  sx={fieldSX}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Address Line 2"
                  name="address2"
                  value={billingAddress.address2}
                  onChange={handleBillingChange}
                  variant="outlined"
                  sx={fieldSX}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="City"
                  name="city"
                  value={billingAddress.city}
                  onChange={handleBillingChange}
                  variant="outlined"
                  sx={fieldSX}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="State/Province"
                  name="state"
                  value={billingAddress.state}
                  onChange={handleBillingChange}
                  variant="outlined"
                  sx={fieldSX}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Postal Code"
                  name="postalCode"
                  value={billingAddress.postalCode}
                  onChange={handleBillingChange}
                  variant="outlined"
                  sx={fieldSX}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Country"
                  name="country"
                  value={billingAddress.country}
                  onChange={handleBillingChange}
                  variant="outlined"
                  sx={fieldSX}
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ marginBottom: '2rem', background:"transparent" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useBillingForShipping}
                  onChange={(e) => setUseBillingForShipping(e.target.checked)}
                />
              }
              label="Use billing address for shipping"
            />
          </Box>

          <Divider sx={{ marginY: '2rem' }} />

          {/* Payment Section */}
          <Box sx={{ marginBottom: '2rem', background: "transparent" }}>
            <Typography variant="h6" gutterBottom>Payment</Typography>
            <FormControl component="fieldset">
              <FormLabel component="legend">Payment Method</FormLabel>
              <RadioGroup
                row
                name="paymentMethod"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <FormControlLabel value="credit" control={<Radio />} label="Credit Card" />
                <FormControlLabel value="paypal" control={<Radio />} label="PayPal" />
                <FormControlLabel value="bitcoin" control={<Radio />} label="Bitcoin" />
              </RadioGroup>
            </FormControl>
            {/* Additional payment fields (e.g., card number, expiry) can be added here */}
          </Box>

          <FullWidthGradientButton size="large">
            Place Order
          </FullWidthGradientButton>
        </Grid>

        {/* Right Column: Cart Summary */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>Cart Summary</Typography>
          {cartItems.map(item => (
            <Box
              key={item.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1rem',
                padding: '0.5rem',
                background: 'transparent',
                borderRadius: '4px',
              }}
            >
              <Box
                component="img"
                src={item.image}
                alt={item.title}
                sx={{
                  width: '80px',
                  height: '80px',
                  objectFit: 'cover',
                  borderRadius: '4px',
                  marginRight: '1rem',
                  background: "transparent",
                }}
              />
              <Box sx={{ flex: 1, textAlign: 'left', background: "transparent" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{item.title}</Typography>
                <Typography variant="body2">${item.price.toFixed(2)}</Typography>
                <Typography variant="body2">Qty: {item.quantity}</Typography>
              </Box>
              <IconButton onClick={() => handleRemoveCartItem(item.id)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Divider sx={{ my: '1rem' }} />
          <Typography variant="h6">Total: ${cartTotal.toFixed(2)}</Typography>
          <Box sx={{ background: "transparent", marginTop: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box component="img" src="https://via.placeholder.com/50" alt="Security Icon" sx={{ marginBottom: '0.5rem' }} />
            <Typography variant="caption">Secure Checkout</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CheckoutSection;
