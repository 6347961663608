import React, { useState } from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import CustomButton from "../Button/CustomButton";
import GradientH3 from "../Tyopgraphy/GradientH3";
import { Link } from "react-router-dom";
// Import the SVG as a React component:
import { ReactComponent as SmallSIcon } from "../../../assets/img/frontend/small-s.svg";
import Placeholder from "../../../assets/img/frontend/placeholder2.webp";
import bannerPlaceholder from "../../../assets/img/frontend/GarethDuncanColor.png"
import CaledonBanner from "../../../assets/img/frontend/CaledonBanner.png";
import CoolPlay1 from "../../../assets/img/frontend/CoolPlay1.png";
import SplendidoBanner from "../../../assets/img/frontend/SplendidoBanner.png";
import CoolPlay2 from "../../../assets/img/frontend/SeriesScheduleSplendido.jpeg";
import Splendido from "../../../assets/img/frontend/montecasino_news.webp";

// Sample Posts Data
const posts = [{
    featuredImage: `${CoolPlay1}`,
    slug: "shuffle-up-caledon-series",
    title: "Shuffle Up Poker’s Caledon Poker Series: A Week of Excitement,Skill, and Camaraderie",
    author: "Adam Scher",
    date: "January 31, 2025",
    excerpt: "The 2025 Caledon Poker Series, hosted by Shuffle Up Poker, took place at the stunning Caledon Hotel and Casino from Wednesday, January 29th to Sunday, February 2nd. The event brought together poker enthusiasts from all corners of the country for an action-packed week filled with high stakes, thrilling tournaments, and plenty of off-the-table relaxation.",
  galleryImage: `${CoolPlay1}`
  },
  {
    featuredImage: `${Splendido}`,
    slug: "get-ready-for-splendido",
    title: "Get Ready for the Splendido Poker Series 12 at Monte Casino!",
    author: "Adam Scher",
    date: "January 31, 2025",
    excerpt: "Johannesburg, South Africa – The highly anticipated Splendido Poker Series 12 is set to return to Monte Casino in Johannesburg from March 19th to 30th, 2025, bringing the best of poker to one of South Africa's premier entertainment destinations.",
    galleryImage: `${CoolPlay2}`
  },
  {
    featuredImage: `${Placeholder}`,
    slug: "shuffle-up-presents-brand-refresh",
    title: "Shuffle Up Poker Unveils Full Brand Refresh: A New Era of Poker Excellence",
    author: "Adam Scher",
    date: "February 28, 2025",
    excerpt: "Shuffle Up Poker is excited to announce the launch of our brand-new look and feel! After months of careful planning and collaboration, we’re proud to unveil our full brand refresh for 2025",
    galleryImage: `${Placeholder}`
  },
  {
  featuredImage: `${bannerPlaceholder}`,
  slug: "shuffle-up-poker-announces-2025-brand-ambassadors",
  title: "Shuffle Up Poker Announces 2025 Brand Ambassadors: A New Era of Poker in Africa",
  author: "Adam Scher",
  date: "February 28, 2025",
  excerpt: "We’re thrilled to announce the new faces of Shuffle Up Poker for 2025! This year, we’ve brought on four extraordinary individuals as our official brand ambassadors. These talented players and poker advocates will be representing Shuffle Up Poker across South Africa and the greater African continent, bringing excitement, passion, and skill to poker tables everywhere.",
  }
];

const NewsGrid = () => {
  const [visibleArticles, setVisibleArticles] = useState(9);

  const handleLoadMore = () => {
    setVisibleArticles(posts.length);
  };

  return (
    <Box sx={{ background: "#000000", padding: "4rem 0", color: "#fff", textAlign: "center" }}>
      <Container maxWidth="lg">
        {/* Section Title */}
        <GradientH3 variant="h3" fontWeight="bold" gutterBottom>
          Stay Up-To-Date
        </GradientH3>

        {/* Section Description */}
        <Typography variant="h6" sx={{ opacity: 1, maxWidth: "600px", margin: "0 auto 2rem" }}>
          Get the latest poker tournaments News here!
        </Typography>

        {/* News Grid */}
        <Grid container spacing={3} justifyContent="start">
          {posts.slice(0, visibleArticles).map((post, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} sx={{ background: "transparent" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  padding: "1.5rem",
                  borderRadius: "0px",
                  background: "transparent",
                }}
              >
                {/* Image Container */}
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "350px",
                    height: "100%",
                    objectFit: "cover",
                    paddingTop: "0px",
                    background: "transparent",
                    marginBottom: "1rem",
                  }}
                >
                  <Box
                    component="img"
                    src={post.featuredImage}
                    alt={post.title}
                    sx={{
                      width: "350px",
                      height: "215px",
                      objectFit: "cover",
                      paddingTop: "0px",
                      border: "1px solid",
                      borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
                      borderRadius: "0px",
                      display: "block",
                    }}
                  />
                  {/* Overlay on Bottom 40px */}
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "40px",
                      background: "rgba(0, 0, 0, 0.5)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* Render the SVG icon as a React component */}
                    <SmallSIcon style={{ marginTop: "-30px", width: "30px", height: "30px" }} />
                  </Box>
                </Box>

                {/* Title */}
                <Typography variant="h5" fontWeight="bold" gutterBottom>
                  {post.title}
                </Typography>

                {/* Excerpt */}
                <Typography variant="body1" sx={{ opacity: 0.8, maxWidth: "300px", marginBottom: "1rem" }}>
                  {post.excerpt.length > 30
                    ? `${post.excerpt.split(" ").slice(0, 30).join(" ")}...`
                    : post.excerpt}
                </Typography>

                {/* Read More Button */}
                <CustomButton as={Link} to={`/news/${post.slug}`} variant="contained" color="primary">
                  Read More
                </CustomButton>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Load More Button - Center Aligned */}
        {visibleArticles < posts.length && (
          <Box sx={{ marginTop: "2rem", display: "flex", justifyContent: "center", background: "transparent" }}>
            <CustomButton variant="contained" color="secondary" onClick={handleLoadMore}>
              Load More
            </CustomButton>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default NewsGrid;
