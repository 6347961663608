import React from "react";
import { Box } from "@mui/material";
import BackgroundImage from "../../../assets/img/frontend/background-purple-full.webp"; // Replace with actual image path

const FullWidthBackgroundSection = ({ children }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "300px",
        display: "flex",
        marginTop: "0px",
        paddingTop: "0px",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: "#fff",
        overflow: "hidden",
        background: "transparent"
      }}
    >
      {/* Background Image */}
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          zIndex: -2,
        }}
      />

      {/* Black to Transparent Gradient Overlay */}
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: "linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)",
          zIndex: -1,
        }}
      />

      {/* Content */}
      <Box sx={{ position: "relative", zIndex: 1 }}>
        {children}
      </Box>
    </Box>
  );
};

export default FullWidthBackgroundSection;
