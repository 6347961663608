import React from "react";
import { Button, useMediaQuery, useTheme } from "@mui/material";

const TealButton = ({ children, onClick, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Button
      onClick={onClick}
      {...props}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "14px 24px",
        gap: "32px",
        width: "177px",
        height: "48px",
        background: "#72ECEA", // Neon Cyan background
        borderRadius: "4px",
        flex: "none",
        order: "2",
        flexGrow: "0",
        color: "#000", // Ensures contrast (black text)
        textTransform: "none", // Removes default uppercase styling
        fontWeight: "bold",
        fontSize: "16px",
        "&:hover": {
          background: "#5FD9D7", // Slightly darker on hover
        },
      }}
    >
      {children}
    </Button>
  );
};

export default TealButton;
