import React from 'react';
import { Box } from '@mui/material';
import { FaWhatsapp } from 'react-icons/fa';

const FloatingWhatsapp = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        background: "transparent",
        right: 20,
        display: 'flex',
        alignItems: 'center',
        zIndex: 990,
      }}
    >
      {/* Always-Visible Tooltip */}
      <Box
        sx={{
          backgroundColor: '#efefef',
          color: '#121212',
          padding: '8px 12px',
          borderRadius: '4px',
          marginRight: '8px',
          fontSize: '14px',
          fontWeight: '400',
        }}
      >
        Need help? Chat with us.
      </Box>

      {/* WhatsApp Icon Button */}
      <Box
        component="a"
        href="https://wa.me/27670298025"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          backgroundColor: '#72ECEA',
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          lineHeight: 0,
          cursor: 'pointer',
        }}
      >
        <FaWhatsapp size={32} color="#000" style={{ margin: "auto", marginTop: '-23px' }} />
      </Box>
    </Box>
  );
};

export default FloatingWhatsapp;
