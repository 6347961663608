import React from "react";
import { Typography } from "@mui/material";

const GradientH1 = ({ children, ...props }) => {
  return (
    <Typography
      variant="h1"
      {...props}
      sx={{
        fontFamily: "'Oswald', sans-serif",
        fontStyle: "normal",
        fontWeight: 200,
        fontSize: "52px",
        wordBreak: "no-break",
        textAlign: "center",
        textTransform: "uppercase",
        background: "linear-gradient(90deg, #72ECEA 18.06%, #AE3DEF 81.65%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        flex: "none",
        order: "0",
        flexGrow: "0",
      }}
    >
      {children}
    </Typography>
  );
};

export default GradientH1;
