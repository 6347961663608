import React, { useContext, useEffect, useState } from 'react';
import styles from './Event.module.css';
import { useNavigate } from "react-router-dom";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import { UserContext } from '../../Context/UserContext'
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import MyCalendar from '../Datepicker/MyCalendar';
import moment from "moment"
import axios from "axios";
import { BootstrapDialog, BootstrapDialogTitle } from "../MUIDialogueBox/BoostrapDialogueBox"
import DialogContent from '@mui/material/DialogContent';
import EventForm from '../Forms/EventForm'
import EventTable from '../MUITable/EventTable'
import GoogleCalendarIntegration from '../../Service/gapi.service'
import ToastService from '../../Service/toast.service'

function AdminEvent() {
    const navigate = useNavigate();

    const [clickedTimeSlot, setClickedTimeSlot] = useState('');
    const [googleCalendarEvents, setGoogleCalendarEvents] = useState([]);

    const [date, setDate] = useState(new Date());
    const [availableSlots, setAvailableSlots] = useState([])
    const [bookedSlots, setBookedSlots] = useState([])
    const [bookedEvents, setBookedEvents] = useState([])


    const [departmentList, setDepartmentList] = useState([]);
    const [doctorList, setDoctorList] = useState([]);
    const [patientList, setPatientList] = useState([]);


    const [departmentSelected, setDepartmentSelected] = useState("");
    const [doctorSelected, setDoctorSelected] = useState("");

    const handleCalendarSync = (events) => {
        console.log('Synced Events:', events);
        setGoogleCalendarEvents(events);
    };

    const handleDepartmentChange = (event) => {
        setDepartmentSelected(event.target.value);
        setDoctorSelected("");
    };
    const handleDoctorChange = (event) => {
        setDoctorSelected(event.target.value);
    };


    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const handleErrorDialogueOpen = () => {
        setErrorDialogueBoxOpen(true)
    };
    const handleErrorDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false)
    };

    const [openDialgueBox, setOpenDialgueBox] = React.useState(false);

    const handleClickOpen = () => {
        setOpenDialgueBox(true);
    };
    const handleClose = () => {
        setOpenDialgueBox(false);
    };

    const addEventFormSubmitted = async (event) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        event.preventDefault();
        const form = document.forms.addEvent;
        let reqObj = {
            "appDate": form.appDate.value,
            "appTime": form.appTime.value,
            "doctorId": form.doctor.value,
            "patientId": form.patient.value
        }

        let response = await axios.put(`${baseApi}/events/`,
            reqObj,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }
        );
        if (response.data.message == "success") {
            ToastService.success("Booking request submitted successfully.")
            getAvailableSlots();
            getBookedSlots();
        }

        handleClose();


    }

    const getformDate = (mydate) => {
        const parts = mydate.split('-');
        const d = new Date(+parts[0], parts[1] - 1, +parts[2], 12);
        return d;
    }

    const formatDateForDateInput = (dateOfJoining) => {
        dateOfJoining = moment(new Date(dateOfJoining)).format('YYYY-MM-DD');
        return dateOfJoining;
    }

    const slotClicked = (slot) => {
        setClickedTimeSlot(slot)
        handleClickOpen()
    }

    const getAvailableSlots = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
        if (doctorSelected) {
            let response = await axios.post(`${baseApi}/events`,
                {
                    'isTimeSlotAvailable': true,
                    'appDate': formatDateForDateInput(date),
                    'doctorID': doctorSelected
                },
                {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );
            if (response.data.message == "success") {
                let aptms = response.data.events;

                let slots = aptms.map(apt =>
                    apt.eventTime
                )
                slots.sort((a, b) => {
                    const timeA = new Date(`01/01/2000 ${a}`);
                    const timeB = new Date(`01/01/2000 ${b}`);
                    return timeA - timeB;
                });

                setAvailableSlots(slots);
            }
            else {
            }
        }
        else {
            setAvailableSlots([]);
        }

    }

    const getBookedSlots = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        let response = await axios.post(`${baseApi}/events`,
            {
                'isTimeSlotAvailable': false,
                'appDate': formatDateForDateInput(date),
                'doctorID': doctorSelected
            },
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }
        );
        if (response.data.message == "success") {
            let aptms = response.data.events;
            console.log("aptms", aptms);
            let sortedAptms = aptms.sort((a, b) => {
                const timeA = new Date(`01/01/2000 ${a['eventTime']}`);
                const timeB = new Date(`01/01/2000 ${b["eventTime"]}`);
                return timeA - timeB;
            });
            setBookedEvents(sortedAptms);
            console.log(aptms)
            let slots = aptms.map(apt =>
                apt.eventTime
            )
            slots.sort((a, b) => {
                const timeA = new Date(`01/01/2000 ${a}`);
                const timeB = new Date(`01/01/2000 ${b}`);
                return timeA - timeB;
            });

            setBookedSlots(slots);
        }
    }

    const deleteBookedSlots = async (appId) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        console.log("delete slot with id", appId);
        let response = await axios.delete(`${baseApi}/events/`,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                data: {
                    eventId: appId,
                },
            }
        );
        if (response.data.message == "success") {
            getAvailableSlots();
            getBookedSlots();
            ToastService.success("Event slot deleted successfully.")
        }
    }

    const getDoctorList = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        let response = await axios.get(`${baseApi}/doctors`,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }
        );
        let doctors = response.data;
        if (doctors.length > 0) {
            if (!departmentSelected) {
                setDoctorList(doctors);
            }
            else {
                let filterdDocs = doctors.filter((doc) => {
                    return doc.department == departmentSelected;
                })
                setDoctorList(filterdDocs);
            }

        }
        else {
        }

    }

    const getDepartmentList = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        let response = await axios.get(`${baseApi}/departments`,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }
        );
        let departments = response.data.departments;
        if (departments.length > 0) {

            setDepartmentList(departments);
        }
        else {
        }

    }

    const getPatients = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        const response = await axios.get(`${baseApi}/patients`);
        setPatientList(response.data);
    };

    useEffect(() => {
        getDepartmentList()
        getDoctorList()
        getAvailableSlots()
        getBookedSlots()
        getPatients()

    }, [date, departmentSelected, doctorSelected])

    const handleCreateSlotSubmit = async (event) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        event.preventDefault();
        const form = document.forms.createSlotForm;
        let timeSlots = Array.from(form.querySelectorAll('input[type="checkbox"]:checked'))
            .map(input => input.value);
        if (!(timeSlots.length > 0)) {
            setErrorList(["Please choose a time slot"])
            handleErrorDialogueOpen();
        }
        else {
            try {
                let response = await axios.post(`${baseApi}/events/add`,
                    {
                        'appDate': getformDate(form.appDate.value),
                        'timeSlots': timeSlots,
                        'doctorID': form.doctor.value
                    },
                    {
                        headers: {
                            authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                );
                if (response.data.message == "success") {
                    getAvailableSlots();
                    getBookedSlots();
                    ToastService.success("Appoitnment slot created successfully.")
                }
            }
            catch (error) {
                setErrorList(error.response.data.errors)
                handleErrorDialogueOpen();
            }


            form.querySelectorAll('input[type="checkbox"]').forEach(input => input.checked = false);

        }
    }


    return (
        <Box id={styles.eventMain} component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div>
                <h3 className={styles.pageTitle}> Events</h3>
            </div>

            <div>
                <GoogleCalendarIntegration onCalendarSync={handleCalendarSync} />
            </div>

            <div id={styles.slotGrid}>
                <div id={styles.calendarDiv}>
                    <MyCalendar date={date} setDate={setDate} />
                    {googleCalendarEvents.length > 0 && (
                        <div>
                            <h4>Synced Google Calendar Events:</h4>
                            <ul>
                                {googleCalendarEvents.map((event) => (
                                    <li key={event.id}>
                                        {event.summary} - {event.start.dateTime} to {event.end.dateTime}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                <div id={styles.slotCreationDiv}>
                    <form name='createSlotForm' id="createSlotForm" onSubmit={handleCreateSlotSubmit} >
                        <h4>Choose Doctor and Date</h4>
                        <div className='my-4 row'>
                            <div className='col-12'>
                                <label for="department" className="col-sm-3 col-form-label ">Department: </label>
                                <select name="department" id="department" class="col-form-select col-sm-7" aria-label="Default select example" onChange={handleDepartmentChange}>
                                    <option selected value=''>All</option>
                                    {
                                        departmentList.map(sp => {
                                            return <option value={sp}>{sp}</option>
                                        })
                                    }
                                </select>
                            </div>


                        </div>
                        <div className='my-4 row'>

                            <div className='col-12'>
                                <label for="doctor" className="col-sm-3 col-form-label ">Doctor: </label>
                                <select name="doctor" id="doctor" class="col-form-select col-sm-7" aria-label="Default select example" required
                                    onChange={handleDoctorChange}
                                >
                                    <option value=''>Choose Doctor</option>
                                    {
                                        doctorList.map(doctor => {
                                            if (doctorSelected == doctor._id) {
                                                return <option value={doctor._id} selected>{doctor.userId.firstName} {doctor.userId.lastName}</option>
                                            }
                                            else {
                                                return <option value={doctor._id} >{doctor.userId.firstName} {doctor.userId.lastName}</option>
                                            }

                                        })
                                    }
                                </select>
                            </div>

                        </div>
                        <div className='my-4 row'>
                            <div className="col-12">
                                <label for="appDate" className="col-sm-3 col-form-label ">Date: </label>
                                <input id="appDate" name="appDate" type="date" className="col-form-control col-sm-7"
                                    value={formatDateForDateInput(date)}
                                    onChange={(e) => setDate(getformDate(e.target.value))}
                                />
                            </div>

                        </div>
                        <h4>Create Slots</h4>
                        <div className='my-4 row'>
                            {}
                            <label for="appTime" className="col-sm-3 col-form-label ">Time slots: </label>
                            {
}
                            <span className='col-sm-9'>
                                {["9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM"].map((slot) => {
                                    if (!(availableSlots.includes(slot)) && !(bookedSlots.includes(slot))) {
                                        return (
                                            <div class="form-check form-check-inline px-3 py-1">
                                                <input class="form-check-input" type="checkbox" id={slot} value={slot} />
                                                <label class="form-check-label" for={slot}>{slot}</label>
                                            </div>
                                        )
                                    }

                                })}
                            </span>
                            {}
                        </div>

                        <input type='submit' className='btn btn-primary float-right btn-rounded py-2 px-4' value='Create' />
                    </form>

                    {availableSlots.length > 0 ? <div className={styles.availableSlotsHeader}> <h4 className="mt-5">Available Slots</h4> <p>Click a slot to book events</p></div> : <div></div>}

                    <div className='d-flex flex-wrap'>
                        {
                            availableSlots.map(slot => {
                                return <div onClick={() => slotClicked(slot)} className={styles.slotCard}>{slot}</div>
                            })
                        }
                    </div>

                </div>

            </div>


            {bookedEvents.length > 0 ?
                <div className={styles.availableSlotsHeader}>
                    <h4 className="mt-5">
                        Booked Events
                    </h4>
                    <EventTable
                        bookedEvents={bookedEvents}
                        deleteBookedSlots={deleteBookedSlots}
                        doctorList={doctorList}
                        patientList={patientList}
                        availableSlots={availableSlots}
                        getAvailableSlots={getAvailableSlots}
                        getBookedSlots={getBookedSlots}
                    />
                </div> : <div></div>}




            <ErrorDialogueBox
                open={errorDialogueBoxOpen}
                handleToClose={handleErrorDialogueClose}
                ErrorTitle="Error"
                ErrorList={errorList}
            />
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDialgueBox}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Book Event
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <EventForm
                        formName="addEvent"
                        formOnSubmit={addEventFormSubmitted}
                        appDate={formatDateForDateInput(date)}
                        appTime={clickedTimeSlot}
                        doctorList={doctorList}
                        doctorSelected={doctorSelected}
                        patientList={patientList}
                        availableSlots={availableSlots} />
                </DialogContent>
            </BootstrapDialog>
        </Box>
    );
}

export default AdminEvent;
