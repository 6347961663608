
import styles from './Dashboard.module.css';
import { React, useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import axios from "axios";
import { NavLink } from 'react-router-dom';
import moment from "moment"
import { UserContext } from '../../Context/UserContext'


export default function GeneralUserDashboard() {

	const [adminCount, setAdminCount] = useState(0);
	const [employeeCount, setEmployeeCount] = useState(0);
	const [appsTodayCount, setAppsTodayCount] = useState(0);
	const [pendingAppsTodayCount, setPendingAppsTodayCount] = useState(0);
	const [bookedEvents, setBookedEvents] = useState([]);
	const [admins, setadmin] = useState([]);
	const { currentUser } = useContext(UserContext);

	/**
	 * Get user count by role
	 * @since 0.1
	 * @param {} userType 
	 */
	const getUserCountByRole = async (userType) => {
		const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

		const response = await axios.post(`${baseApi}/count/users`,
			{
				'userType': userType
			},
			{
				headers: {
					authorization: `Bearer ${localStorage.getItem("token")}`
				}
			}
		);
		let count = response.data.count
		if (count) {
			if (userType == "Admin")
				setAdminCount(count);
			else if (userType == "Employee")
				setEmployeeCount(count);
		}

	};

	/**
	 * Get appointment count
	 * @since 0.1
	 */
	const getAppointmentCount = async () => {
		const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

		const response = await axios.get(`${baseApi}/count/events`,
			{
				headers: {
					authorization: `Bearer ${localStorage.getItem("token")}`
				}
			}
		);
		if (response?.data?.totalEvents) {
			setAppsTodayCount(response?.data?.totalEvents);
		}
		if (response?.data?.pendingEvents) {
			setPendingAppsTodayCount(response?.data?.pendingEvents)
		}
	}

	/**
	 * Get booked slots
	 * @since 0.1
	 */
	const getBookedSlots = async () => {
		const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

		let response = await axios.post(`${baseApi}/api/events`,
			{
				'isTimeSlotAvailable': false,
				'appDate': moment(new Date()).format('YYYY-MM-DD')
			},
			{
				headers: {
					authorization: `Bearer ${localStorage.getItem("token")}`
				}
			}
		);
		if (response.data.message == "success") {

			let aptms = response.data.events;
			console.log("aptms", aptms);

			setBookedEvents(aptms);

		}

	}

	/**
	 * Get admins
	 * @since 0.1
	 */
	const getadmins = async () => {
		const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

		const response = await axios.get(`${baseApi}/admins`);
		setadmin(response.data);
	};

	useEffect(() => {
		getUserCountByRole("Admin");
		getUserCountByRole("Employee");
		getAppointmentCount()
		getBookedSlots();
		getadmins();
	}, []);

	return (
		<Box className={styles.dashboardBody} component="main" sx={{ flexGrow: 1, p: 3 }}>
			<div id={styles.welcomeBanner}>
				<div className='text-white'>
					<h3 >Welcome!</h3>
					<br/>
					<h4>{currentUser.firstName} {currentUser.lastName}</h4>
					<br/>
					<div class={styles.horizontalLine}></div>
					Welcome to Shuffle Up Poker					
					<br/>
					Our commitment to excellence is matched only by our passion for those we serve.

				</div>

			</div>
			<div className={styles.statCardGrid}>
				<div className={["", styles.statCard].join(" ")}>
					<div className={styles.dashWidget}>
						<span className={styles.dashWidgetBg1}><i className="fa fa-lock" aria-hidden="true"></i></span>
						<div className={[" ", styles.dashWidgetInfo].join(" ")} >
							<h3 className={styles.dashWidgetInfoH3}>{adminCount}</h3>
							<span className={styles.widgetTitle1}>Admins <i class="fa fa-check" aria-hidden="true"></i></span>
						</div>
					</div>
				</div>
				<div className={["", styles.statCard].join(" ")}>
					<div className={styles.dashWidget}>
						<span className={styles.dashWidgetBg2}><i className="fa fa-user-o" aria-hidden="true"></i></span>
						<div className={[" ", styles.dashWidgetInfo].join(" ")} >
							<h3 className={styles.dashWidgetInfoH3}>{employeeCount}</h3>
							<span className={styles.widgetTitle2}>Employees <i class="fa fa-check" aria-hidden="true"></i></span>
						</div>
					</div>
				</div>
				<div className={["", styles.statCard].join(" ")}>
					<div className={styles.dashWidget}>
						<span className={styles.dashWidgetBg3}><i className=" fa fa-calendar" aria-hidden="true"></i></span>
						<div className={[" ", styles.dashWidgetInfo].join(" ")} >
							<h3 className={styles.dashWidgetInfoH3}>{appsTodayCount}</h3>
							<span className={styles.widgetTitle3}>Events Today <i class="fa fa-check" aria-hidden="true"></i></span>
						</div>
					</div>
				</div>
				<div className={["", styles.statCard].join(" ")}>
					<div className={styles.dashWidget}>
						<span className={styles.dashWidgetBg4}><i className="fa fa-calendar-plus-o" aria-hidden="true"></i></span>
						<div className={[" ", styles.dashWidgetInfo].join(" ")} >
							<h3 className={styles.dashWidgetInfoH3}>{pendingAppsTodayCount}</h3>
							<span className={styles.widgetTitle4}>Pending Events <i class="fa fa-check" aria-hidden="true"></i></span>
						</div>
					</div>
				</div>
			</div>

			<div className="row ">
				<div className="col-12 col-lg-8 col-xl-8">
					<div className="card appointment-panel">
						<div className="card-header">
							<h4 className="card-title d-inline-block">Upcoming Events</h4> <NavLink to="/events" className="btn btn-primary float-end">View all</NavLink>
						</div>
						<div className="card-body">
							<div className="table-responsive">
								<table className="table mb-0">
									<thead className="d-none">
										<tr>
											<th>Employee Name</th>
											<th>Admin Name</th>
											<th>Timing</th>
											<th className="text-right">Status</th>
										</tr>
									</thead>
									<tbody>
										{bookedEvents.map((apt) => {
											return (
												<tr>
													<td className={styles.appointmentTableTd}>
														<a className="avatar" href="">{apt?.employeeId?.userId?.firstName?.charAt(0)}</a>
														<h2 className='ps-3'><a href="">{apt?.employeeId?.userId?.firstName} {apt?.employeeId?.userId?.lastName} <span>{apt?.employeeId?.address}</span></a></h2>
													</td>
													<td>
														<h5 className="time-title p-0">Appointment With</h5>
														<p>Dr. {apt?.adminId?.userId?.firstName} {apt?.adminId?.userId?.lastName}</p>
													</td>
													<td>
														<h5 class="time-title p-0">Timing</h5>
														<p>{apt?.appointmentTime}</p>
													</td>
												</tr>
											)
										})
										}


									</tbody>
								</table>
								{(!bookedEvents || bookedEvents?.length === 0) &&
									<h3 className='mt-5 text-center '>
										You have no events today
									</h3>		
								}
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-4 col-xl-4">
					<div class="card member-panel">
						<div class="card-header bg-white">
							<h4 class="card-title mb-0">Admins</h4>
						</div>
						<div class="card-body">
							<ul class="contact-list">
								{admins && admins.map((doc) => {
									return (
										<li>
											<div class="contact-cont">
												<div class="float-left user-img m-r-10">{}</div>
												<div class="contact-info">
													<span class="contact-name text-ellipsis">{doc.userId?.firstName} {doc.userId?.lastName}</span>
													<span class="contact-date">{doc.department} </span>
												</div>
											</div>
										</li>
									)
								})
								}

							</ul>
						</div>
						<div class="card-footer text-center bg-white">
							<NavLink to="/admins" className="text-muted">View all Admins</NavLink>
						</div>
					</div>
				</div>
			</div>
		</Box>
	);
}
