import React from 'react';
import {
    Box,
    useMediaQuery,
    useTheme
  } from "@mui/material";
import { Link } from 'react-router-dom';
import Navbar from '../../components/Frontend/Header/Navbar';
import TeamBioSection from '../../components/Frontend/Sections/TeamBioSection';
import ThinBanner from '../../components/Frontend/Banner/ThinBanner';
import Footer from '../../components/Frontend/Footer/PrimaryFooter';
import LeftTextHeroBanner from '../../components/Frontend/Banner/LeftTextBanner';
import FullWidthBackgroundSection from '../../components/Frontend/Sections/FullWidthBackgroundSection';
import IconSection from '../../components/Frontend/Sections/IconSection';
import NewsRoomBanner from '../../components/Frontend/Banner/NewsRoomBanner';
import TeamProfileGrid from '../../components/Frontend/Sections/TeamPlayers';
import backgroundImage from "../../assets/img/frontend/background-purple-news.webp";
import CustomText from '../../components/Frontend/Tyopgraphy/CustomText';
import GradientH1 from '../../components/Frontend/Tyopgraphy/GradientH1';
import LargeTealButton from '../../components/Frontend/Button/LargeTealButton';

function TeamPage(){
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return(
        <>
        <Navbar/>
        <NewsRoomBanner 
        accent="Team"
        title="ShuffleUp" 
        backgroundImage={backgroundImage}
        /> 
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile? "column":"row",
            background: "#000",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: '10%',
            paddingRight: '10% !important',
            pb:2
          }}
        >
        <Box sx={{ display: "flex", flexDirection: isMobile?"column":"row", background: "transparent"}}>
        <CustomText variant="h2">Brand</CustomText>
          <GradientH1 style={{ marginTop: '0.5rem'}}>&nbsp;Ambassadors</GradientH1>
          </Box>
          <LargeTealButton style={{ marginTop: isMobile?'1rem':'2rem', marginBottom: isMobile? "1rem":"0"}} as={Link} to={'/contact'}>Apply Now</LargeTealButton>
        </Box>         
        <TeamProfileGrid/>
        <TeamBioSection/>
        <IconSection/>
        <FullWidthBackgroundSection/>
        <LeftTextHeroBanner/>
        <ThinBanner/>
        <Footer/>
        </>
    );
}
export default TeamPage;