import React from 'react';
import CheckoutSection from '../../components/Frontend/Sections/CheckoutSection';
import Navbar from '../../components/Frontend/Header/Navbar';
import Footer from '../../components/Frontend/Footer/PrimaryFooter';
import ThinBanner from '../../components/Frontend/Banner/ThinBanner';
import NewsRoomBanner from '../../components/Frontend/Banner/NewsRoomBanner';
import ProductBanner from "../../assets/img/frontend/productAndPolicies.webp";
import { Box } from '@mui/material';
import CustomText from '../../components/Frontend/Tyopgraphy/CustomText';
import backgroundImage from "../../assets/img/frontend/productAndPolicies.webp";

function CheckoutWizardPage() {
    return (
        <>
            <Navbar />
            <Box
                sx={{
                    marginTop: "-5px",
                    marginBottom: "0px",
                    width: "100%",
                    height: "60vh",
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    color: "#fff",
                    padding: "1rem",
                }}
            >
                {/* Accent Title */}
                <CustomText sx={{ marginBottom: "0px", paddingBottom: "0px" }}>
                    Checkout
                </CustomText>
            </Box>
            <CheckoutSection />
            <ThinBanner />
            <Footer />
        </>
    );
}
export default CheckoutWizardPage;