import React from "react";
import { Button } from "@mui/material";

const SmallTealButtonOutline = ({ children, onClick, component = "button", to }) => {
  return (
    <Button
      onClick={onClick}
      to={to}
      component={component}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 19px",
        gap: "10px",
        width: "100px",
        marginTop: "2rem",
        height: "37px",
        border: "2px solid #5AD4D2",
        background: "transparent",
        borderRadius: "4px",
        color: "#5AD4D2",
        fontWeight: "bold",
        textTransform: "none",
        "&:hover": {
          background: "#5AD4D2", // Slightly darker teal on hover
          color: "#fff",
          border: "2px solid #5AD4D2"
        },
      }}
    >
      {children}
    </Button>
  );
};

export default SmallTealButtonOutline;
