import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/Frontend/Header/Navbar';
import ThinBanner from '../../components/Frontend/Banner/ThinBanner';
import Footer from '../../components/Frontend/Footer/PrimaryFooter';
import NewsRoomBannerEvents from '../../components/Frontend/Banner/NewsRoomBannerEvents';
import LeftTextHeroBanner from '../../components/Frontend/Banner/LeftTextBanner';
import ImageCarouselSection from '../../components/Frontend/Carousel/ImageCarousel';
import IconSection from '../../components/Frontend/Sections/IconSection';
import GallerySection from '../../components/Frontend/Sections/GallerySection';
import backgroundImage from "../../assets/img/frontend/background-purple-news.webp";
import PokerBanner from "../../assets/img/frontend/sup-poker-banner2.png";
import ImageBanner from '../../components/Frontend/Banner/ImageBanner';
import ImageOnlyCarousel from '../../components/Frontend/Sections/ImageOnlyCarouse';
import iconBackground from "../../assets/img/frontend/home_achievements_banner.webp";

import SAPokerOpen1 from "../../assets/img/frontend/CaledonBanner.png";
import SAPokerOpen2 from "../../assets/img/frontend/SAOpenPokerEvent2.png";
import SAPokerOpen3 from "../../assets/img/frontend/SAOpenPokerEvent3.png";
import SAPokerOpen4 from "../../assets/img/frontend/Splendido12.png";

import Win1 from "../../assets/img/frontend/Win3.png";
import Win2 from "../../assets/img/frontend/Win2.png";
import Win3 from "../../assets/img/frontend/Win3.png";
//import Wes from "../../assets/img/frontend/WesBlom.webp"

// import Win4 from "../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
// import Win5 from "../../assets/img/frontend/MarkoNortje.jpeg";
// import Win6 from "../../assets/img/frontend/ODWSugen.jpeg";
// import Win7 from "../../assets/img/frontend/KirithHaria.jpeg";
// import Win8 from "../../assets/img/frontend/AhmedKarriem.jpeg";
// import Win9 from "../../assets/img/frontend/Evasan.jpeg";
// import Win10 from "../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
// import Win11 from "../../assets/img/frontend/CaledonGallery/MuazzGani-HighRoller.jpeg";
// import Win12 from "../../assets/img/frontend/Win3.png";


import SAP1 from "../../assets/img/frontend/SAPoker/SAP1.jpg";
import SAP2 from "../../assets/img/frontend/SAPoker/SAP2.jpg";
import SAP3 from "../../assets/img/frontend/SAPoker/SAP3.jpg";
import SAP4 from "../../assets/img/frontend/SAPoker/SAP4.jpg";
import SAP5 from "../../assets/img/frontend/SAPoker/SAP5.jpg";
import SAP6 from "../../assets/img/frontend/SAPoker/SAP6.jpg";
import SAP7 from "../../assets/img/frontend/SAPoker/SAP7.jpg";

import Win4 from "../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
import Win5 from "../../assets/img/frontend/MarkoWinnersPhoto.jpeg";
import Win6 from "../../assets/img/frontend/ODWSugen.jpeg";
import Win13 from "../../assets/img/frontend/WesBlom.webp"
import Win7 from "../../assets/img/frontend/KirithHaria.jpeg";
import Win8 from "../../assets/img/frontend/AhmedKarriem.jpeg";
import Win9 from "../../assets/img/frontend/Evasan.jpeg";
import Win10 from "../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
import Win11 from "../../assets/img/frontend/CaledonGallery/MuazzGani-HighRoller.jpeg";
import Win12 from "../../assets/img/frontend/Win3.png";
import Wes from "../../assets/img/frontend/WesBlom.webp";
import Benjamin from "../../assets/img/frontend/Benjamin.jpeg"

import SP1 from "../../assets/img/frontend/SP1.png";
import SP2 from "../../assets/img/frontend/SP2.png";
import SP3 from "../../assets/img/frontend/SP3.png";
import SP4 from "../../assets/img/frontend/SP4.png";
import SP5 from "../../assets/img/frontend/SP5.jpeg";
import SP6 from "../../assets/img/frontend/SP6.jpeg";
import SP7 from "../../assets/img/frontend/SP7.jpeg";
import SP8 from "../../assets/img/frontend/SP8.jpeg";

import CG1 from "../../assets/img/frontend/CaledonGallery/CG1.png";
import CG2 from "../../assets/img/frontend/CaledonGallery/CG2.png";
import CG3 from "../../assets/img/frontend/CaledonGallery/CG3.png";
import CG4 from "../../assets/img/frontend/CaledonGallery/CG4.png";
import CG6 from "../../assets/img/frontend/CaledonGallery/QB_04218.jpeg";
import CG11 from "../../assets/img/frontend/CaledonGallery/QB_04195.jpeg";
import CG12 from "../../assets/img/frontend/CaledonGallery/QB_04203.jpeg";
import CG14 from "../../assets/img/frontend/CaledonGallery/QB_04156.jpeg";
import CG15 from "../../assets/img/frontend/CaledonGallery/QB_04154.jpeg";
import CG16 from "../../assets/img/frontend/CaledonGallery/QB_04161.jpeg";
import CG17 from "../../assets/img/frontend/CaledonGallery/QB_04148.jpeg";
import CG18 from "../../assets/img/frontend/CaledonGallery/QB_04146.jpeg";
import CG19 from "../../assets/img/frontend/CaledonGallery/QB_04144.jpeg";
import CG20 from "../../assets/img/frontend/CaledonGallery/QB_04144.jpeg";
import CG21 from "../../assets/img/frontend/CaledonGallery/QB_04176.jpeg";

import B1 from "../../assets/img/frontend/B1.png";
import B2 from "../../assets/img/frontend/B2.png";
import B3 from "../../assets/img/frontend/B3.png";
import B4 from "../../assets/img/frontend/B4.png";
import BG1 from "../../assets/img/frontend/BG1.png";
import BG2 from "../../assets/img/frontend/BG2.png";
import BG3 from "../../assets/img/frontend/BG3.png";
import BG4 from "../../assets/img/frontend/BG4.png";
import BG5 from "../../assets/img/frontend/BG5.png";
import BG6 from "../../assets/img/frontend/BG6.png";
import BG7 from "../../assets/img/frontend/BG7.png";
import BG8 from "../../assets/img/frontend/BG8.png";
import { Shop2 } from '@mui/icons-material';

const NotFound = () => <h2 style={{ margin: '70px' }}>This Path is not available</h2>;

const events = [
  {
    id: 1,
    image: `${SAPokerOpen1}`,
    venue: "Caledon Casino & Resort",
    series: "Caledon Poker Series",
    date: "29 Jan - 2 Feb 2025",
    type: "Past",
    slug: "caledon-series-2025",
    title: "Caledon Poker Series",
    description: "ShuffleUp Poker is excited to kickoff the year with the Caledon Poker Series at the incredible Caledon Casino & Resort",
    registrationDeadline: "06 Jan 2025",
    eventLink: "/events/caledon-series-2025",
    imageCarousel: [
      `${CG1}`,
      `${CG14}`,
      `${CG15}`,
      `${CG16}`
    ],
    galleryImages: [
      `${CG6}`,
      `${CG11}`,
      `${CG12}`,
      `${CG4}`,
      `${CG14}`,
      `${CG15}`,
      `${CG16}`,
      `${CG17}`,
      `${CG20}`,
      `${CG21}`
    ],
    winners: [
        {
          image: `${Win6}`,
          title: "Sugen Singh",
          event: "Main Event",
          awards: "The Caledon Poker Series 2025",
          description: "R260 000",
        },
        {
          image: `${Wes}`,
          title: "Wes Blom",
          event: "Turbo",
          awards: "The Caledon Poker Series 2025",
          description: "R92,000"
        },
        {
          image: `${Win11}`,
          title: "Muaaz Gani",
          event: "High Roller",
          awards: "The Caledon Poker Series 2025",
          description: "R320,000"
        },
        {
          image: `${Win4}`,
          title: "Gavin Sardini",
          event: "R10k One Day Event",
          awards: "The Caledon Poker Series 2025",
          description: "R120,000"
        },
    ]
  },
  {
    id: 0,
    image: `${SAPokerOpen4}`,
    venue: "Monte Casino",
    series: "Splendido Poker Series 12",
    date: "19 March - 30 March 2025",
    type: "Current",
    slug: "splendido-12-poker-series",
    title: "Splendido Poker Series 12",
    description: "ShuffleUp Poker is excited to announce the Splendido Poker Series 12 at the incredible Montecasino Events & Hotels in Fourways",
    registrationDeadline: "06 Jan 2025",
    eventLink: "/events/splendido-12-poker-series",
    imageCarousel: [
      `${SP2}`,
      `${SP6}`,
      `${SP7}`,
      `${SP5}`
    ],
    galleryImages: [
    ],
    winners: [
    ],
    buttons: [
      { "type": "dark", "text": "Players Handbook", "url": `${process.env.PUBLIC_URL}/images/sup_players_handbook_splendido.pdf` },
      { "type": "dark", "text": "Structure", "url": "/events/structure" },
      { "type": "dark", "text": "Schedule", "url": "/events/schedule" },
      { "type": "teal", "text": "Enquire Now", "url": "/events/enquiry" }
     ]
  },
  {
    id: 0,
    image: `${SAPokerOpen4}`,
    venue: "Monte Casino",
    series: "Monte Casino Invitational Poker Series",
    date: "23rd July to 3rd August",
    type: "Current",
    slug: "monte-casino-invitational-poker-series",
    title: "Monte Casino Invitational Poker Series",
    description: "Join us at Monte Casino in Johannesburg for an unforgettable poker extravaganza from 23rd July to 3rd August. With events catering to all buy-in levels, there's something for every player, whether you're a seasoned pro or just starting out.",
    registrationDeadline: "06 Jan 2025",
    eventLink: "/events/monte-casino-invitational-poker-series",
    imageCarousel: [
      `${SP5}`,
      `${SP7}`,
      `${SP2}`,
      `${SP6}`
    ],
    galleryImages: [
      // No gallery images provided.
    ],
    winners: [
      // No winners data provided.
    ]
  },
  {
    id: 4,
    image: `${SAPokerOpen1}`,
    venue: "Caledon Casino & Resort",
    series: "Poker at Caledon Resort & Casino",
    date: "4 Jun - 8 Jun 2025",
    type: "Current",
    slug: "poker-at-caledon-resort",
    title: "Poker at Caledon Resort & Casino",
    description: "Join us at Caledon Hotel and Casino from 4th to 8th June for an action-packed poker week! Compete in four exciting events, including the 50K Super High Roller, and battle against top players from across Southern Africa.",
    registrationDeadline: "18 Mar 2025",
    eventLink: "/events/poker-at-caledon-resort",
    imageCarousel: [
      `${CG1}`,
      `${CG14}`,
      `${CG15}`,
      `${CG16}`
    ],
    galleryImages: [
      // No gallery images provided.
    ],
    winners: [
      // No winners data provided.
    ],
  },
  {
    id: 5,
    image: `${SAPokerOpen4}`,
    venue: "Monte Casino",
    series: "The SA Poker Open",
    date: "22 Oct to 2 Nov 2025",
    type: "Current",
    slug: "the-sa-poker-open", // Slug for dynamic routing
    title: "The SA Poker Open",
    description: "Get ready for the biggest poker event of the year! The SA Poker Open at Montecasino, from 22nd October to 2nd November, is set to break all previous prize pool records with 9 world-class events.",
    registrationDeadline: "06 Jan 2025",
    eventLink: "/events/the-sa-poker-open",
    imageCarousel: [
      `${SAP1}`,
      `${SAP2}`,
      `${SAP3}`,
      `${SAP5}`
    ],
    galleryImages: [
      `${SAP1}`,
      `${SAP2}`,
      `${SAP3}`,
      `${SAP6}`,
      `${SAP4}`,
      `${SAP5}`,
      `${SAP7}`
    ],
    winners: [
        {
            image: `${Win5}`,
            title: "Marko Nortje",
            event: "Main Event",
            description: "R800 000",
            awards: "SA Poker Open 2024",
          },
          {
            image: `${Win10}`,
            title: "Gavin Sardini",
            event: "R10k One Day Event",
            awards: "SA Poker Open 2024",
            description: "R120,000"
          },
          {
            image: `${Win7}`,
            title: "Kirith Haria",
            event: "Mugsy Turbo",
            awards: "SA Poker Open 2024",
            description: "R60,000"
          },
          {
            image: `${Win8}`,
            title: "Ahmed Karrim",
            event: "High Roller",
            awards: "SA Poker Open 2024",
            description: "R1,000,000"
          },
          {
            image: `${Benjamin}`,
            title: "Benjamin Lingenfelder",
            event: "Mystery Bounty",
            awards: "SA Poker Open 2024",
            description: "R49,100"
          },
          {
            image: `${Win9}`,
            title: "Evasan Chettier",
            event: "Freezout",
            awards: "SA Poker Open 2024",
            description: "R62,000"
          },
    ]
},
];

function EventsSinglePage() {
  const { slug } = useParams(); // Get slug from URL

  // Find the event that matches the slug
  const event = events.find((event) => event.slug === slug);

  if (!event) {
    return <NotFound />;
  }

  const accent = event.type === "Current" ? "Current" : "Past";
  const buttons = event.buttons;

  return (
    <>
      <Navbar />
      <NewsRoomBannerEvents event={event} accent={accent} title="Poker Events" backgroundImage={backgroundImage} buttons={buttons} />
      <ImageBanner heading={event.title} description={event.description} background={event.image} type={event.type} />
      <ImageOnlyCarousel images={event.imageCarousel} slideDelay={3000} darkBackground="#000" />
      <IconSection backgroundImage={iconBackground} />
      {/* Only show Past Winners section if winners data exists */}
      {event.winners && event.winners.length > 0 && (
        <ImageCarouselSection
          title="Past Winners"
          description="Explore our amazing collection of moments captured in stunning imagery."
          background="#000"
          backgroundPosition="bottom"
          carouselItems={event.winners}
        />
      )}
      {/* Only show Gallery section if galleryImages exist */}
      {event.galleryImages && event.galleryImages.length > 0 && <GallerySection images={event.galleryImages} />}
      <LeftTextHeroBanner />
      <ThinBanner />
      <Footer />
    </>
  );
}

export default EventsSinglePage;
