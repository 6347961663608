import React from "react";
import { Box, Grid, Typography, Container, useMediaQuery, useTheme } from "@mui/material";
import Adam from "../../../assets/img/frontend/Adam.png";
import Demi from "../../../assets/img/frontend/Demi.png";
import Toni from "../../../assets/img/frontend/Toni.png";
import Anastasia from "../../../assets/img/frontend/Anastasia.png";
import Neville from "../../../assets/img/frontend/Neville.png";
import Gary from "../../../assets/img/frontend/CaledonGallery/QB_04214.jpeg";
import CustomText from "../Tyopgraphy/CustomText";
import GradientH1 from "../Tyopgraphy/GradientH1";

const teamMembers = [
  {
    id: 2,
    image: `${Demi}`,
    name: "Demi",
    role: "Operations, Player Experience & Customer Support",
    bio:
      "As the Head of Operations at Shuffle Up Poker, Demi brings a wealth of  expertise in managing event logistics and ensuring seamless customer  experiences. With a strong focus on player satisfaction, Demi is  dedicated to delivering top-tier service and smooth operations at every  event. She leads the charge in player experience, ensuring that each  participant feels valued and supported, and oversees our customer  support team to resolve queries efficiently. Demi’s passion for creating unforgettable events is matched only by her commitment to operational  excellence.",
  },
  {
    id: 3,
    image: `${Anastasia}`,
    name: "Anastasia",
    role: "Admin, Direct Marketing & Sales",
    bio:
      "Anastasia is the driving force behind our administrative operations, ensuring  everything runs smoothly behind the scenes. As Head of Admin, she  manages all aspects of business operations while also spearheading our  direct marketing and sales strategies. With a keen eye for detail and a  deep understanding of the poker industry, Anastasia works to grow our  customer base, attract new players, and create innovative marketing  campaigns. Her strategic approach and organizational skills are integral to the success of our company.",
  },
  {
    id: 4,
    image: `${Toni}`,
    name: "Toni",
    role: "Satellites & Digital Marketing",
    bio:
      "Toni leads our satellite events and digital marketing strategies, ensuring  that our online presence is as dynamic and engaging as the live events  we host. With a strong background in digital marketing, Toni leverages  innovative online platforms to reach new players, drive traffic, and  enhance player engagement. As Head of Satellites, Toni designs and  manages satellite tournament systems that provide players with the  chance to qualify for our major poker events. Her creative approach and  digital expertise play a key role in growing our community and reaching a wider audience.",
  },
  {
    id: 1,
    image: `${Adam}`,
    name: "Adam",
    role: "Business Development & Marketing",
    location: "Cape Town & Greater South Africa",
    bio:
      "Based in Cape Town, Adam heads our marketing efforts and player experience  initiatives across Africa. With extensive experience in the poker world  and a deep understanding of regional markets, Adam develops targeted  marketing campaigns to expand our presence and attract players  throughout the continent. As Head of Sales for Cape Town and Greater  Africa, he is d edicated to building strong relationships with both  players and partners to ensure continued growth and success. Adam’s  combination of marketing expertise and passion for poker helps deliver  exciting and dynamic experiences for all players.",
  },
  {
    id: 6,
    image: `${Neville}`,
    name: "Neville Green",
    role: "Tournament Director",
    bio: "Neville Green is a highly experienced Tournament Director with over 20  years in the Casino and Poker industries. Well known in the South  African poker scene, he brings a wealth of knowledge and expertise to  tournament operations. His extensive background ensures smooth and  professional event management, making him a respected figurein the industry."
  },
  {
    id: 7,
    image: `${Gary}`,
    name: "Gary Allen",
    role: "Tournament Director",
    bio: "Gary Allen is an experienced TDA Tournament Director with over thirty  year’s involvement in the poker industry as a player, organiser and  adjudicator. Born in the United Kingdom and from a military background,  he brings a strong presence conveying confidence and discipline to his  role as a ShuffleUp Tournament Director. Gary is well known and liked  throughout the industry and is recognised for his approachable and  professional demeanour."
  }
];

const TeamBioSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ background: "#000", padding: "4rem 0", color: "#fff" }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            background: "transparent",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CustomText variant="h2">ShuffleUp</CustomText>
          <GradientH1>Team</GradientH1>
        </Box>
        <Grid container spacing={2}> {/* Tighter rows */}
          {teamMembers.map((member) => (
            <Grid item xs={12} key={member.id}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "stretch", // Ensures equal height
                  gap: 3,
                  background: "transparent",
                  flexDirection: { xs: "column", md: "row" }, // Stack on mobile, row on larger screens
                }}
              >
                {/* Image Section */}
                <Box
                  component="img"
                  src={member.image}
                  alt={member.name}
                  sx={{
                    width: isMobile ? "100%" : "300px",
                    height: "350px",
                    paddingTop: "0px",
                    borderRadius: "8px",
                    objectFit: "cover",
                    flexShrink: 0, // Prevents shrinking
                  }}
                />

                {/* Content Section (Matches Image Height) */}
                <Box
                  sx={{
                    border: "1px solid #AE3DEF",
                    padding: " 3rem 1.5rem",
                    background: "transparent",
                    borderRadius: "8px",
                    boxShadow: "0px 0px 45px rgba(174, 61, 239, 0.35)",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "top", // Centers text vertically
                    height: "350px", // Matches image height
                  }}
                >
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    {member.name} - {member.role}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ opacity: 0.8, marginBottom: "1rem" }}>
                    {member.location}
                  </Typography>
                  <Typography variant="body1">{member.bio}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default TeamBioSection;
