import React, { useContext } from "react";
import { IconButton, Badge } from "@mui/material";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { CartContext } from "../../../Context/CartContext";

const CartIcon = ({ onClick }) => {
  const { cartItems } = useContext(CartContext);

  return (
    <IconButton
      sx={{ display: { xs: "none", md: "flex" }, color: "white" }}
      onClick={onClick}
    >
      <Badge badgeContent={cartItems.length} color="secondary">
        <ShoppingBagOutlinedIcon />
      </Badge>
    </IconButton>
  );
};

export default CartIcon;
