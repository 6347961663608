import React, { useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import TealButton from '../../components/Frontend/Button/TealButton';
import CustomButton from '../../components/Frontend/Button/CustomButton';
import { Link } from 'react-router-dom';
import RegistrationModal from '../../components/Frontend/Modals/RegistrationModal';

const WarningBanner = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

  return (
    <Box sx={{ marginLeft: "auto", marginRight: "auto", textAlign: 'center', p: 2, background: "transparent", maxWidth:"800px", justifyContent: "center", alignContent: "center"}}>
      <Typography variant="caption" sx={{ color: "white", fontWeight: 'bold', mb: 2 }}>
        NO PERSON UNDER THE AGE OF 18 YEARS PERMITTED TO GAMBLE. OPERATOR LICENCED BY THE WESTERN CAPE GAMBLING AND RACING BOARD. NATIONAL RESPONSIBLE GAMBLING PROGRAMME TOLL FREE COUNSELLING LINE 0800 006 008 OR WHATSAPP HELP TO 076 675 0710. WINNERS KNOW WHEN TO STOP.
      </Typography>
      <Box sx={{ background: "transparent", display: 'flex', flexDirection: isMobile?"column": "row", justifyContent: 'center', gap: 2 }}>
        <TealButton style={{ width: isMobile ? "100%": "177px"}}   onClick={handleOpenModal}>Enquire Now</TealButton>
        <CustomButton
  as={Link}
  target="_blank"
  to={`${process.env.PUBLIC_URL}/images/sup_players_handbook_splendido.pdf`}
>
  Download
</CustomButton>
      </Box>
      <RegistrationModal open={modalOpen} handleClose={handleCloseModal} />
    </Box>
  );
};

export default WarningBanner;
