import React, { useState } from "react";
import {
  Drawer,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from "@mui/material";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Logo from "../../../assets/img/frontend/sup-logo.svg";
import CustomButton from "../Button/CustomButton";

const MobileDrawer = ({ open, onClose }) => {
  const [eventsOpen, setEventsOpen] = useState(false);

  const handleToggleEvents = () => {
    setEventsOpen(!eventsOpen);
  };

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          background: "#0E0B0E",
          color: "white",
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Close Button */}
        <Box sx={{ alignSelf: "flex-end", background: "transparent" }}>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>

        {/* Logo at the Top */}
        <Box sx={{ mb: 4, background: "transparent" }}>
          <img src={Logo} alt="Logo" style={{ maxWidth: "150px" }} />
        </Box>

        {/* Centered Menu Items */}
        <Box sx={{ width: "100%", background: "transparent" }}>
          <List sx={{ width: "100%", textAlign: "center", background: "transparent" }}>
            {/* Expandable Events Item */}
            <ListItem
              button
              onClick={handleToggleEvents}
              sx={{ justifyContent: "center", background: "transparent" }}
            >
              <ListItemText primary="Events" />
              {eventsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={eventsOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  component={Link}
                  to="/events"
                  onClick={() => {
                    onClose();
                    setEventsOpen(false);
                  }}
                  sx={{ pl: 4, justifyContent: "center", background: "transparent" }}
                >
                  <ListItemText primary="Current Events" />
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/events/past"
                  onClick={() => {
                    onClose();
                    setEventsOpen(false);
                  }}
                  sx={{ pl: 4, justifyContent: "center", background: "transparent" }}
                >
                  <ListItemText primary="Past Events" />
                </ListItem>
              </List>
            </Collapse>

            {/* Other Menu Items */}
            <ListItem
              button
              component={Link}
              to="/team"
              onClick={onClose}
              sx={{ justifyContent: "center", background: "transparent" }}
            >
              <ListItemText primary="Team ShuffleUp" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/live-feed"
              onClick={onClose}
              sx={{ justifyContent: "center", background: "transparent" }}
            >
              <ListItemText primary="Live Reporting" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/news"
              onClick={onClose}
              sx={{ justifyContent: "center", background: "transparent" }}
            >
              <ListItemText primary="News" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/shop"
              onClick={onClose}
              sx={{ justifyContent: "center", background: "transparent" }}
            >
              <ListItemText primary="Shop" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/contact"
              onClick={onClose}
              sx={{ justifyContent: "center", background: "transparent" }}
            >
              <ListItemText primary="Contact" />
            </ListItem>
          </List>
        </Box>

        {/* Spacer to push Login button to bottom */}
        <Box sx={{ flexGrow: 1, background: "transparent" }} />

        {/* Full-Width Login Button using CustomButton */}
        <Box sx={{ width: "100%", mt: 2, background: "transparent" }}>
          <CustomButton
            component={Link}
            to="/login"
            onClick={onClose}
            sx={{
              width: "100%",
              background: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%)",
              color: "#fff",
              py: 1.5,
              fontWeight: "bold",
              borderRadius: 1,
            }}
          >
            Login
          </CustomButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MobileDrawer;
