import React from "react";
import { Button } from "@mui/material";

const SmallGradientButton = ({ children, onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "14px 24px",
        gap: "32px",
        width: "94px",
        border: "1px solid",
        borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
        height: "48px",
        background: "linear-gradient(180deg, #21174F 16.75%, #0E0B0E 140.05%)",
        borderRadius: "4px",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "none",
        "&:hover": {
          background: "linear-gradient(180deg, #2B1F60 16.75%, #171016 140.05%)",
        },
      }}
    >
      {children}
    </Button>
  );
};

export default SmallGradientButton;
