import React from 'react';
import ShopProductSection from '../../components/Frontend/Sections/ShopProductSection';
import Navbar from '../../components/Frontend/Header/Navbar';
import ThinBanner from '../../components/Frontend/Banner/ThinBanner';
import Footer from '../../components/Frontend/Footer/PrimaryFooter';
import NewsRoomBanner from '../../components/Frontend/Banner/NewsRoomBanner';
import backgroundImage from "../../assets/img/frontend/background-purple-news.webp";

function ShopPage() {

    return (
        <>
            <Navbar />
            <NewsRoomBanner
                accent="Official"
                title="Merchandise"
                subtitle="Style that wins"
                description="Step into the world of poker with style and confidence! At ShuffleUp Poker, we offer premium clothing and custom gear designed for players who love the game. From sleek bomber jackets to lucky hoodies, every piece combines comfort, quality, and a touch of poker flair."
                backgroundImage={backgroundImage}
            />
            <ShopProductSection />
            <ThinBanner />
            <Footer />
        </>
    );
}
export default ShopPage;