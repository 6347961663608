import React from "react";
import { Box, Typography, Grid, Container, useMediaQuery, useTheme } from "@mui/material";
import Vector1 from "../../../assets/img/frontend/Vector_Icon1.svg";
import Vector2 from "../../../assets/img/frontend/Vector_Icon2.svg";
import Vector3 from "../../../assets/img/frontend/Vector_Icon3.svg";
import Vector4 from "../../../assets/img/frontend/Vector_Icon4.svg";
import GradientH6 from "../Tyopgraphy/GradientH6";

const icons = [
  { id: 1, image: Vector4, title: "Fast Shipping" },
  { id: 2, image: Vector1, title: "Secure Payments" },
  { id: 3, image: Vector2, title: "24/7 Support" },
  { id: 4, image: Vector3, title: "Best Quality" },
];

const IconSection = ({ backgroundImage }) => {
  console.log("Background Image:", backgroundImage); // Debugging
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  return (
    <Box
      sx={{
        position: "relative",
        background: backgroundImage ? "transparent" : "#000", // Default dark background
        padding: "4rem 0",
        color: "#fff",
        height: "100%", // Ensuring a visible section
      }}
    >
      {/* ✅ Background Image Fix */}
      {backgroundImage && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            zIndex: -1,
            opacity: 1,
          }}
        />
      )}

      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center">
          {icons.map((icon) => (
            <Grid item xs={12} sm={6} md={3} key={icon.id}>
              <Box
                sx={{
                  background: "transparent",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {/* Icon Image */}
                <Box
                  component="img"
                  src={icon.image}
                  alt={icon.title}
                  sx={{
                    background: "transparent",
                    width: "150px",
                    height: "150px",
                    marginBottom: "1rem",
                  }}
                />
                {/* Title */}
                <GradientH6 text={icon.title} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default IconSection;
