import { Routes, Route } from 'react-router-dom';
import React, { useContext } from 'react';
import ResetPasswordPage from './components/Login/PasswordResetPage';

import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import NewsPage from './pages/news/NewsPage';
import NewsPageSingle from './pages/news/NewsPageSingle';
import LiveFeedPage from './pages/LiveFeedPage';
import EventsPage from './pages/events/EventsPage';
import EventsPastPage from './pages/events/EventsPastPage';
import TeamPage from './pages/team/TeamPage';
import WebsitePoliciesPage from './pages/policies/WebsitePoliciesPage';
import EventsSinglePage from './pages/events/EventsSinglePage';
import EventsStructure from './pages/events/EventsStructure';

import MailBox from './components/Inbox/Mail';
import Chat from './components/Chat/Chat';
import DocumentContent from './components/Content/DocumentFileManager';
import MediaLibrary from './components/Content/MediaContent';
import PagesScreen from './components/Content/PageContent';
import AddPage from './components/Content/AddPageContent';
import PostsScreen from './components/Content/PostContent';
import Config from './components/System/Config';
import BookingSimple from './components/BookingSimple/BookingSimple';
//import ShopList from './components/Storefront/ShopList';
import ShopPage from './pages/shop/ShopPage';
import LoginPage from './components/Login/Login'
import CorporateLogin from './components/Login/CorporateLogin';

import SignupPage from './components/SignUp/SignupPage';
import CheckoutWizard from './components/Checkout/Checkout';
import ConfirmationPage from './components/Checkout/ConfirmationPage';
import CancelledPage from './components/Checkout/CancelledPage';

import Dashboard from './components/dashboard/Dashboard';
import GeneralUserDashboard from './components/dashboard/GeneralUserDashboard';
import AdminDashboard from './components/dashboard/AdminDashboard';
import CorporateDashboard from './components/dashboard/CorporateDashboard';

import AdminEvent from './components/Events/AdminEvent';
import CustomerEvent from './components/Events/CustomerEvent';
import EmployeeEvent from './components/Events/EmployeeEvent';

import AddUser from './components/User/AddUser3';
import UserList from "./components/User/UserList3";
import EditUser from "./components/User/EditUser3";
import User from './components/User/User';

import AddPatient from './components/Patient/AddPatient';
import PatientList from "./components/Patient/PatientList";
import EditPatient from "./components/Patient/EditPatient";
import Patient from './components/Patient/Patient';

import AddCustomer from './components/Customer/AddCustomer';
import CustomerList from "./components/Customer/CustomerList";
import EditCustomer from "./components/Customer/EditCustomer";
import Customers from './components/Customer/Customer';

import AddEmployee from './components/Employee/AddEmployee';
import EmployeeList from "./components/Employee/EmployeeList";
import EditEmployee from "./components/Employee/EditEmployee";
import Employee from './components/Employee/Employee';

import AddTeam from './components/Team/AddTeam';
import TeamList from "./components/Team/TeamList";
import EditTeam from "./components/Team/EditTeam";
import Team from './components/Team/Team';

import AddDoctor from './components/Doctor/AddDoctor';
import DoctorList from "./components/Doctor/DoctorList";
import EditDoctor from "./components/Doctor/EditDoctor";
import Doctor from './components/Doctor/Doctor';

import AddMedicine from './components/Medicine/AddMedicine';
import MedicineList from "./components/Medicine/MedicineList";
import EditMedicine from "./components/Medicine/EditMedicine";
import Medicine from './components/Medicine/Medicine';

import AddProduct from './components/Product/AddProduct';
import ProductList from "./components/Product/ProductList";
import EditProduct from "./components/Product/EditProduct";

import PrescriptionList from "./components/Prescription/PrescriptionList";
import AddPrescription from "./components/Prescription/AddPrescription";
import EditPrescription from "./components/Prescription/EditPrescription";
import Prescription from './components/Prescription/Prescription';
import Success from './components/Prescription/Success';
import Cancel from './components/Prescription/Cancel';

import AdminReportingScreen from './components/Reports/AdminReports';
import CorporateUserReportingScreen from './components/Reports/CorporateReports';
import DoctorUserReportingScreen from './components/Reports/DoctorReports';

import OrdersList from "./components/Order/OrderList";
import EditOrder from "./components/Order/EditOrder";
import Orders from './components/Order/Order';

import TransactionList from './components/Transactions/TransactionList';
import Transaction from './components/Transactions/Transaction';
import AddTransaction from './components/Transactions/AddTransaction';
import Edittransaction from './components/Transactions/EditTransaction';

import { UserContext } from './Context/UserContext'
import PatientDashboard from './components/dashboard/PatientDashboard';
import CustomerDashboard from './components/dashboard/CustomerDashboard';
import EmployeeDashboard from './components/dashboard/EmployeeDashboard';
import DoctorDashboard from './components/dashboard/DoctorDashboard';

import AdminAppointment from './components/Appointment/AdminAppointment';
import PatientAppointment from './components/Appointment/PatientAppointment';
import DoctorAppointment from './components/Appointment/DoctorAppointment';
import CorporateAppointment from './components/Appointment/CorporateAppointment';
import CustomerAppointment from './components/Appointment/CustomerAppointment';
import DoctorProfile from './components/Profile/DoctorProfile';
import PatientProfile from './components/Profile/PatientProfile';
import CustomerProfile from './components/Profile/CustomerProfile';
import EmployeeProfile from './components/Profile/EmployeeProfile';
import AdminProfile from './components/Profile/AdminProfile';
import CorporateProfile from './components/Profile/CorporateProfile';

import Corporate from './components/Corporate/Corporate';
import CorporateList from './components/Corporate/Corporate3';
import AddCorporate from './components/Corporate/AddCorporate3';
import EditCorporate from './components/Corporate/EditCorporate3';
import ContactInfoScreen from './components/Corporate/ContactInfoScreen';

import Products from './components/Product/Product';

import SettingsPage from './components/System/SettingsPage';
import CorporateSettingsPage from './components/System/CorporateSettings';

import Login from './components/Login/Login';
import PatientHistory from './components/Patient/PatientHistory';
import EventsGallery from './pages/events/EventsGallery';
import SingleProductPage from './pages/shop/ProductSinglePage';
import CheckoutWizardPage from './pages/checkout/CheckoutWizardPage';
import EventsSchedule from './pages/events/EventsSchedule';
import EventsEnquiry from './pages/events/EventsEnquiry';
import EventForm from './components/Forms/EventForm';
import EventsEnquiryForm from './pages/events/EventsEnquiry';

/**
 * Constant to return Not Found path message
 * @since 0.1
 * @returns 
 */
const NotFound = () => <h2 style={{ margin: '70px' }}>This Path is not available</h2>

/**
 * Protected admin route function to return child object for Admin user role
 * @since 0.1
 * @param {Object} children 
 * @returns
 */
function ProtectedAdminRoute({ children }) {
    const { currentUser } = useContext(UserContext);
    if (currentUser.userType == "Admin") {
        return children;
    }
}

/**
 * Protected corporate route function to return child object for Corporate user role
 * @since 0.1
 * @param {Object} children 
 * @returns
 */
function ProtectedCorporateRoute({ children }) {
    const { currentUser } = useContext(UserContext);
    if (currentUser.userType == "Corporate") {
        return children;
    }
}

/**
 * Protected staff route function to return child object by staff user role
 * @param {Object} children 
 * @returns 
 */
function ProtectedStaffRoute({ children }) {
    const { currentUser } = useContext(UserContext);
    if (currentUser.userType == "Admin" || currentUser.userType == "Employee" || currentUser.userType == "Nurse" || currentUser.userType == "Doctor") {
        return children;
    }
}

/**
 * Page routes export function for defining page routes
 * @since 0.1
 * @returns Object
 */
export default function PageRoutes() {
    const { currentUser } = useContext(UserContext);
    return (
        <Routes>
            {}
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactPage/>} />
            <Route path="/team" element={<TeamPage/>} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/news/:slug" element={<NewsPageSingle />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/events/:slug" element={<EventsSinglePage />} />
            <Route path="/events/past/:slug" element={<EventsSinglePage />} />
            <Route path="/events/current/:slug" element={<EventsSinglePage />} />
            <Route path="/events/structure" element={<EventsStructure/> }/>
            <Route path="/events/enquiry" element={<EventsEnquiry /> }/>
            <Route path="/events/schedule" element={<EventsSchedule/> }/>
            <Route path="/checkout-pay" element={<CheckoutWizardPage/>} />
            <Route path="/events/past" element={<EventsPastPage />} />
            <Route path="/events/gallery" element={<EventsGallery />} />
            <Route path="/live-feed" element={<LiveFeedPage/>} />
            <Route path="/policies" element={<WebsitePoliciesPage />} />
            <Route path="/events/single" element={<EventsSinglePage />} />

            <Route path="/shop" element={<ShopPage />} />
            <Route path="/shop/:slug" element={<SingleProductPage/>} />
            <Route path="/simple-booking" element={<BookingSimple/>} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/signup' element={<SignupPage />} />
            <Route path="/reset-password/:token" element={<ResetPasswordPage />} />

            <Route path='/checkout' element={<CheckoutWizard />} />
            <Route path='/checkout/confirmation' element={<ConfirmationPage />} />
            <Route path='/checkout/cancelled' element={<CancelledPage />} />

            <Route path="/corporate/:slug" element={<CorporateLogin />} />
            <Route path='/corporate/roadcover' element={<CorporateLogin />} />

            <Route path='/account' element={<Dashboard />} >

                {}
                <Route index element={
                    currentUser.userType == "Admin" ?
                        <GeneralUserDashboard /> :
                        currentUser.userType == "Doctor" ?
                            <DoctorDashboard /> :
                            currentUser.userType == "Patient" ?
                                <PatientDashboard /> :
                                currentUser.userType == "Employee" ?
                                    <EmployeeDashboard /> :
                                    currentUser.userType == "Customer" ?
                                        <CustomerDashboard /> :
                                        currentUser.userType == "Corporate" ?
                                            <CorporateDashboard /> :
                                            <div />}
                />

                {}
                <Route path='users' element={<ProtectedAdminRoute>  <User /> </ProtectedAdminRoute>} >
                    <Route index element={<UserList />} />
                    <Route path='add' element={<AddUser />} />
                    <Route path="edit/:id" element={<EditUser />} />
                </Route>

                {}
                <Route path='mail' element={<MailBox />} >
                </Route>

                <Route path='documents' element={<DocumentContent />} />
                <Route path='media' element={<MediaLibrary />} />
                <Route path='config' element={<ProtectedAdminRoute>  <Config /> </ProtectedAdminRoute>} />

                <Route path='pages' element={<ProtectedAdminRoute>  <PagesScreen /> </ProtectedAdminRoute>} >
                    <Route path='add' element={<AddPage />} />
                    {}
                </Route>

                <Route path='posts' element={<ProtectedAdminRoute>  <PostsScreen /> </ProtectedAdminRoute>} >
                    {}
                    {}
                </Route>


                {}
                <Route path='corporates' element={<Corporate />} >
                    <Route index element={<CorporateList />} />
                    <Route path='add' element={<AddCorporate />} />
                    <Route path="edit/:id" element={<EditCorporate />} />
                </Route>

                {}
                <Route path='patients' element={<Patient />} >
                    <Route index element={<PatientList />} />
                    <Route path='add' element={<AddPatient />} />
                    <Route path="edit/:id" element={<EditPatient />} />
                </Route>

                {}
                <Route path='chat' element={<Chat />} ></Route>

                {}
                <Route path='doctors' element={<Doctor />} >
                    <Route index element={<DoctorList />} />
                    <Route path='add' element={<AddDoctor />} />
                    <Route path="edit/:id" element={<EditDoctor />} />
                </Route>

                <Route path="/account/events" element={
                    currentUser.userType == "Admin" ?
                        <AdminEvent /> :
                                currentUser.userType == "Employee" ?
                                    <EmployeeEvent /> :
                                    currentUser.userType == "Customer" ?
                                        <CustomerEvent /> :
                                            <div />}
                />

                {}
                <Route path='medicines' element={<Medicine />} >
                    <Route index element={<ProtectedStaffRoute> <MedicineList /> </ProtectedStaffRoute>} />
                    <Route path='add' element={<ProtectedAdminRoute> <AddMedicine /> </ProtectedAdminRoute>} />
                    <Route path="edit/:id" element={<ProtectedAdminRoute> <EditMedicine /></ProtectedAdminRoute>} />
                </Route>

                {}
                <Route path='prescriptions' element={<Prescription />} >
                    <Route index element={<PrescriptionList />} />
                    <Route path='add' element={<AddPrescription />} />
                    <Route path="edit/:id" element={<EditPrescription />} />
                    <Route path="success" element={<Success />}></Route>
                    <Route path="cancel" element={<Cancel />}></Route>
                </Route>

                {}
                <Route path='employee' element={<Employee />} >
                    <Route index element={<EmployeeList />} />
                    <Route path='add' element={<AddEmployee />} />
                    <Route path="edit/:id" element={<EditEmployee />} />
                </Route>

                {}
                <Route path='teams' element={<Team />} >
                    <Route index element={<TeamList />} />
                    <Route path='add' element={<AddTeam />} />
                    <Route path="edit/:id" element={<EditTeam />} />
                </Route>

                {}
                <Route path='customers' element={<Customers />} >
                    <Route index element={<CustomerList />} />
                    <Route path='add' element={<AddCustomer />} />
                    <Route path="edit/:id" element={<EditCustomer />} />
                </Route>

                {}
                <Route path='products' element={<Products />} >
                    <Route index element={<ProductList />} />
                    <Route path='add' element={<AddProduct />} />
                    <Route path="edit/:id" element={<EditProduct />} />
                </Route>

                {}
                <Route path='customer-check' element={<ContactInfoScreen />} ></Route>

                <Route path='orders' element={<Orders />} >
                    <Route index element={<OrdersList />} />
                    <Route path="edit/:id" element={<EditOrder />} />
                </Route>

                <Route path='transactions' element={<Transaction />} >
                    <Route index element={<TransactionList />} />
                    <Route path='add' element={<AddTransaction />} />
                    <Route path="edit/:id" element={<Edittransaction />} />
                </Route>

                {}
                <Route path='patient/history/:id' element={<ProtectedStaffRoute> <PatientHistory /> </ProtectedStaffRoute>} >

                </Route>

                {}
                <Route path='bookings' element={
                    currentUser.userType == "Admin" ?
                        <AdminAppointment /> :
                        currentUser.userType == "Doctor" ?
                            <DoctorAppointment /> :
                            currentUser.userType == "Patient" ?
                                <PatientAppointment /> :
                                currentUser.userType == "Corporate" ?
                                    <CorporateAppointment /> :
                                    currentUser.userType == "Customer" ?
                                        <CustomerAppointment /> :
                                        <div />
                }
                />

                <Route path='appointments' element={
                    currentUser.userType == "Admin" ?
                        <AdminAppointment /> :
                        currentUser.userType == "Doctor" ?
                            <DoctorAppointment /> :
                            currentUser.userType == "Patient" ?
                                <PatientAppointment /> :
                                currentUser.userType == "Corporate" ?
                                    <CorporateAppointment /> :
                                    currentUser.userType == "Customer" ?
                                        <CustomerAppointment /> :
                                        <div />
                }
                />

                <Route path='reports' element={
                    currentUser.userType == "Admin" ?
                        <AdminReportingScreen /> :
                        currentUser.userType == "Doctor" ?
                            <DoctorUserReportingScreen /> :
                            currentUser.userType == "Corporate" ?
                                <CorporateUserReportingScreen /> :
                                <div />}
                />

                {}
                <Route path='settings' element={<ProtectedAdminRoute>  <SettingsPage /> </ProtectedAdminRoute>} />
                <Route path='corporate-settings' element={<ProtectedCorporateRoute>  <CorporateSettingsPage /> </ProtectedCorporateRoute>} />

                {}
                <Route path='profile' element={
                    currentUser.userType == "Admin" ?
                        <AdminProfile /> :
                        currentUser.userType == "Doctor" ?
                            <DoctorProfile /> :
                            currentUser.userType == "Patient" ?
                                <PatientProfile /> :
                                currentUser.userType == "Corporate" ?
                                    <CorporateProfile /> :
                                    currentUser.userType == "Customer" ?
                                        <CustomerProfile /> :
                                        <div />
                }
                />

                {}
                {}

            </Route>

            {}
            {}
            {
}

            {}
            <Route path='/*' element={<NotFound />} />

        </Routes>
    )
}