// ThemeContext.js
import React, { createContext, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export const ThemeContext = createContext();

export const ThemeProviderWrapper = ({ children }) => {
  const [themeSettings, setThemeSettings] = useState({
    primaryColor: "#603f8b",
    secondaryColor: "#8aa4ff",
    tertiaryColor: "#170B3B",
    fontColor: "#170B3B",
  });

  const theme = createTheme({
    palette: {
      primary: { main: themeSettings.primaryColor },
      secondary: { main: themeSettings.secondaryColor },
      text: { primary: themeSettings.fontColor },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  return (
    <ThemeContext.Provider value={{ themeSettings, setThemeSettings }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
