import React, { useState } from "react";
import {
  Box,
  Modal,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../Button/CustomButton"; // Make sure this button component exists
import { toast } from "react-toastify";

const NewsletterModal = ({ open, handleClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    marketingOptIn: false,
    offersOptIn: false,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Compose the email payload for your SMTP endpoint.
    const emailPayload = {
      to: 'anastasia@shuffleuppoker.com',
      subject: "Newsletter Signup",
      body: `
Name: ${formData.firstName} ${formData.lastName}
Email: ${formData.email}
Phone: ${formData.phoneNumber}
Marketing Opt-In: ${formData.marketingOptIn ? "Yes" : "No"}
Offers Opt-In: ${formData.offersOptIn ? "Yes" : "No"}
      `,
    };

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || "https://shuffleuppoker.com/";

    try {
      const response = await fetch(`${apiBaseUrl}api/api/smtp/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailPayload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(
          `Error sending email: ${errorData.message || "Unknown error"}`
        );
        // Keep modal open on error.
      } else {
        toast.success("Signup email sent successfully!");
        handleClose(); // Close modal on success.
      }
    } catch (error) {
      toast.error("Failed to send email.");
      // Modal remains open.
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="newsletter-modal-title">
      <Box
        sx={{
          position: "absolute",
          width: isMobile ? "100%" : "736px",
          height: isMobile ? "100%" : "630px",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          background: "#0E0B0E",
          boxShadow: "0px 4px 45px rgba(174, 61, 239, 0.45)",
          borderRadius: "4px",
          padding: isMobile ? "38px" : "72px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "39px",
          color: "#fff",
          border: "1px solid",
          borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
          position: "relative", // Ensure relative positioning for loader overlay
        }}
      >
        {/* Close Button */}
        <IconButton
          sx={{ position: "absolute", top: "16px", right: "16px", color: "#fff" }}
          onClick={handleClose}
          disabled={isLoading}
        >
          <CloseIcon />
        </IconButton>

        {/* Title */}
        <Typography variant="h4" fontWeight="bold" id="newsletter-modal-title">
          Sign Up for Our Newsletter
        </Typography>

        {/* Form */}
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ background: "transparent", width: "100%", position: "relative" }}
        >
          <Grid container spacing={3}>
            {/* Name Group */}
            <Grid item xs={12} sm={3}>
              <Typography variant="h6">Name</Typography>
            </Grid>
            <Grid item xs={12} sm={9} sx={{ display: "flex", gap: "1rem" }}>
              <TextField
                fullWidth
                name="firstName"
                label="First Name"
                variant="outlined"
                value={formData.firstName}
                onChange={handleChange}
                sx={inputStyles}
                disabled={isLoading}
              />
              <TextField
                fullWidth
                name="lastName"
                label="Last Name"
                variant="outlined"
                value={formData.lastName}
                onChange={handleChange}
                sx={inputStyles}
                disabled={isLoading}
              />
            </Grid>

            {/* Email Group */}
            <Grid item xs={12} sm={3}>
              <Typography variant="h6">Email</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                name="email"
                label="Email"
                variant="outlined"
                value={formData.email}
                onChange={handleChange}
                sx={inputStyles}
                disabled={isLoading}
              />
            </Grid>

            {/* Phone Number Group */}
            <Grid item xs={12} sm={3}>
              <Typography variant="h6">Phone</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                name="phoneNumber"
                label="Phone Number"
                variant="outlined"
                value={formData.phoneNumber}
                onChange={handleChange}
                sx={inputStyles}
                disabled={isLoading}
              />
            </Grid>

            {/* Checkboxes */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="marketingOptIn"
                    checked={formData.marketingOptIn}
                    onChange={handleChange}
                    sx={{ color: "#fff" }}
                    disabled={isLoading}
                  />
                }
                label="I agree to receive marketing emails"
                sx={{ color: "#fff" }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="offersOptIn"
                    checked={formData.offersOptIn}
                    onChange={handleChange}
                    sx={{ color: "#fff" }}
                    disabled={isLoading}
                  />
                }
                label="I agree to receive additional offers"
                sx={{ color: "#fff" }}
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <CustomButton type="submit" variant="contained" color="primary" disabled={isLoading}>
                Sign Up
              </CustomButton>
            </Grid>
          </Grid>

          {/* Loader Overlay */}
          {isLoading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 10,
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

// Common styles for input fields
const inputStyles = {
  borderRadius: "4px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "#ffffff" },
    "&:hover fieldset": { borderColor: "#AE3DEF" },
    "&.Mui-focused fieldset": { borderColor: "#AE3DEF" },
  },
  "& .MuiInputLabel-root": {
    color: "#ffffff",
    "&.Mui-focused": { color: "#AE3DEF" },
  },
};

export default NewsletterModal;
