import React from "react";
import NewsPageSingleComponent from "../../components/Frontend/Sections/NewsSingleComponent";
import Navbar from "../../components/Frontend/Header/Navbar";
import ThinBanner from "../../components/Frontend/Banner/ThinBanner";
import Footer from "../../components/Frontend/Footer/PrimaryFooter";
import IconSection from "../../components/Frontend/Sections/IconSection";
import { useParams } from "react-router-dom";
import NewPlaceholder from "../../assets/img/frontend/SeriesScheduleSplendido.jpeg";
import bannerPlaceholder from "../../assets/img/frontend/placeholder_blog_banner.png";
import CaledonBanner from "../../assets/img/frontend/CaledonBanner.png";
import SplendidoBanner from "../../assets/img/frontend/SplendidoBanner.png";
import CoolPlay1 from "../../assets/img/frontend/CoolPlay1.png";
import CoolPlay2 from "../../assets/img/frontend/CoolPlay2.png";
import Jedd from "../../assets/img/frontend/Jedd.png";
import Sugen from "../../assets/img/frontend/Sugen.png";
import Wes from "../../assets/img/frontend/Wesley.png";
import Ricky from "../../assets/img/frontend/Ricky.png";
import Gareth from "../../assets/img/frontend/GarethDuncan.png";
import { useMediaQuery, useTheme } from '@mui/material';

const posts = [{
    featuredImage: `${CaledonBanner}`,
    slug: "shuffle-up-caledon-series",
    title: "Shuffle Up Poker’s Caledon Poker Series: A Week of Excitement,Skill, and Camaraderie",
    author: "Adam Scher",
    date: "January 31, 2025",
    excerpt: "The 2025 Caledon Poker Series, hosted by Shuffle Up Poker, took place at the stunning Caledon Hotel and Casino from Wednesday, January 29th to Sunday, February 2nd. The event brought together poker enthusiasts from all corners of the country for an action-packed week filled with high stakes, thrilling tournaments, and plenty of off-the-table relaxation.",
    content: `<h2><b>A Week of High-Stakes Poker Action</b></h2><br/>

<p>The tournament series kicked off with four highly successful events, showcasing incredible skill and
determination from all participants. Here are the champions and their well-deserved prizes:</p>

<ol>
<li>High Roller Tournament: Muaaz Gani emerged victorious, taking home an
impressive R458,000 for his win in this prestigious event.</li>
<li>10k One Day Event: Gavin Sardini claimed the title and the R120,000 prize after an
intense day of competition.</li>
<li>Main Event: Sugen Singh triumphed in the Main Event, walking away with R260,000 for
his efforts.</li>
<li>Turbo Tournament: In the final event of the series, Wes Blom secured a solid win,
earning R92,000.</li>
</ol><br/>

<h3>Beyond the Tables: A Perfect Blend of Relaxation and Fun</h3><br/>
<p>While the poker action was the main draw, players were treated to more than just competitive
gaming. The event featured a cocktail evening by the pool, giving participants the chance to unwind
and socialize in a relaxed setting. A delicious braai lunch was served, offering a true taste of South
African hospitality and even a day trip to Hermanus for some of the High Rollers! For those seeking
ultimate relaxation, the Caledon Spa and hot springs provided a serene backdrop to recharge after
the intense days of poker.</p>

<h3>Comradery and Sportsmanship</h3>
<p>What truly stood out throughout the week was the incredible sense of camaraderie between the
players. Whether it was friendly banter during breaks or support and encouragement at the tables,
the spirit of good sportsmanship was evident, making for a memorable experience all around.</p>

<h3>A Week Well Spent</h3>
<p>The 2025 Caledon Poker Series was more than just a series of tournaments—it was a celebration of
poker, relaxation, and community. It was a week well spent by players who came from near and far,
and it’s safe to say everyone left with unforgettable memories and a desire to return next year.</p>

<h3>Ready for More High-Stakes Action?</h3>
<p>We delivere unforgettable moments, thrilling competition, and incredible wins—but this is just the beginning! 
Whether you're chasing the next big pot or looking to be part of the action, make sure you don’t miss out on future events.</p>
`,
  galleryImage: `${CoolPlay1}`
  },
  {
    featuredImage: `${bannerPlaceholder}`,
    slug: "shuffle-up-presents-brand-refresh",
    title: "Shuffle Up Poker Unveils Full Brand Refresh: A New Era of Poker Excellence",
    author: "Adam Scher",
    date: "February 28, 2025",
    excerpt: "Shuffle Up Poker is excited to announce the launch of our brand-new look and feel! After months of careful planning and collaboration, we’re proud to unveil our full brand refresh for 2025",
    content: `<p>Shuffle Up Poker is excited to announce the launch of our brand-new look and feel! After months of careful planning and collaboration, we’re proud to unveil our full brand refresh for 2025. This is more than just a change in design; it represents a fresh chapter in our journey to redefine the poker experience across Africa and beyond.</p>
<h2>Why the Brand Refresh?</h2>
<p>As a forward-thinking poker brand, we are always striving to evolve and stay ahead of the game. Our decision to refresh our brand stems from our commitment to building a more engaging, dynamic, and inclusive poker community. Whether you’re an avid player or a first-time fan, we want every interaction with Shuffle Up Poker to be exciting, immersive, and memorable.</p>
<p>We’ve taken a deep dive into our core values, the needs of our players, and the growing poker landscape to craft a new identity that better reflects who we are today—and where we’re headed tomorrow.
</p><br />
<h2>What’s New?</h2><br/>
<b>Logo & Visual Identity</b><br/>
<p>At the heart of our brand refresh is a bold, modernized logo that embodies our passion for poker and the excitement it brings to our community. The new design features a sleeker, more dynamic look, symbolizing the constant movement and growth of poker in Africa. Along with the logo, we’ve updated our color palette, typography, and overall visual style to ensure consistency across all our platforms and events.</p>
<b>Website Overhaul</b><br/>
<p>Our online presence is more important than ever, which is why we’ve given ShuffleUpPoker.com a complete redesign. The new website is not only visually stunning but also more user-friendly, with enhanced navigation and features. Whether you’re looking for tournament schedules, ambassador updates, or poker strategy tips, everything you need is now just a click away. We’ve made it easier for players to access events, register for tournaments, and stay up to date with the latest news in the poker world.</p>
<b>Brand Voice & Messaging</b><br/>
<p>Along with the visual changes, our brand voice has also evolved. We’re committed to bringing our message of inclusivity, integrity, and excitement to every interaction. Whether you’re reading an article, watching a live tournament, or engaging with us on social media, you’ll notice a fresh, approachable, and fun tone that resonates with both seasoned pros and new poker enthusiasts.</p></b>
<b>Poker Events and Experiences</b>
<p>As part of our brand refresh, we’re enhancing the overall player experience at our tournaments and events. From better-organized events to more engaging fan interactions, Shuffle Up Poker is focused on creating unforgettable moments for our players and audiences. You can expect bigger and more exciting events throughout South Africa and the greater African continent, with exclusive content and new opportunities to connect with the poker community.</p>
<h3>What Does This Mean for the Future?</h3>
<p>This brand refresh isn’t just about aesthetics—it’s about positioning Shuffle Up Poker for even greater success in the coming years. We are committed to becoming the leading poker brand in Africa, and this new direction will help us build stronger relationships with players, fans, and sponsors alike.</p>
<p>Our ambassadors, tournaments, and community initiatives will all reflect the updated brand identity, creating a more unified and consistent experience for everyone involved. Whether you’re playing in our events or cheering from the sidelines, we want every interaction with Shuffle Up Poker to leave you feeling inspired and excited for the next big moment.</p><br/>
<h3>Join Us in This New Chapter</h3><br/>
<p>2025 marks an exciting time for Shuffle Up Poker. We’re kicking off the year with a refreshed look, a renewed sense of purpose, and a stronger commitment to the poker community. We invite you to join us as we continue to grow, evolve, and make history together.</p>
<p>Stay tuned for more updates, new events, and exclusive offers coming your way. The future of Shuffle Up Poker has never looked brighter!</p>
`,
  galleryImage: `${CoolPlay2}`
  },
  {
    featuredImage: `${bannerPlaceholder}`,
    slug: "shuffle-up-poker-announces-2025-brand-ambassadors",
    title: "Shuffle Up Poker Announces 2025 Brand Ambassadors: A New Era of Poker in Africa",
    author: "Adam Scher",
    date: "February 28, 2025",
    excerpt: "We’re thrilled to announce the new faces of Shuffle Up Poker for 2025! This year, we’ve brought on four extraordinary individuals as our official brand ambassadors. These talented players and poker advocates will be representing Shuffle Up Poker across South Africa and the greater African continent, bringing excitement, passion, and skill to poker tables everywhere.",
    content: [
      {
        text: "<p>We’re thrilled to announce the new faces of Shuffle Up Poker for 2025! This year, we’ve brought on four extraordinary individuals as our official brand ambassadors. These talented players and poker advocates will be representing Shuffle Up Poker across South Africa and the greater African continent, bringing excitement, passion, and skill to poker tables everywhere.</p>",
        displayImage: false
      },
      {
        text: "<h2>Meet the Ambassadors</h2>",
        displayImage: false
      },
      {
        text: "<p><strong>Sugen Singh:</strong> Sugen is not just a poker player, but a powerhouse in the African poker community. Representing Madagascar, Mauritius, and South Africa, his journey to becoming one of Africa’s top poker pros has been nothing short of inspiring. As the Player’s Rep for this diverse region, he is committed to growing the game, mentoring emerging players, and creating a thriving poker culture.</p>",
        image: `${Sugen}`,
        displayImage: true
      },
      {
        text: "<p><strong>Wes Blom:</strong><br> Leading the charge in Tanzania and Johannesburg, Wes is known for his strong analytical approach. As a dedicated ambassador, he aims to elevate the African poker scene by showcasing the growing talent in his regions.</p>",
        image: `${Wes}`,
        displayImage: true
      },
      {
        text: "<p><strong>Ricky Toker:</strong><br> Representing the new generation, Ricky brings a fresh perspective to the game. His ability to connect with younger players will help cultivate a new wave of poker stars.</p>",
        image: `${Ricky}`,
        displayImage: true
      },
      {
        text: "<p><strong>Jedd Kossew:</strong><br> As the dedicated Media Rep, Jedd brings his storytelling expertise to ensure that poker tournaments and events are covered far and wide, keeping fans informed with exclusive content and behind-the-scenes footage.</p>",
        image: `${Jedd}`,
        displayImage: true
      },
      {
        text: "<p><strong>Gareth Duncan</strong><br> Gareth Duncan is a prominent figure in the poker industry, serving as a brand ambassador for Shuffle Up, where he focuses on player development in Cape Town. With a passion for the game and a deep commitment to fostering talent, Gareth plays a key role in mentoring and guiding aspiring players in the region. His expertise and dedication to the growth of poker in Cape Town have made him a respected voice in the local poker community.</p>",
        image: `${Gareth}`,
        displayImage: true
      },
      {
        text: "<p><strong>What to Expect in 2025:</strong><br> Our ambassadors are more than just players—they are leaders, mentors, and advocates for the poker community. In 2025, they will be traveling across South Africa and Africa, playing in high-stakes tournaments, engaging with fans, and mentoring aspiring players.</p>",
        displayImage: false
      },
      {
        text: "<p><strong>What This Means for Shuffle Up Poker:</strong><br> This announcement marks the beginning of a thrilling new chapter. With our respected ambassadors representing key regions, we are building a stronger community and setting the stage for a revolutionary year in poker.</p>",
        displayImage: false
      }
    ],
    galleryImage: ""
  },  
  {
    featuredImage: `${SplendidoBanner}`,
    slug: "get-ready-for-splendido",
    title: "Get Ready for the Splendido Poker Series 12 at Monte Casino!",
    author: "Adam Scher",
    date: "January 31, 2025",
    excerpt: "Johannesburg, South Africa – The highly anticipated Splendido Poker Series 12 is set to return to Monte Casino in Johannesburg from March 19th to 30th, 2025, bringing the best of poker to one of South Africa's premier entertainment destinations.",
    content: `
    <p>This exciting poker event will feature a range of buy-in options, with tournaments starting from just R2,500 to a high-stakes R25,000, offering players of all levels a chance to compete for huge prizes and prestige. Whether you're an experienced player or a newcomer, there's an event to match your skill and budget.</p>
    
    <h3>Satellite Qualifiers: Your Ticket to Glory</h3></br>

<p>The road to Splendido 12 starts February 7th, with satellite qualifiers running to give players the opportunity to earn their seat in the main event at a fraction of the cost. These qualifiers will be
hosted online and at Monte Casino, giving poker enthusiasts across the region the chance to join the action.</p>

<h3>Discounted Accommodation Packages</h3>
<p>For out-of-town players or those looking to extend their poker experience, special discounted
accommodation packages are available. Stay in the comfort of Monte Casino’s luxury hotels and
experience everything the venue has to offer – from world-class dining to vibrant nightlife.</p>

<h3>A Series for Everyone</h3>
<p>Whether you're a seasoned pro or a casual player, the Splendido Poker Series 12 offers something for everyone. With a range of events spread over 12 days, players can participate in multiple tournaments, each with its own unique structure and format. The event will also host a variety of side games and cash tables for players who want to keep the action going between tournament sessions. Don’t miss your chance to be part of the excitement and see if you have what it takes to win big!</p>

<h3>Join Us at Monte Casino</h3>
<p>Mark your calendars and prepare for the thrill of Splendido 12. With a reputation for hosting some of the most exciting poker events in the country, Monte Casino is the perfect venue for this action- packed series. For more details, including the full tournament schedule, accommodation offers, and satellite qualifier registration, visit our website or head to Monte Casino’s poker room.</p>

<h4><b>Dates:</b> March 19–30, 2025</h4>
<h4><b>Location:</b> Monte Casino, Johannesburg</h4>
    `,
    galleryImage: `${NewPlaceholder}`
  },
  {
    featuredImage: `${CaledonBanner}`,
    slug: "shuffle-up-caledon-series",
    title: "Shuffle Up Poker’s Caledon Poker Series: A Week of Excitement,Skill, and Camaraderie",
    author: "Adam Scher",
    date: "January 31, 2025",
    excerpt: "The 2025 Caledon Poker Series, hosted by Shuffle Up Poker, took place at the stunning Caledon Hotel and Casino from Wednesday, January 29th to Sunday, February 2nd. The event brought together poker enthusiasts from all corners of the country for an action-packed week filled with high stakes, thrilling tournaments, and plenty of off-the-table relaxation.",
    content: `The 2025 Caledon Poker Series, hosted by Shuffle Up Poker, took place at the stunning Caledon Hotel and Casino from Wednesday, January 29th to Sunday, February 2nd. The event brought together poker enthusiasts from all corners of the country for an action-packed week filled with high stakes, thrilling tournaments, and plenty of off-the-table relaxation.`,
    galleryImage: `${CoolPlay1}`
  },
];


function NewsPageSingle() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // Get the 'slug' parameter from the URL
  const { slug } = useParams();

  // Find the post by slug
  const post = posts.find((p) => p.slug === slug);

  if (!post) {
    return <div>Post not found</div>;
  }

  // Normalize content: if it's not an array, wrap it into one
  const contentSections = Array.isArray(post.content)
    ? post.content
    : [{ text: post.content, displayImage: false }];

  return (
    <>
      <Navbar />
      {/* Pass contentSections to your NewsPageSingleComponent */}
      <NewsPageSingleComponent {...post} contentSections={contentSections} />
      <IconSection />
      <ThinBanner />
      <Footer />
    </>
  );
}

export default NewsPageSingle;