import React from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

const CustomH3 = ({ children, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Typography
      variant="h2"
      {...props}
      sx={{
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        fontStyle: "italic",
        fontWeight: 800,
        marginTop: "0px",
        fontSize: isMobile? "42px":"52.6952px",
        textTransform: "uppercase",
        background: "linear-gradient(90deg, #72ECEA 0%, #AE3DEF 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        flex: "none",
        order: "1",
        alignSelf: "stretch",
        flexGrow: "0",
      }}
    >
      {children}
    </Typography>
  );
};

export default CustomH3;
