import React from "react";
import { Typography, useTheme, useMediaQuery } from "@mui/material";

const CustomText = ({ children, variant = "h1", sx = {}, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Typography
      variant={isMobile ? "h1" : variant}
      {...props}
      sx={{
        position: "relative",
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: isMobile ? "42px" : "64px",
        lineHeight: isMobile ? "40px" : "81px",
        textAlign: "center",
        color: "#FFFFFF",
        textShadow: "0px 2px 45px #AE3DEF",
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export default CustomText;
