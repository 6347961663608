import React, { useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  Collapse,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Close"; // Using CloseIcon as the remove cross

const CartDrawer = ({
  open,
  onClose,
  cartItems,
  onRemoveItem,
  onCheckout,
  onUpdateItem = () => {} // default no-op
}) => {
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address1: "",
    address2: "",
    contactNumber: ""
  });

  // Toggle between showing product list and order form.
  const toggleForm = () => setShowForm((prev) => !prev);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleConfirmOrder = async () => {
    setIsLoading(true);
    try {
      // Assuming onCheckout returns a promise
      await onCheckout({ cartItems, formData });
    } catch (error) {
      // You could show an error toast here if desired.
    } finally {
      setIsLoading(false);
    }
  };

  // Calculate total price. (Assumes price format "R<number>")
  const totalPrice = cartItems.reduce((total, item) => {
    const priceNumber = parseFloat(item.price.replace(/[^0-9.]/g, "")) || 0;
    return total + priceNumber * (item.quantity || 1);
  }, 0);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          boxSizing: "border-box",
          position: "absolute",
          width: "350px",
          height: "100vh",
          right: 0,
          background: "linear-gradient(0deg, #37135E 0%, #0E0B0E 80.52%)",
          borderLeft: "1px solid #AE3DEF"
        }
      }}
    >
      <Box
        sx={{
          p: 2,
          paddingTop: '0px',
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          background: "transparent"
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 0,
            mt: 0,
            background: "transparent"
          }}
        >
          <Typography variant="h6" sx={{ color: "white" }}>
            Your Cart
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>

        {/* Product List */}
        <Collapse in={!showForm} timeout="auto" unmountOnExit>
          {cartItems.length > 0 ? (
            <>
              {cartItems.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    mb: 1,
                    p: 1,
                    borderBottom: "1px solid #72ECEA",
                    background: "transparent",
                    position: "relative"
                  }}
                >
                  {item.soldOut && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "5px",
                        left: "5px",
                        background: "red",
                        color: "white",
                        px: "4px",
                        py: "2px",
                        borderRadius: "4px",
                        fontSize: "0.7rem"
                      }}
                    >
                      SOLD OUT
                    </Box>
                  )}
                  <Grid container spacing={1} sx={{ background: "transparent" }}>
                    {/* Column 1: Image */}
                    <Grid item xs={3} sx={{ background: "transparent" }}>
                      <Box sx={{ background: "transparent" }}>
                        <img
                          src={item.image}
                          alt={item.title}
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                            borderRadius: "4px"
                          }}
                        />
                      </Box>
                    </Grid>
                    {/* Column 2: Details */}
                    <Grid item xs={7} sx={{ background: "transparent" }}>
                      <Box sx={{ background: "transparent" }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "white", fontSize: "0.9rem", mb: 0 }}>
                          {item.title}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'white', fontSize: "0.8rem", mb: 0 }}>
                          Price: R{item.price}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: 'row', alignItems: "center", background: "transparent", marginTop: '-55px' }}>
                          <Box sx={{ display: "flex", flexDirection: 'row', alignItems: "center", background: "transparent" }}>
                            <Typography variant="body2" sx={{ color: 'white', fontSize: "0.8rem", mr: 0 }}>
                              Qty:
                            </Typography>
                            <Select
                              value={item.quantity}
                              onChange={(e) =>
                                onUpdateItem(item.id, { quantity: e.target.value })
                              }
                              sx={{
                                background: "transparent",
                                color: "white",
                                fontSize: "0.8rem",
                                "& .MuiSelect-icon": { color: "white" },
                                minWidth: "40px",
                                p: 0
                              }}
                            >
                              {[1, 2, 3, 4, 5].map((q) => (
                                <MenuItem key={q} value={q} sx={{ fontSize: "0.8rem" }}>
                                  {q}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center", mt: 0, background: "transparent" }}>
                            <Typography variant="body2" sx={{ fontSize: "0.8rem", mr: 0.5 }}>
                              Size:
                            </Typography>
                            <Select
                              value={item.size || "M"}
                              onChange={(e) =>
                                onUpdateItem(item.id, { size: e.target.value })
                              }
                              sx={{
                                background: "transparent",
                                color: "white",
                                fontSize: "0.8rem",
                                "& .MuiSelect-icon": { color: "white" },
                                minWidth: "40px",
                                p: 0
                              }}
                            >
                              {["S", "M", "L", "XL"].map((s) => (
                                <MenuItem key={s} value={s} sx={{ fontSize: "0.8rem" }}>
                                  {s}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    {/* Column 3: Remove Icon */}
                    <Grid item xs={2} sx={{ background: "transparent" }}>
                      <Box sx={{ background: "transparent" }}>
                        <IconButton onClick={() => onRemoveItem(item.id)}>
                          <DeleteIcon sx={{ color: "white" }} />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              {/* Totals Section */}
              <Box sx={{ mt: 1, background: "transparent", borderTop: "1px solid #72ECEA", pt: 1 }}>
                <Typography variant="subtitle1" sx={{ color: "white", fontSize: "1.2rem" }}>
                  Total: R{totalPrice.toFixed(2)}
                </Typography>
              </Box>
            </>
          ) : (
            <Typography variant="body1" sx={{ mt: 2, color: "white", background: "transparent" }}>
              Your order request is empty.
            </Typography>
          )}
        </Collapse>

        {/* Order Form */}
        <Collapse in={showForm} timeout="auto" unmountOnExit>
          <Box sx={{ position: "relative", background: "transparent", mb: 2 }}>
            {/* Form Fields */}
            <Box sx={{ background: "transparent", mb: 2 }}>
              <TextField
                fullWidth
                margin="dense"
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ style: { color: "white" } }}
                sx={{
                  mb: 1,
                  background: "transparent",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "white" },
                    "&:hover fieldset": { borderColor: "#72ECEA" },
                    "&.Mui-focused fieldset": { borderColor: "#72ECEA" }
                  }
                }}
              />
              <TextField
                fullWidth
                margin="dense"
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ style: { color: "white" } }}
                sx={{
                  mb: 1,
                  background: "transparent",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "white" },
                    "&:hover fieldset": { borderColor: "#72ECEA" },
                    "&.Mui-focused fieldset": { borderColor: "#72ECEA" }
                  }
                }}
              />
              <TextField
                fullWidth
                margin="dense"
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ style: { color: "white" } }}
                sx={{
                  mb: 1,
                  background: "transparent",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "white" },
                    "&:hover fieldset": { borderColor: "#72ECEA" },
                    "&.Mui-focused fieldset": { borderColor: "#72ECEA" }
                  }
                }}
              />
              <TextField
                fullWidth
                margin="dense"
                label="Address Line 1"
                name="address1"
                value={formData.address1}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ style: { color: "white" } }}
                sx={{
                  mb: 1,
                  background: "transparent",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "white" },
                    "&:hover fieldset": { borderColor: "#72ECEA" },
                    "&.Mui-focused fieldset": { borderColor: "#72ECEA" }
                  }
                }}
              />
              <TextField
                fullWidth
                margin="dense"
                label="Address Line 2"
                name="address2"
                value={formData.address2}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ style: { color: "white" } }}
                sx={{
                  mb: 1,
                  background: "transparent",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "white" },
                    "&:hover fieldset": { borderColor: "#72ECEA" },
                    "&.Mui-focused fieldset": { borderColor: "#72ECEA" }
                  }
                }}
              />
              <TextField
                fullWidth
                margin="dense"
                label="Contact Number"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleInputChange}
                variant="outlined"
                InputLabelProps={{ style: { color: "white" } }}
                sx={{
                  mb: 1,
                  background: "transparent",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "white" },
                    "&:hover fieldset": { borderColor: "#72ECEA" },
                    "&.Mui-focused fieldset": { borderColor: "#72ECEA" }
                  }
                }}
              />
            </Box>
            {/* Loader Overlay */}
            {isLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 10,
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
          </Box>
        </Collapse>

        <Box sx={{ flexGrow: 1, background: "transparent" }} />

        {/* Checkout / Confirm Order Button */}
        <Box sx={{ display: "flex", justifyContent: "center", mb: 2, background: "transparent" }}>
          <Button
            onClick={() => {
              if (!showForm) {
                toggleForm();
              } else {
                handleConfirmOrder();
              }
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              p: "16px 34px",
              gap: "49px",
              width: "436px",
              fontWeight: "bold",
              height: "54px",
              background: "linear-gradient(88.24deg, #72ECEA 41.73%, #AE3DEF 121.56%)",
              borderRadius: "8px",
              color: "#000",
              textTransform: "none"
            }}
          >
            {showForm ? "Confirm Order Details" : "Send Order Request"}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CartDrawer;
