import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Container, useTheme, useMediaQuery } from "@mui/material";
import PokerBanner from "../../../assets/img/frontend/Splendido12.png";
import CustomText from "../Tyopgraphy/CustomText";
import CustomButton from "../Button/CustomButton";
import TealButton from "../Button/TealButton";
import RegistrationModal from "../Modals/RegistrationModal";
import { Link } from "react-router-dom";

const HeroBannerCountdown = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);
  // Set target date (adjust as needed)
  const targetDate = new Date("2025-03-19T23:59:59").getTime();

  const [timeLeft, setTimeLeft] = useState(getTimeRemaining(targetDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeRemaining(targetDate));
    }, 1000);
    return () => clearInterval(timer);
  }, [targetDate]);

  function getTimeRemaining(target) {
    const now = new Date().getTime();
    const difference = target - now;

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return (
    <Box sx={{ marginTop: "0px", position: "relative", textAlign: "center", color: "white", paddingTop: "0px"}}>
      {/* Background Image */}
      <Box
        component="img"
        src={PokerBanner}
        alt="Poker Banner"
        sx={{
          width: "100%",
          height: "87vh", // Adjust as needed
          objectFit: "cover",
          paddingTop: "0px",
          display: "block",
        }}
      />

      {/* Gradient Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%)",
        }}
      />

      {/* Content (Positioned over the darkest part of the gradient) */}
      <Box
        sx={{
          position: "absolute",
          bottom: "10%", // Moves text up into the dark gradient
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          maxWidth: "800px",
          textAlign: "center",
          background: "transparent"
        }}
      >
        {/* Hero Title */}
        <Typography variant="h5">Splendido!</Typography>
        <CustomText variant="h4" fontWeight="bold" gutterBottom>
          Splendido Poker Series 12
        </CustomText>
        <Typography variant="h6" sx={{ mb: 2 }}>Don't miss out on your opportunity at Poker Glory!</Typography>

        {/* Countdown Timer */}
        <Box
      sx={{
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row", // Keeps time sections in a row
        alignItems: "center", // Vertically centers everything
        justifyContent: "center", // Centers items inside the box
        padding: "16px 133px",
        marginLeft: "auto",
        marginRight: "auto",
        gap: "32px", // Creates space between time sections
        width: isMobile? "350px":"634px",
        height: "88px",
        border: "1px solid",
        borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
        background: "linear-gradient(180deg, #21174F 16.75%, #0E0B0E 140.05%)",
        borderRadius: "10px",
        flex: "none",
        order: "2",
        alignSelf: "stretch",
        flexGrow: "0",
      }}
    >
      {["Days", "Hours", "Minutes", "Seconds"].map((label, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column", // Ensures time is above label
            alignItems: "center", // Centers text and label
            justifyContent: "center", // Centers everything vertically
            textAlign: "center",
            paddingBottom: "1rem",
            background: "transparent"
          }}
        >
          {/* Time Value */}
          <Typography
            variant="h4"
            sx={{
              width: isMobile? "39px": "78px",
              height: "55px",
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: isMobile? "21px": "32px",
              lineHeight: "40px",
              textAlign: "center",
              color: "#FFFFFF",
              flex: "none",
              order: "6",
              flexGrow: "0",
            }}
          >
            {timeLeft[label.toLowerCase()]}
          </Typography>

          {/* Label Below Time */}
          <Typography
            variant="body2"
            sx={{
              fontFamily: "'Plus Jakarta Sans', sans-serif",
              fontSize: isMobile? "14px": "18px",
              fontWeight: 500,
              color: "#FFFFFF",
              textTransform: "uppercase", // Keeps label consistent
            }}
          >
            {label}
          </Typography>
        </Box>
      ))}
    </Box>
        {/* Buttons */}
        <Box sx={{ display: "flex", flexDirection: isMobile? "column":"row", gap: 2, justifyContent: "center", marginTop: "2rem", marginLeft: isMobile? "1rem":"auto", marginRight: isMobile? "1rem":"auto", background: "transparent"}}>
          <CustomButton onClick={handleOpenModal}>
            Enquire Now
          </CustomButton>
          <TealButton style={{ width: isMobile? "100%":"177px"}} as={Link} to={'/events/splendido-12-poker-series'}>
            Learn More
          </TealButton>
        </Box>
      </Box>
      <RegistrationModal open={modalOpen} handleClose={handleCloseModal} />
    </Box>
  );
};

export default HeroBannerCountdown;
