import React from "react";
import { Box, Typography, Container, useMediaQuery, useTheme } from "@mui/material";

const NewsPageSingleComponent = ({
  featuredImage,
  title,
  author,
  date,
  content,
  excerpt,
  galleryImage,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ background: "#121212", color: "#fff" }}>
      {/* Full-width Featured Image */}
      <Box
        sx={{
          width: "100%",
          height: "60vh",
          backgroundImage: `url(${featuredImage})`,
          backgroundSize: "cover",
          backgroundColor: "#000",
          marginLeft: 0,
          marginRight: 0,
          marginTop: "-25px",
          paddingTop: 0,
          objectFit: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      {/* Content Section */}
      <Container maxWidth="md" sx={{ padding: "4rem 0" }}>
        {/* Title */}
        <Typography
          variant="h1"
          fontWeight="bold"
          sx={{
            width: "100%",
            maxWidth: isMobile? "100%":"1032px",
            fontSize: isMobile? "42px":"64px",
            lineHeight: isMobile? "48px":"81px",
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontStyle: "normal",
            fontWeight: 700,
            textAlign: "left",
            margin: isMobile? "1rem 1rem 1rem 1rem":"0 auto 2rem",
            background: "linear-gradient(90deg, #72ECEA 18.06%, #AE3DEF 81.65%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
          }}
        >
          {title}
        </Typography>

        {/* Post Meta */}
        <Typography variant="subtitle2" sx={{ opacity: 0.8, marginBottom: "1rem", textAlign: "left", margin: isMobile?"0 1rem 0 1rem":"auto" }}>
          By <strong>{author}</strong> | {date}
        </Typography>

        {/* Excerpt */}
        <Typography variant="body1" sx={{ lineHeight: "1.8", opacity: 0.9, textAlign: "justify", marginBottom: "1rem", margin: isMobile?"0 1rem 0 1rem":"auto" }}>
          {excerpt}
        </Typography>

        {/* Optional Gallery Image */}
        {galleryImage && galleryImage !== "" && (
          <Box
            component="img"
            src={galleryImage}
            alt={title}
            sx={{
              background: "transparent",
              width: isMobile? "99%":"100%",
              height: "auto",
              marginBottom: "1rem",
              justifyContent: isMobile?"center":"center",
              alignContent: isMobile?"center":"center"
            }}
          />
        )}

        {/* Blog Content */}
        {Array.isArray(content) ? (
          content.map((section, index) => (
            <Box key={index} sx={{ background: "transparent", display: "flex", flexDirection: isMobile? "column":"auto", marginBottom: "1rem", alignItems: "flex-start", margin: isMobile?"0 1rem 0 1rem":"auto" }}>
              {section.displayImage && section.image && (
                <Box
                  component="img"
                  src={section.image}
                  alt={`Section ${index}`}
                  sx={{ background: "transparent", marginRight: "1rem", width: isMobile?'100%':"250px", height: isMobile?"100%":"auto" }}
                />
              )}
              <Box
                dangerouslySetInnerHTML={{ __html: section.text }}
                sx={{ background: "transparent", lineHeight: "1.8", opacity: 0.9, textAlign: isMobile?"left":"justify", margin: isMobile?"0 1rem 0 1rem":"auto" }}
              />
            </Box>
          ))
        ) : (
          <Typography variant="body1" sx={{ background: "transparent", lineHeight: "1.8", opacity: 0.9, textAlign: isMobile?"left":"justify", margin: isMobile?"0 1rem 0 1rem":"auto" }}>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Typography>
        )}
      </Container>
    </Box>
  );
};

export default NewsPageSingleComponent;
