import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Container,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import Masonry from "react-masonry-css";
import GradientH1 from "../Tyopgraphy/GradientH1";

const GallerySection = ({ images }) => {
  const [view, setView] = useState("masonry");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");

  const masonryBreakpoints = {
    default: 3,
    1100: 2,
    768: 1,
  };

  let filteredData = images.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (sortOrder === "asc") {
    filteredData = filteredData.sort();
  } else if (sortOrder === "desc") {
    filteredData = filteredData.reverse();
  }

  return (
    <Box sx={{ background: "#000", padding: "4rem 0", color: "#fff", textAlign: "center" }}>
      <Container maxWidth="lg">
        <GradientH1 gutterBottom>Photo Gallery</GradientH1>
        <Typography variant="body1" gutterBottom>
          See all our highlights taken from this event.
        </Typography>

        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "1rem",
            background: "transparent",
            marginBottom: "2rem",
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: "25%",
              background: "linear-gradient(180deg, #21174F 16.75%, #0E0B0E 140.05%)",
              borderRadius: "4px",
              color: "#fff",
              border: "1px solid",
              borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
            }}
            InputProps={{ style: { color: "#fff" } }}
          />

          <FormControl sx={{ minWidth: 150 }}>
            <InputLabel sx={{ color: "#fff" }}>Sort By</InputLabel>
            <Select
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
              sx={{ color: "#fff" }}
            >
              <MenuItem value="asc">Ascending</MenuItem>
              <MenuItem value="desc">Descending</MenuItem>
            </Select>
          </FormControl>

          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={(e, newView) => newView && setView(newView)}
            sx={{ background: "linear-gradient(180deg, #21174F 16.75%, #0E0B0E 140.05%)", borderRadius: "4px", border: "1px solid", borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1" }}
          >
            <ToggleButton value="masonry" sx={{ color: "#fff" }}>Masonry</ToggleButton>
            <ToggleButton value="grid" sx={{ color: "#fff" }}>Grid</ToggleButton>
            <ToggleButton value="list" sx={{ color: "#fff" }}>List</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {filteredData.length === 0 ? (
          <Typography variant="body1">No gallery to show - some great event photos coming soon</Typography>
        ) : (
          <>
            {view === "masonry" && (
              <Masonry breakpointCols={masonryBreakpoints} className="masonry-grid" columnClassName="masonry-column">
                {filteredData.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      background: "transparent",
                      marginBottom: "1rem",
                      borderRadius: "8px",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <img
                      src={item}
                      alt={`gallery-item-${index}`}
                      style={{ width: "100%", display: "block", borderRadius: "8px" }}
                    />
                  </Box>
                ))}
              </Masonry>
            )}

            {view === "grid" && (
              <Grid container spacing={2}>
                {filteredData.map((item, index) => (
                  <Grid item xs={6} md={4} key={index}>
                    <Box sx={{ position: "relative", overflow: "hidden", background: "transparent", borderRadius: "8px" }}>
                      <img
                        src={item}
                        alt={`gallery-item-${index}`}
                        style={{ width: "100%", height: "200px", objectFit: "cover", borderRadius: "8px" }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}

            {view === "list" && (
              <Box>
                {filteredData.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      background: "#121212",
                      borderRadius: "8px",
                      overflow: "hidden",
                      marginBottom: "1rem",
                    }}
                  >
                    <Box sx={{ flex: "0 0 150px", background: "transparent" }}>
                      <img
                        src={item}
                        alt={`gallery-item-${index}`}
                        style={{
                          width: "100%",
                          height: "200px",
                          objectFit: "cover",
                          borderRadius: "8px",
                        }}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}
      </Container>
    </Box>
  );
};

export default GallerySection;