import React from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import Adam from "../../../assets/img/frontend/Adam.png";
import Demi from "../../../assets/img/frontend/Demi.png";
import Toni from "../../../assets/img/frontend/Toni.png";
import Anastasia from "../../../assets/img/frontend/Anastasia.png";

const contacts = [
  {
    image: Toni,
    name: "Toni Howden",
    phone: "066 300 7263",
    email: "toni@shuffleuppoker.com",
  },
  {
    image: Demi,
    name: "Demi Poulton",
    phone: "067 029 8025",
    email: "demi@shuffleuppoker.com",
  },
  {
    image: Anastasia,
    name: "Anastasia Darlow",
    phone: "064 984 4572",
    email: "anastasia@shuffleuppoker.com",
  },
  {
    image: Adam,
    name: "Adam Scher",
    phone: "079 026 7527",
    email: "adam@shuffleuppoker.com",
  },
];

const ContactGrid = () => {
  return (
    <Box
      sx={{
        background: "#000000",
        padding: "3rem 0",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={1.5} // Reduced spacing between rows and columns
          justifyContent="center"
          sx={{ 
            maxWidth: "800px", 
            margin: "0 auto"
        }}
        >
          {contacts.map((contact, index) => (
            <Grid item xs={12} sm={6} md={6} key={index} sx={{ padding: "5px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  textAlign: "left",
                  padding: "0.8rem",
                  background: "transparent"
                }}
              >
                {/* Portrait Image - Ensuring Equal Size */}
                <Box
                  component="img"
                  src={contact.image}
                  alt={contact.name}
                  sx={{
                    width: "100%", // Fixed width for consistency
                    height: "400px", 
                    objectFit: "cover", // Ensures aspect ratio consistency
                    borderRadius: "10px",
                    marginBottom: "0.5rem",
                    background: "transparent"
                  }}
                />

                {/* Name Title */}
                <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: "0rem" }}>
                  {contact.name}
                </Typography>

                {/* Phone Number with Icon */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0rem",
                    background: "transparent",
                    paddingBottom: "0px"
                  }}
                >
                  <PhoneIcon sx={{ marginRight: "6px", fontSize: "18px", color: "#AE3DEF", marginBottom: "0rem" }} />
                  <Typography variant="body1" sx={{ fontSize: "15px", marginBottom: "0px", paddingBottom: "0px" }}>
                    {contact.phone}
                  </Typography>
                </Box>

                {/* Email with Icon */}
                <Box sx={{ display: "flex", alignItems: "center", background: "transparent", paddingBottom: "0px" }}>
                  <EmailIcon sx={{ marginRight: "6px", fontSize: "18px", color: "#AE3DEF" }} />
                  <Typography variant="body1" sx={{ fontSize: "15px" }}>
                    {contact.email}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactGrid;
