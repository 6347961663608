import React, { useState } from "react";
import { Box, Typography, Button, useMediaQuery, useTheme } from "@mui/material";
import NewsletterModal from "../Modals/NewsletterModal";

const ThinBanner = () => {
const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box
      sx={{
        height: isMobile? "125px":"75px",
        background: "linear-gradient(90deg, #72ECEA 0%, #AE3DEF 100%)",
        color: "white",
        display: "flex",
        flexDirection: isMobile? "column":"row",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 2rem",
      }}
    >
      {/* Text */}
      <Typography variant="body1" sx={{ marginRight: "1rem" }}>
        Sign up to keep up to date with the latest News and Events
      </Typography>

      {/* Button */}
      <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>
        Sign Up
      </Button>
      <NewsletterModal open={modalOpen} handleClose={() => setModalOpen(false)} />
    </Box>
  );
};

export default ThinBanner;
