import React from "react";
import { Typography } from "@mui/material";

const CustomText2 = ({ children, variant = "h3", sx = {}, ...props }) => {
  return (
    <Typography
      variant={variant}
      {...props}
      sx={{
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "38px",
        color: "#FFFFFF",
        textShadow: "0px 2px 45px #AE3DEF",
        ...sx, // Allows additional styles to be passed
      }}
    >
      {children}
    </Typography>
  );
};

export default CustomText2;
