import React from "react";
import { Button, useMediaQuery, useTheme } from "@mui/material";

const CustomButton = ({ children, onClick, sx, ...props}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Button
      onClick={onClick}
      {...props}
      sx={{
        ...sx,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "14px 24px",
        gap: "32px",
        width: isMobile?"100%":"177px",
        height: "48px",
        background: "linear-gradient(180deg, #21174F 16.75%, #0E0B0E 140.05%)",
        borderRadius: "4px",
        flex: "none",
        order: "1",
        flexGrow: "0",
        border: "1px solid",
        borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
        color: "white", // Ensures text visibility
        textTransform: "none", // Removes default uppercase styling
        fontWeight: "bold",
        fontSize: "14px",
        "&:hover": {
          background: "linear-gradient(180deg, #2A1C5F 16.75%, #1A121A 140.05%)",
          borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
        },
      }}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
