
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import ToastService from '../../Service/toast.service';

function AddUser() {
  const navigate = useNavigate();

  /**
   * User use states
   * @since 0.1
   */
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userType, setUserType] = useState('');
  const [linkedCorporate, setLinkedCorporate] = useState('');
  const [linkedTeam, setLinkedTeam] = useState('');
  const [corporates, setCorporates] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loadingCorporates, setLoadingCorporates] = useState(true);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState('none');
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  /**
   * Handle error dialog open
   * @since 0.1
   */
  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true);
  };

  /**
   * Handle error dialog close
   * @since 0.1
   */
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  /**
   * useEffect to fetch corporate and team info for user assignment
   * @since 0.1
   */
  useEffect(() => {
    const fetchCorporatesAndTeams = async () => {
      const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
      try {
        const [corporatesRes, teamsRes] = await Promise.all([
          fetch(`${baseApi}/corporates`, {
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }),
          fetch(`${baseApi}/teams`, {
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
        ]);

        if (!corporatesRes.ok || !teamsRes.ok) {
          throw new Error('Failed to fetch corporates or teams');
        }

        const corporatesData = await corporatesRes.json();
        const teamsData = await teamsRes.json();

        console.log('Corporates:', corporatesData);
        console.log('Teams:', teamsData);

        setCorporates(corporatesData);
        setTeams(teamsData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setFetchError(error.message);
      } finally {
        setLoadingCorporates(false);
        setLoadingTeams(false);
      }
    };

    fetchCorporatesAndTeams();
  }, []);

  /**
   * Action to add user to the system
   * @since 0.1
   * @param {*} event 
   * @returns 
   */
  const addUser = async (event) => {
    event.preventDefault();

    if (!linkedCorporate) {
      setErrorList(['Please select a corporate.']);
      handleDialogueOpen();
      return;
    }

    if (!linkedTeam) {
      setErrorList(['Please select a team.']);
      handleDialogueOpen();
      return;
    }

    const isValidObjectId = (id) => /^[a-fA-F0-9]{24}$/.test(id);

    const validLinkedTeam = isValidObjectId(linkedTeam) ? linkedTeam : null;
    const validLinkedCorporate = isValidObjectId(linkedCorporate) ? linkedCorporate : null;

    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    const user = {
      firstName,
      lastName,
      email,
      username,
      password,
      confirmPassword,
      userType,
      linkedCorporate: validLinkedCorporate,
      linkedTeam: validLinkedTeam,
    };

    console.log("Submitting user data:", user);

    try {
      const response = await fetch(`${baseApi}/users`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(user),
      });

      const data = await response.json();
      console.log("Server response:", data);

      if (response.ok && data.message === "success") {
        ToastService.success("User added successfully.");
        navigate("/account/users");
      } else {
        setErrorList(data.errors || ["Failed to add user."]);
        handleDialogueOpen();
      }
    } catch (error) {
      setErrorList([error.message || "An unexpected error occurred."]);
      handleDialogueOpen();
    }
  };

  /**
   * useEffect for password validation and confirmation
   * @since 0.1
   */
  useEffect(() => {
    if (password.length > 0 && password.trim().length <= 6) {
      setPasswordValidationMessage('Password length must be greater than 6 characters');
    } else {
      setPasswordValidationMessage('');
    }

    if (password === confirmPassword) {
      setPasswordMatchDisplay('none');
    } else {
      setPasswordMatchDisplay('block');
    }
  }, [password, confirmPassword]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            {}
            <div className="row">
              <div className="col-lg-12">
                <h4 className="page-title">Add User</h4>
              </div>
            </div>

            {}
            <div className="row">
              <div className="col-lg-12">
                {/* User form start */}
                <form id="addUserForm" name='addUserForm' onSubmit={addUser}>
                  <div className="row">
                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>First Name <span className="text-danger">*</span></label>
                        <input
                          name="firstName"
                          className="form-control"
                          type="text"
                          required
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Last Name <span className="text-danger">*</span></label>
                        <input
                          name="lastName"
                          className="form-control"
                          type="text"
                          required
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Username <span className="text-danger">*</span></label>
                        <input
                          name="username"
                          className="form-control"
                          type="text"
                          required
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email <span className="text-danger">*</span></label>
                        <input
                          name="email"
                          className="form-control"
                          type="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          name="password"
                          className="form-control"
                          type="password"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {passwordValidationMessage && (
                          <small className="text-danger">{passwordValidationMessage}</small>
                        )}
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                          name="confirmPassword"
                          className="form-control"
                          type="password"
                          required
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div style={{ display: passwordMatchDisplay, color: 'red' }}>
                          Passwords do not match
                        </div>
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Role <span className="text-danger">*</span></label>
                        <select
                          name="userType"
                          className="form-select"
                          value={userType}
                          onChange={(e) => setUserType(e.target.value)}
                          required
                        >
                          <option value="">Select Role</option>
                          <option value="Admin">Admin</option>
                          {/*                           
                          <option value="Doctor">Doctor</option>
                          <option value="Patient">Patient</option> */}

                          <option value="Corporate">Corporate</option>
                          <option value="Employee">Employee</option>
                          <option value="Customer">Customer</option>
                        </select>
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Linked Corporate <span className="text-danger">*</span></label>
                        {loadingCorporates ? (
                          <div>Loading corporates...</div>
                        ) : fetchError ? (
                          <div className="text-danger">Error loading corporates: {fetchError}</div>
                        ) : (
                          <select
                            name="linkedCorporate"
                            className="form-select"
                            value={linkedCorporate}
                            onChange={(e) => setLinkedCorporate(e.target.value)}
                            required
                          >
                            <option value="">Select Corporate</option>
                            {corporates.length > 0 ? (
                              corporates.map(corp => (
                                <option key={corp._id} value={corp._id}>
                                  {corp.registeredName || corp.legalName || 'Unnamed Corporate'}
                                </option>
                              ))
                            ) : (
                              <option value="" disabled>No corporates available</option>
                            )}
                          </select>
                        )}
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Linked Team <span className="text-danger">*</span></label>
                        {loadingTeams ? (
                          <div>Loading teams...</div>
                        ) : fetchError ? (
                          <div className="text-danger">Error loading teams: {fetchError}</div>
                        ) : (
                          <select
                            name="linkedTeam"
                            className="form-select"
                            value={linkedTeam}
                            onChange={(e) => setLinkedTeam(e.target.value)}
                            required
                          >
                            <option value="">Select Team</option>
                            {teams.length > 0 ? (
                              teams.map(t => (
                                <option key={t._id} value={t._id}>
                                  {t.teamName || 'Unnamed Team'}
                                </option>
                              ))
                            ) : (
                              <option value="" disabled>No teams available</option>
                            )}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>

                  {}
                  <div className="m-t-20 text-left">
                    <button
                      id="addUser"
                      type="submit"
                      className="btn btn-primary"
                      disabled={!linkedCorporate || !linkedTeam}
                    >
                      Add User
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Error dialog box */}
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Add User"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default AddUser;
