import React from 'react';
import ContactForm from '../components/Frontend/Forms/ContactForm';
import Navbar from '../components/Frontend/Header/Navbar';
import Footer from '../components/Frontend/Footer/PrimaryFooter';
import ContainerBanner from '../components/Frontend/Banner/ContainerBanner';
import ContactGrid from '../components/Frontend/Sections/ContactGrid';
import FullWidthBackgroundSection from '../components/Frontend/Sections/FullWidthBackgroundSection';
import ThinBanner from '../components/Frontend/Banner/ThinBanner';

function ContactPage(){

    return(
        <>
        <Navbar/>
        <ContainerBanner/>
        <ContactForm/>
        <ContactGrid/>
        <FullWidthBackgroundSection/>
        <ThinBanner/>
        <Footer/>
        </>
    );
}
export default ContactPage;