import React from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material';

const LargeTealButton = ({ children, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Button
      {...props}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '14px 16px',
        gap: '32px',
        width: isMobile? "100%":'190px',
        height: '48px',
        fontWeight: "600 !important",
        background: '#72ECEA',
        fontSize: '16px',
        textTransform: 'capitalize !important',
        borderRadius: '0px',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        // Keep the background the same on hover (or change as desired)
        '&:hover': {
          background: '#72ECEA',
        },
      }}
    >
      {children}
    </Button>
  );
};

export default LargeTealButton;
