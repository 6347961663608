import React, { useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import CustomText from "../Tyopgraphy/CustomText";
import GradientH4 from "../Tyopgraphy/GradientH4";
import GradientH1 from "../Tyopgraphy/GradientH1";
import ButtonRow from "../Button/ButtonRow";
import CenteredImage from "../../../assets/img/frontend/small-s.svg";


const NewsRoomBannerEvents = ({
  accent,
  title,
  subtitle,
  description,
  date,
  series,
  backgroundImage,
  height,
  buttons,
  event
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  return (
    <Box
      sx={{
        marginTop: "-5px",
        marginBottom: "-10px",
        width: "100%",
        height: isMobile ? "70vh" : "80vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: "#fff",
        padding: "1rem",
      }}
    >
      {/* Title */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: isMobile ? "1rem" : "2rem",
          background: "transparent",
        }}
      >
        <img
          src={CenteredImage}
          alt="Centered"
          style={{
            maxWidth: isMobile ? "80%" : "100%",
            height: "auto",
          }}
        />
      </Box>

      <GradientH1 sx={{ marginBottom: "0px", paddingBottom: "0px" }}>{accent}</GradientH1>
      <CustomText variant="h2" fontWeight="bold" sx={{ marginTop: "0px", paddingTop: "0px" }}>
        {event.series}
      </CustomText>

      <ButtonRow buttons={buttons} onEnquireNoClick={handleOpenModal} />

      {/* Subtitle */}
      {event.date && (
        <GradientH4 variant="h6" sx={{ fontSize: "21px !important", marginTop: "0rem", fontWeight: "500" }}>
          {event.date}
        </GradientH4>
      )}

      {/* Description */}
      {description && (
        <Typography variant="body1" sx={{ marginTop: "1rem", maxWidth: "800px" }}>
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default NewsRoomBannerEvents;
