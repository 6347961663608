import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Button, useMediaQuery, useTheme } from "@mui/material";
import PokerBanner from "../../../assets/img/frontend/sup-poker-banner3.png";
import CustomH3 from "../Tyopgraphy/CustomH3";
import Logo from "../../../assets/img/frontend/sup-logo.svg";
import TealButton from "../Button/TealButton";
import CustomButton from "../Button/CustomButton";
import LargeTealButton from "../Button/LargeTealButton";
import CustomH3White from "../Tyopgraphy/CustomH3White";

const LeftTextHeroBanner = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Box
            sx={{
                height: "90vh", // Adjust height as needed
                backgroundImage: `url(${PokerBanner})`,// Change path accordingly
                backgroundSize: isMobile? "cover":"cover",
                backgroundPosition: isMobile? "center center":"left center", // Ensures image stays left-aligned
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start", // Push everything to the left
                color: "white",
                padding: "0 60px", // Adds left padding, no right space
            }}
        >
            <Box sx={{ maxWidth: "600px", background: "transparent", paddingBottom: "60px" }}>
                {/* Logo */}
                <Box sx={{ textAlign: "left", marginBottom: "2rem", background: "transparent" }}>
                    <img src={Logo} alt="Premier Poker Logo" style={{ height: "50px" }} />
                </Box>

                {/* Heading */}
                <CustomH3 variant="h4" fontWeight="bold" gutterBottom sx={{ textAlign: "left" }}>
                    PLAYER OF THE YEAR
                </CustomH3>
                <CustomH3White variant="h4" fontWeight="bold" gutterBottom sx={{ textAlign: "left" }}>
                   LEADERBOARD
                </CustomH3White>

                {/* Description */}
                <Typography variant="h6" sx={{ opacity: 0.8, marginBottom: "1.5rem", textAlign: "left" }}>
                <i>Stay on top of the competition!</i>
                </Typography>

                {/* Buttons */}
                <Box sx={{ display: "flex", flexDirection: isMobile?"column":"row", gap: "1rem", background: "transparent" }}>
                    <LargeTealButton as={Link} to={'/login'}>
                        View Leaderboard
                    </LargeTealButton>
                    <CustomButton as={Link} to={'/signup'} variant="outlined" color="secondary" size="large">
                        Sign Up Now
                    </CustomButton>
                </Box>
            </Box>
        </Box>
    );
};

export default LeftTextHeroBanner;
