import React from "react";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Import carousel and its styles
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Ricky from "../../../assets/img/frontend/Ricky.png";
import Jedd from "../../../assets/img/frontend/Jedd.png";
import Wesley from "../../../assets/img/frontend/Wesley.png";
import Sugen from "../../../assets/img/frontend/Sugen.png";
import Gareth from "../../../assets/img/frontend/GarethDuncan.png";

const pokerPlayers = [
  {
    image: `${Ricky}`,
    name: "Ricky Toker",
    bio: "Artist, Musician, Athlete and Aspiring Poker Pro.",
    pokerMoment:
      "Having the bubble burst and cashing the main event in my first ever live tournament through a satellite.",
    quote:
      "Whenever I get told I’m not a good loser after a bad beat, my response is always 'why would I want to be good at losing?'",
    biggestCash: "R35 780",
  },
  {
    image: `${Jedd}`,
    name: "Jedd Kossew",
    bio: "Jedd Kossew Born 1982 in Cape Town South Africa",
    pokerMoment: "Playing the main event for the first time ",
    quote:
      "No when to Holdem no when to foldem No when to walk away no when to run.",
    biggestCash: "R350 000",
  },
  {
    image: `${Wesley}`,
    name: "Wes Blom",
    bio: "SA's Rising Poker Talent New to the game, but making waves! Since starting in June 2023, I've won local & international tournaments and earned my spot at WSOP 2024. Poker strategist by day, thrill-seeker by night. Let's GO!",
    pokerMoment:
      "Winning my 1st Live event @ Monte which got me to WSOP 2024.\r\nCashing my 1st WSOP event on my Birthday. \r\nMeeting so many interesting people along the way, and learning about the amazing game. ",
    quote: "You can't be afraid to lose if you want to win. - Dnegs",
    biggestCash: "R248 000",
  },
  {
    image: `${Sugen}`,
    name: "Sugen Singh",
    bio: "I started playing poker in 2018 when a friend introduced me to the poker community in JHB. I tried playing small stakes local poker games as a total beginner who had no idea what a button was. I kept at it learning from mistakes and taking advice from other players to better my skills. I gained confidence to now play bigger tournaments as my skills continue to Improve and even managed to play at the world series of poker in 2023 which was my greatest achievement. Since then I have won many tournaments, got 14 Tournament Trophies and cashed consistently in every series I played. I am now well known in the wonderful poker community in South Africa and abroad. My poker Alias is 'The Silver Surfer' as my poker persona is to always be well presented with lots of Bling and bring fun and entertainment to this game we love called Poker. My favourite poker hand is the 2 black Kings, King clubs, King spades, I call them the ' Shaka Zulu'",
    pokerMoment:
      "I say it was fulfilling my life long dream of going to Las Vegas and playing a WSOP tournament to complete against the world's best poker players.  Coming 3rd in an event and adding an international cash to my portfolio was the cherry on top.",
    quote: "Tough times don't last, tough people do",
    biggestCash: "R760 000",
  },
  {
    image: `${Gareth}`,
    name: "Gareth Duncan",
    bio: "A well-known figure on the Cape Town poker scene, Gareth Duncan is excited to make a name for himself at national and international level as a ShuffleUp Brand Ambassador. ",
    pokerMoment:"While he has a few notable cashes to his name, his proudest achievement to date is back-to-back final table finishes at the DAR Masters in Tanzania in 2023. ",
    quote: "`Life is not always a matter of holding good cards, but sometimes playing a poor hand well.' ~ Jack London",
    biggestCash: "R130 000",
  },
];

// Extract the card into its own component so that both grid and carousel share the same styling
const PlayerCard = ({ player, isMobile }) => (
  <Box
    sx={{
      paddingTop: "0px",
      marginTop: "0px",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      width: isMobile ? "100%" : "280px",
      height: "100%",
      boxShadow: "0px 0px 30px rgba(174, 61, 239, 0.35)",
      maxHeight: "650px",
      background: "linear-gradient(180deg, #5E2794 -58.27%, #321B62 31.6%, #21174F 100%)",
      border: "1px solid",
      borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
      overflow: "auto",
    }}
  >
    <Box
      component="img"
      src={player.image}
      alt={player.name}
      sx={{
        width: "100%",
        height: "280px",
        paddingTop: "0px",
        objectFit: "cover",
        background: "transparent",
      }}
    />
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        padding: "15px",
        gap: "12px",
        textAlign: "center",
        background: "transparent",
      }}
    >
      <Typography variant="h6" fontWeight="bold" sx={{ color: "#FFFFFF" }}>
        {player.name}
      </Typography>
      <Accordion
        sx={{
          width: "100%",
          background: "transparent",
          borderBottom: "0.7px solid #AE3DEF",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#72ECEA" }} />}>
          <Typography
            sx={{
              textAlign: "left",
              fontWeight: "800",
              textTransform: "uppercase",
              color: "#FFFFFF",
              fontSize: "12px",
            }}
          >
            Bio
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: "left", color: "#FFFFFF", fontSize: "12px" }}>
            {player.bio}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          width: "100%",
          background: "transparent",
          borderBottom: "0.7px solid #AE3DEF",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#72ECEA" }} />}>
          <Typography
            sx={{
              textAlign: "left",
              fontWeight: "800",
              textTransform: "uppercase",
              color: "#FFFFFF",
              fontSize: "12px",
            }}
          >
            Favorite Poker Moment
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: "left", color: "#FFFFFF", fontSize: "12px" }}>
            {player.pokerMoment}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          width: "100%",
          background: "transparent",
          borderBottom: "0.7px solid #AE3DEF",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#72ECEA" }} />}>
          <Typography
            sx={{
              textAlign: "left",
              fontWeight: "800",
              textTransform: "uppercase",
              color: "#FFFFFF",
              fontSize: "12px",
            }}
          >
            Quote
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: "left", color: "#FFFFFF", fontSize: "12px" }}>
            {player.quote}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Box sx={{ background: "transparent", textAlign: "left", width: "100%", marginTop: "10px" }}>
        <Typography
          sx={{
            color: "#72ECEA",
            fontWeight: "700",
            fontSize: "14px",
            textTransform: "uppercase",
          }}
        >
          Biggest Cash
        </Typography>
        <Typography
          sx={{
            color: "#FFFFFF",
            fontWeight: "700",
            fontSize: "14px",
          }}
        >
          {player.biggestCash}
        </Typography>
      </Box>
    </Box>
  </Box>
);

const TeamProfileGrid = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // Determine if we should use the carousel (more than 4 players) or the grid
  const isCarousel = pokerPlayers.length > 4;

  // Slider settings mimic the grid layout (4 per view on desktop)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        background: "#000",
        padding: "0",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="lg">
        {isCarousel ? (
          <Slider {...settings}>
            {pokerPlayers.map((player, index) => (
              <Box key={index} sx={{ padding: "0 8px", background: "transparent" }}>
                <PlayerCard player={player} isMobile={isMobile} />
              </Box>
            ))}
          </Slider>
        ) : (
          <Grid container spacing={2} justifyContent="center" sx={{ paddingTop: "0px" }}>
            {pokerPlayers.map((player, index) => (
              <Grid item xs={12} sm={6} md={3} key={index} sx={{ paddingTop: "0px" }}>
                <PlayerCard player={player} isMobile={isMobile} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default TeamProfileGrid;
