import React, { useState, useContext } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";
import Logo from "../../../assets/img/frontend/sup-logo.svg";
import { Link } from "react-router-dom";
import SmallGradientButton from "../Button/SmallGradientButton";
import MobileDrawer from "./MobileDrawer";
import CartIcon from "./CartIcon";
import CartDrawer from "./CartDrawer";
import { CartContext } from "../../../Context/CartContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [menuOpen, setMenuOpen] = useState(false);
  const [eventsMenuOpen, setEventsMenuOpen] = useState(null);
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);

  // Use the cartItems from your CartContext instead of dummy data.
  const { cartItems, removeFromCart } = useContext(CartContext);

  // Checkout function that sends the entire cartItems array via email.
  const handleCheckout = async () => {
    try {
      const response = await fetch("/api/smpt/send-mail", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ cartItems }),
      });
      if (response.ok) {
        toast.success("Sent your checkout request.");
        // Optionally, clear the cart here.
      } else {
        toast.error("Failed to send checkout request.");
      }
    } catch (error) {
      toast.error("An error occurred during checkout.");
    }
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          height: isMobile ? "4rem" : "6rem",
          backgroundColor: "#0E0B0E",
          paddingBottom: "2rem",
          boxShadow: "none",
          justifyContent: "center",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ display: "flex", alignItems: "center", background: "transparent" }}>
            <Typography variant="h6" component="div">
              <a href="/">
                <img src={Logo} alt="Logo" />
              </a>
            </Typography>
          </Box>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 3, background: "transparent" }}>
            <Button
              color="inherit"
              onClick={(e) => setEventsMenuOpen(e.currentTarget)}
              endIcon={<ArrowDropDownIcon />}
            >
              Events
            </Button>
            <Menu
              anchorEl={eventsMenuOpen}
              open={Boolean(eventsMenuOpen)}
              onClose={() => setEventsMenuOpen(null)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              PaperProps={{
                sx: {
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
                  padding: "8px 24px 32px",
                  width: "209px",
                  height: "132px",
                  background: "#0E0B0E",
                  borderRadius: "6px",
                },
              }}
            >
              <MenuItem
                component={Link}
                to={"/events"}
                onClick={() => setEventsMenuOpen(null)}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "16px 10px 10px 0px",
                  gap: "10px",
                  width: "161px",
                  height: "46px",
                  borderBottom: "0.7px solid transparent",
                  transition: "border-bottom 0.3s, color 0.3s",
                  "& .MuiTypography-root": { color: "#FFFFFF", fontFamily: "inherit" },
                  "&:hover": {
                    borderBottom: "0.7px solid",
                    borderImageSource: "linear-gradient(0deg, #72ECEA, #AE3DEF)",
                    borderImageSlice: 1,
                    "& .MuiTypography-root": { color: "#72ECEA" },
                  },
                }}
              >
                <Typography>Current Events</Typography>
              </MenuItem>
              <MenuItem
                component={Link}
                to={"/events/past"}
                onClick={() => setEventsMenuOpen(null)}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "16px 10px 10px 0px",
                  gap: "10px",
                  width: "161px",
                  height: "46px",
                  borderBottom: "0.7px solid transparent",
                  transition: "border-bottom 0.3s, color 0.3s",
                  "& .MuiTypography-root": { color: "#FFFFFF", fontFamily: "inherit" },
                  "&:hover": {
                    borderBottom: "0.7px solid",
                    borderImageSource: "linear-gradient(0deg, #72ECEA, #AE3DEF)",
                    borderImageSlice: 1,
                    "& .MuiTypography-root": { color: "#72ECEA" },
                  },
                }}
              >
                <Typography>Past Events</Typography>
              </MenuItem>
            </Menu>
            <Button component={Link} to={"/team"} color="inherit">
              Team ShuffleUp
            </Button>
            <Button component={Link} to={"/live-feed"} color="inherit">
              Live Reporting
            </Button>
            <Button component={Link} to={"/news"} color="inherit">
              News
            </Button>
            <Button component={Link} to={"/shop"} color="inherit">
              Shop
            </Button>
            <Button component={Link} to={"/contact"} color="inherit">
              Contact
            </Button>
          </Box>

          {/* Right Side */}
          <Box sx={{ display: "flex", gap: 2, background: "transparent", alignItems: "center" }}>
            <Button
              component={Link}
              to={"/signup"}
              color="inherit"
              sx={{ display: isMobile ? "none" : "flex" }}
            >
              Sign Up
            </Button>
            <Link to={"/login"} style={{ display: isMobile ? "none" : "block" }}>
              <SmallGradientButton variant="contained">Login</SmallGradientButton>
            </Link>
            <CartIcon onClick={() => setCartDrawerOpen(true)} />
            <IconButton
              sx={{ display: { md: "none" }, color: "white" }}
              onClick={() => setMenuOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <MobileDrawer open={menuOpen} onClose={() => setMenuOpen(false)} />

      {/* Cart Drawer */}
      <CartDrawer
        open={cartDrawerOpen}
        onClose={() => setCartDrawerOpen(false)}
        cartItems={cartItems}
        onRemoveItem={removeFromCart}
        onCheckout={handleCheckout}
      />
      <ToastContainer position="top-left" autoClose={3000} />
    </>
  );
};

export default Navbar;
