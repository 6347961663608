import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from "../MUIDialogueBox/ErrorDialogueBox";
import Box from "@mui/material/Box";
import ToastService from "../../Service/toast.service";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";

function PostsScreen() {
  const [posts, setPosts] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const handleDialogueOpen = () => setErrorDialogueBoxOpen(true);
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  useEffect(() => {
    fetchPosts();
    fetchAuthors();
  }, []);

  const fetchPosts = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      const response = await axios.get(`${baseApi}/posts`);
      setPosts(response.data);
    } catch (error) {
      console.log("Failed to fetch posts.");
    }
  };

  const fetchAuthors = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      const response = await axios.get(`${baseApi}/users`);
      setAuthors(response.data);
    } catch (error) {
      console.log("Failed to fetch authors.");
    }
  };

  const deletePost = async (id) => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za/api";

    try {
      await axios.delete(`${baseApi}/posts/${id}`);
      ToastService.success("Post deleted successfully.");
      fetchPosts();
    } catch (error) {
      setErrorList(["Failed to delete post."]);
      handleDialogueOpen();
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 6 }}>
      <div className="post-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-sm-4 col-3">
              <h4 className="post-title fw-bold">Posts</h4>
            </div>
            <div className="col-sm-8 col-9 text-right m-b-20">
              <Link to="/account/posts/add" className="btn btn-primary float-right btn-rounded">
                <i className="fa fa-plus"></i> Add Post
              </Link>
            </div>
          </div>
          <form action="/account/posts" name="postFilter">
            <div className="row filter-row">
              <div className="col-sm-4 col-md-4">
                <div className="form-floating">
                  <input type="text" name="name" className="form-control" placeholder="Post Name" />
                  <label className="focus-label">Post Name</label>
                </div>
              </div>
              <div className="col-sm-4 col-md-4">
                <button type="submit" className="btn btn-primary btn-block">
                  Search
                </button>
              </div>
            </div>
          </form>

          <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Post Name</TableCell>
                  <TableCell>Author</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Published Date</TableCell>
                  <TableCell>Modified Date</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {posts.map((post) => (
                  <TableRow key={post.id}>
                    <TableCell>{post.name}</TableCell>
                    <TableCell>{post.author}</TableCell>
                    <TableCell>{post.status}</TableCell>
                    <TableCell>{post.publishedDate}</TableCell>
                    <TableCell>{post.modifiedDate}</TableCell>
                    <TableCell>
                      <Link to={`/account/posts/edit/${post.id}`} className="btn btn-warning btn-sm m-r-2">
                        <EditIcon fontSize="small" /> Edit
                      </Link>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() => deletePost(post.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Post Management"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default PostsScreen;
