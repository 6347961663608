import React from "react";
import { Button } from "@mui/material";

const FullWidthGradientButton = ({ children, onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "8px 16px",
        gap: "49px",
        width: "100%",
        background: "linear-gradient(88.24deg, #72ECEA 41.73%, #AE3DEF 121.56%)",
        borderRadius: "4px",
        color: "#000",
        fontSize: "16px",
        fontWeight: "bold",
        textTransform: "capitalize",
        transition: "0.3s",
        "&:hover": {
          opacity: 0.9,
          background: "linear-gradient(88.24deg, #72ECEA 41.73%, #AE3DEF 121.56%)", // Keeps the same gradient on hover
        },
      }}
    >
      {children}
    </Button>
  );
};

export default FullWidthGradientButton;
