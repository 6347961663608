import React from 'react';
import Navbar from '../../components/Frontend/Header/Navbar';
import ThinBanner from '../../components/Frontend/Banner/ThinBanner';
import Footer from '../../components/Frontend/Footer/PrimaryFooter';
import GallerySection from '../../components/Frontend/Sections/GallerySection';

function EventsGallery(){
    return(
        <>
        <Navbar/>
        <GallerySection/>
        <ThinBanner/>
        <Footer/>
        </>
    )
}
export default EventsGallery;