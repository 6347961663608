import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import axios from "axios";
import moment from "moment"
import ConfirmDeleteDialogue from '../MUIDialogueBox/ConfirmDeleteDialogue'
import { BootstrapDialog, BootstrapDialogTitle } from "../MUIDialogueBox/BoostrapDialogueBox"
import DialogContent from '@mui/material/DialogContent';
import EventForm from '../Forms/EventForm'


const columns = [
    { id: 'customerName', label: 'Customer Name', minWidth: 170 },
    { id: 'employeeName', label: 'Location Name', minWidth: 100 },
    { id: 'eventDate', label: 'Event Date', minWidth: 170 },
    { id: 'eventTime', label: 'Event Time', minWidth: 170 },
    { id: 'actionsID', label: 'Actions', minWidth: 100 },
];

function createData(customerName, employeeName, eventDate, eventTime, actionsID) {
    return { customerName, employeeName, eventDate, eventTime, actionsID };
}



export default function EventTable({ bookedEvents, deleteBookedSlots, employeeList, customerList, availableSlots, getAvailableSlots, getBookedSlots }) {
    console.log("bookedEvents", bookedEvents);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [openConfirmDeleteDialogue, setOpenConfirmDeleteDialogue] = React.useState(false);
    const [openEditFormDialogue, setOpenEditFormDialogue] = React.useState(false);

    const [employeeId, setLocationId] = React.useState("");
    const [customerId, setCustomerId] = React.useState("");
    const [eventDate, setEventDate] = React.useState("");
    const [eventTime, setEventTime] = React.useState("");
    const [eventId, setEventId] = React.useState("");

    const [appIDToDelete,setAppIDToDelete] = React.useState("");


    const handleDeleteDialogueOpen = () => {
        setOpenConfirmDeleteDialogue(true);
    };

    const handleDeleteDialogueClose = () => {
        setOpenConfirmDeleteDialogue(false);
    };

    const handleEditFormOpen = () => {
        setOpenEditFormDialogue(true)
    }

    const handleEditFormClose = () => {
        setOpenEditFormDialogue(false)
    }

    const baseApi = process.env.REACT_APP_API_BASE_URL || "https://my.docotela.co.za";

    const updateEventFormSubmitted = async (event) => {
        event.preventDefault();
        const form = document.forms.updateEvent;
        let appId = form.id.value;
        let reqObj = {
            "appDate": form.appDate.value,
            "appTime": form.appTime.value,
            "employeeId": form.employee.value,
            "customerId": form.customer.value
        }
        const response = await axios.put(`${baseApi}/Events/${appId}`,
            reqObj,
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }
        );

        console.log(response.data);
        getAvailableSlots();
        getBookedSlots();
        handleEditFormClose()
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const formatDateForDateInput = (dateOfJoining) => {
        dateOfJoining = moment(new Date(dateOfJoining.slice(0, -1))).format('YYYY-MM-DD');
        return dateOfJoining;
    }

    const setFormProperties = async (appID) => {
        console.log("appID",appID)
        const response = await axios.get(`${baseApi}/Events/${appID}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
        let app = response.data.event
        console.log(app);
        setLocationId(app.employeeId)
        setCustomerId(app.customerId)
        setEventDate(formatDateForDateInput(app.eventDate));
        setEventTime(app.eventTime);
        setEventId(app._id)

    }



    let rows = bookedEvents.map((apt) => {
        return createData(
            apt.customerId.userId.firstName + " " + apt.customerId.userId.lastName,
            apt.employeeId.userId.firstName + " " + apt.employeeId.userId.lastName,
            formatDateForDateInput(apt.eventDate),
            apt.eventTime,
            apt._id
        )
    })



    React.useEffect(() => {

    }, [])




    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 5, boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2) ", background: '#1e1e1e' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            if (column.id === 'actionsID') {
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        <Tooltip title="Edit" placement="top" arrow>
                                                            <EditIcon
                                                                className="mx-2"
                                                                style={{ color: '#ff6600', fontSize: 30 }}
                                                                onClick={() => {
                                                                    handleEditFormOpen();
                                                                    setFormProperties(value);
                                                                }}
                                                            />
                                                        </Tooltip>
                                                        <Tooltip title="Delete" placement="top" arrow>
                                                            <DeleteIcon
                                                                className="mx-2"
                                                                style={{ color: 'red', fontSize: 30 }}
                                                                onClick={()=>{
                                                                    setAppIDToDelete(value)
                                                                    handleDeleteDialogueOpen();
                                                                }}
                                                            />
                                                        </Tooltip>
                                                        
                                                    </TableCell>
                                                );
                                            } else {
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            }
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                    "& p": {
                        "marginTop": 'auto',
                        "marginBottom": 'auto'
                    }
                }}
            />
            <ConfirmDeleteDialogue
                title="Confirm Delete"
                message="Are you sure you want to delete this record? This action cannot be undone."
                open={openConfirmDeleteDialogue}
                handleClose={handleDeleteDialogueClose}
                handleDelete={() => {
                    deleteBookedSlots(appIDToDelete);
                    handleDeleteDialogueClose();
                }}
            />
            <BootstrapDialog
                onClose={handleEditFormClose}
                aria-labelledby="customized-dialog-title"
                open={openEditFormDialogue}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleEditFormClose}>
                    Update Event
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <EventForm
                        formName="updateEvent"
                        formOnSubmit={updateEventFormSubmitted}
                        appDate={eventDate}
                        appTime={eventTime}
                        employeeSelected={employeeId}
                        customerSelected={customerId}
                        employeeList={employeeList}
                        customerList={customerList}
                        availableSlots={availableSlots}
                        eventId={eventId} />
                </DialogContent>
            </BootstrapDialog>
        </Paper>
    );
}