import React from "react";
import Navbar from "../../components/Frontend/Header/Navbar";
import NewsRoomBanner from "../../components/Frontend/Banner/NewsRoomBanner";
import backgroundImage from "../../assets/img/frontend/background-purple-news.webp";
import LeftTextHeroBanner from "../../components/Frontend/Banner/LeftTextBanner";
import ThinBanner from "../../components/Frontend/Banner/ThinBanner";
import Footer from "../../components/Frontend/Footer/PrimaryFooter";
import EventListSection from "../../components/Frontend/Sections/EventListSection";
import IconSection from "../../components/Frontend/Sections/IconSection";

function EventsPastPage(){

    return(
        <>
        <Navbar/>
        <NewsRoomBanner 
        accent="Past"
        title="Events"
        backgroundImage={backgroundImage}
        />
        <EventListSection type="Past"/>
        <IconSection/>
        <LeftTextHeroBanner/>
        <ThinBanner/>
        <Footer/>
        </>
    );
}
export default EventsPastPage;