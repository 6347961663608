import React, { useState } from "react";
import { Divider, Box, TextField, Typography, FormControlLabel, Checkbox, Grid, Container, CircularProgress } from "@mui/material";
import CustomButton from "../Button/CustomButton";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    comments: "",
    marketing: false,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const baseApiUrl = process.env.API_BASE_URL || "https://shuffleuppoker.com/";

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const emailPayload = {
      to: "anastasia@shuffleuppoker.com",
      subject: "Direct Message from Contact Form",
      body: `
Name: ${formData.firstName} ${formData.lastName}
Email: ${formData.email}
Phone: ${formData.phoneNumber}
Comments: ${formData.comments}
Marketing Opt-In: ${formData.marketing ? "Yes" : "No"}
      `,
    };

    try {
      const response = await fetch(`${baseApiUrl}api/api/smtp/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailPayload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(`Error sending email: ${errorData.message || "Unknown error"}`);
      } else {
        toast.success("Email sent successfully!");
      }
    } catch (error) {
      toast.error("Failed to send email.");
    } finally {
      setIsLoading(false);
    }
  };

  const inputStyles = {
    borderRadius: "4px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": { borderColor: "#ffffff" },
      "&:hover fieldset": { borderColor: "#AE3DEF" },
      "&.Mui-focused fieldset": { borderColor: "#AE3DEF" },
    },
    "& .MuiInputLabel-root": {
      color: "#ffffff",
      "&.Mui-focused": { color: "#AE3DEF" },
    },
  };

  return (
    <Box sx={{ background: "#000000", padding: "0", color: "#fff", position: "relative" }}>
      <ToastContainer />
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Direct Message
        </Typography>
        <Divider
          sx={{
            backgroundColor: "#fff",
            border: "1px solid",
            borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
          }}
        />
        <Box component="form" onSubmit={handleSubmit} sx={{ background: "transparent", position: "relative" }}>
          <Grid container spacing={3} alignItems="center">
            {/* Name Group */}
            <Grid item xs={12} sm={3}>
              <Typography variant="h6">Name</Typography>
            </Grid>
            <Grid item xs={12} sm={9} sx={{ display: "flex", gap: "1rem" }}>
              <TextField
                fullWidth
                name="firstName"
                label="First Name"
                variant="outlined"
                value={formData.firstName}
                onChange={handleChange}
                sx={inputStyles}
              />
              <TextField
                fullWidth
                name="lastName"
                label="Last Name"
                variant="outlined"
                value={formData.lastName}
                onChange={handleChange}
                sx={inputStyles}
              />
            </Grid>

            {/* Email Group */}
            <Grid item xs={12} sm={3}>
              <Typography variant="h6">Email</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                name="email"
                label="Email"
                variant="outlined"
                value={formData.email}
                onChange={handleChange}
                sx={inputStyles}
              />
            </Grid>

            {/* Phone Number Group */}
            <Grid item xs={12} sm={3}>
              <Typography variant="h6">Phone Number</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                name="phoneNumber"
                label="Phone Number"
                variant="outlined"
                value={formData.phoneNumber}
                onChange={handleChange}
                sx={inputStyles}
              />
            </Grid>

            {/* Comments Field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="comments"
                label="Comments"
                variant="outlined"
                multiline
                rows={4}
                value={formData.comments}
                onChange={handleChange}
                sx={inputStyles}
              />
            </Grid>

            {/* Marketing Checkbox */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="marketing"
                    checked={formData.marketing}
                    onChange={handleChange}
                    sx={{ color: "#fff" }}
                  />
                }
                label="I agree to receive marketing emails"
                sx={{ color: "#fff" }}
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <CustomButton type="submit" variant="contained" color="primary">
                Submit
              </CustomButton>
            </Grid>
          </Grid>

          {/* Loader Overlay */}
          {isLoading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 10,
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ContactForm;
