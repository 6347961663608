import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomText from "../Tyopgraphy/CustomText";
import TealButton from "../Button/TealButton";
import RegistrationModal from "../Modals/RegistrationModal";

const ImageBanner = ({ heading, description, background, type }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <Box
      sx={{
        position: "relative",
        textAlign: "center",
        marginTop: 0,
        pt: "0px !important", // override global padding-top
        background: "transparent !important", // override global background
        color: "white",
      }}
    >
      {/* Background Image */}
      <Box
        component="img"
        src={background}
        alt="Banner Background"
        sx={{
          width: "100%",
          mt: 0,
          pt: 0,
          height: "80vh", // Adjust as needed
          objectFit: "cover",
          display: "block",
        }}
      />

      {/* Gradient Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%)",
        }}
      />

      {/* Content (Positioned over the darker area of the gradient) */}
      <Box
        sx={{
          position: "absolute",
          bottom: "10%", // Adjust to move content up/down into the dark gradient
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          maxWidth: "800px",
          textAlign: "center",
          background: "transparent",
        }}
      >
        {/* Heading */}
        <CustomText variant="h3" fontWeight="bold" gutterBottom>
          {heading}
        </CustomText>

        {/* Description Text */}
        <Typography
          variant="h6"
          sx={{
            opacity: 0.8,
            maxWidth: "600px",
            margin: "0 auto",
            color: "#FFFFFF",
          }}
        >
          {description}
        </Typography>

        {/* Conditionally render the Register button if eventType is "Current" */}
        {type === "Current" && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              marginTop: "2rem",
              background: "transparent",
            }}
          >
            <TealButton onClick={handleOpenModal}>
              Enquire Now!
            </TealButton>
          </Box>
        )}
      </Box>

      <RegistrationModal open={modalOpen} handleClose={handleCloseModal} />
    </Box>
  );
};

export default ImageBanner;
