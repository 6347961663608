import React, { useState } from "react";
import { Box, Button, Typography, Container } from "@mui/material";

const tabData = [
  { id: "tab1", label: "Tab One", content: "This is the content for Tab One. Add your relevant details here." },
  { id: "tab2", label: "Tab Two", content: "This is the content for Tab Two. You can include anything inside this tab." },
  { id: "tab3", label: "Tab Three", content: "Tab Three contains different content. You can customize as needed." },
];

const ButtonTabsSection = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  return (
    <Box sx={{ background: "#0E0A0F", padding: "4rem 0", color: "#fff" }}>
      <Container maxWidth="lg">
        
        {/* Horizontal Tabs */}
        <Box sx={{ display: "flex", gap: 2, marginBottom: "2rem", background:"transparent" }}>
          {tabData.map((tab) => (
            <Button
              key={tab.id}
              variant={activeTab === tab.id ? "contained" : "outlined"}
              color="primary"
              sx={{
                textAlign: "center",
                padding: "10px 20px",
                borderRadius: "4px",
                fontWeight: activeTab === tab.id ? "bold" : "normal",
              }}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </Button>
          ))}
        </Box>

        {/* Tab Content */}
        <Box sx={{background: "transparent"}}>
          {tabData.map((tab) => (
            activeTab === tab.id && (
              <Typography key={tab.id} variant="body1">
                {tab.content}
              </Typography>
            )
          ))}
        </Box>

      </Container>
    </Box>
  );
};

export default ButtonTabsSection;
