import './css/App.css';
import React from 'react';
import HomePage from './HomePage';
import { UserContextProvider } from './Context/UserContext';
import { ThemeProviderWrapper } from "./Context/ThemeContext";
import { ToastContainer } from 'react-toastify';
import CookiePopup from './components/Frontend/CookiePopUp';
import FloatingWhatsapp from './components/Frontend/Tooltips/FloatingWhatsapp';
import { CssBaseline } from "@mui/material";
import { CartProvider } from './Context/CartContext';

function App() {
  return (
    <div className="App">
      <ThemeProviderWrapper>
        <CartProvider>
        <CssBaseline />
        <UserContextProvider>
          <ToastContainer />
          <HomePage />
        </UserContextProvider>
        </CartProvider>
        <CookiePopup />
        <FloatingWhatsapp />
      </ThemeProviderWrapper>
    </div>
  );
}

export default App;
