import React from "react";
import { Box, Typography, Container } from "@mui/material";
import BackgroundImage from "../../../assets/img/frontend/home_achievements_banner.webp"; // Replace with actual path
import CenteredImage from "../../../assets/img/frontend/small-s.svg"; // Replace with actual path

const HeadlineText = () => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "60vh", // Adjust as needed
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "2rem 0",
        background: "transparent"
      }}
    >
      {/* Background Image */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: -2,
        }}
      />

      {/* Gradient Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "transparent",
          //background: "linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%)",
          zIndex: -1,
        }}
      />

      {/* Content */}
      <Container maxWidth="md">
        {/* Centered Image */}
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "2rem", background: "transparent" }}>
          <img
            src={CenteredImage}
            alt="Centered"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>

        {/* Headline */}
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ color: "#FFFFFF", marginBottom: "1rem" }}
        >
          South Africa's <br/>Premier Poker Experience
        </Typography>

        {/* Description */}
        <Typography
          variant="h6"
          sx={{ opacity: 0.8, maxWidth: "600px", margin: "0 auto", color: "#FFFFFF" }}
        >
          We specialise in poker tournaments for all levels, from beginners to high rollers and international poker stars.
        </Typography>
      </Container>
    </Box>
  );
};

export default HeadlineText;
