import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  Grid,
  IconButton,
  Divider,
  Container,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomButton from "../../components/Frontend/Button/CustomButton"; // Ensure this exists
import Navbar from "../../components/Frontend/Header/Navbar";
import Footer from "../../components/Frontend/Footer/PrimaryFooter";
import ThinBanner from "../../components/Frontend/Banner/ThinBanner";
import ReviewModal from "../../components/Frontend/Modals/ReviewModal";
import FullWidthGradientButton from "../../components/Frontend/Button/FullWidthGradientButton";
import TealButton from "../../components/Frontend/Button/TealButton";

import BomberJacket from "../../assets/img/frontend/BomberJacket.png"
import SUPShirts from "../../assets/img/frontend/SUPShirts.png"
import LTDPuffer from "../../assets/img/frontend/LTDPuffer.png"
import PokerPro from "../../assets/img/frontend/PokerPro.png"
import SUPCaps from "../../assets/img/frontend/SUPCap.png"
import SUPTops from "../../assets/img/frontend/SUPTops.png"
import CustomMerch from "../../assets/img/frontend/CustomMerch.png"

/**
 * Constant to return Not Found path message
 * @since 0.1
 * @returns 
 */
const NotFound = () => <h2 style={{ margin: '70px' }}>This Path is not available</h2>

// Dummy Product Data (Should be replaced with dynamic data from API)
const products = [{
  id: 1,
  image: `${BomberJacket}`,
  title: `Bomber Jacket`,
  price: `R200.00`,
  shipsIn: `5 - 7 working days`,
  slug: 'bomber-jacket',
  images: [
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
  ],
  colors: ["Black", "Red", "Blue"],
  sizes: ["S", "M", "L", "XL"],
},
{
  id: 2,
  image: `${SUPShirts}`,
  title: `SUP T-Shirts`,
  price: `R350.00`,
  shipsIn: `5 -7 working days`,
  slug: 'sup-tshirts',
  images: [
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
  ],
  colors: ["Black", "Red", "Blue"],
  sizes: ["S", "M", "L", "XL"],
},
{
  id: 3,
  image: `${LTDPuffer}`,
  title: `Ltd Edition SA Poker Open Puffer Jacket`,
  price: `R800.00`,
  shipsIn: `5 -7 working days`,
  slug: 'ltd-puffer',
  images: [
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
  ],
  colors: ["Black", "Red", "Blue"],
  sizes: ["S", "M", "L", "XL"],
},
{
  id: 4,
  image: `${PokerPro}`,
  title: `Poker Pro Puffer`,
  price: `R1400.00`,
  shipsIn: `5 -7 working days`,
  slug: 'poker-pro-puffer',
  images: [
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
  ],
  colors: ["Black", "Red", "Blue"],
  sizes: ["S", "M", "L", "XL"],
},
{
  id: 4,
  image: `${SUPCaps}`,
  title: `SUP Caps`,
  price: `R100.00`,
  shipsIn: `5 -7 working days`,
  slug: 'sup-caps',
  images: [
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
  ],
  colors: ["Black", "Red", "Blue"],
  sizes: ["S", "M", "L", "XL"],
},
{
  id: 4,
  image: `${SUPTops}`,
  title: `SUP Tops`,
  price: `R500.00`,
  shipsIn: `5 -7 working days`,
  slug: 'sup-tops',
  images: [
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
  ],
  colors: ["Black", "Red", "Blue"],
  sizes: ["S", "M", "L", "XL"],
},
{
  id: 4,
  image: `${CustomMerch}`,
  title: `SUP Tops`,
  price: `Contact To Order`,
  shipsIn: `5 -7 working days`,
  slug: "custom-merch",
  images: [
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
    "https://placehold.co/400x500",
  ],
  colors: ["Black", "Red", "Blue"],
  sizes: ["S", "M", "L", "XL"],
}
];
// Dummy Recommended Products Data
const relatedProducts = Array.from({ length: 8 }, (_, i) => ({
  id: i + 2,
  image: "https://placehold.co/200x250",
  title: `Product ${i + 2}`,
  price: `$${(Math.random() * 100).toFixed(2)}`,
}));

const SingleProductPage = () => {
  const { slug } = useParams(); 
  const [product, setProduct] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");  // Empty initial value
  const [selectedSize, setSelectedSize] = useState("");    // Empty initial value
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const foundProduct = products.find((prod) => prod.slug === slug); // Find product by slug
    if (foundProduct) {
      setProduct(foundProduct);
    }
  }, [slug]);

  // Set initial values for color and size once product is loaded
  useEffect(() => {
    if (product) {
      setSelectedColor(product.colors[0]);
      setSelectedSize(product.sizes[0]);
    }
  }, [product]);

  if (!product) {
    return <NotFound/> ;
  }

  return (
    <div>
      <Navbar />
      <Box sx={{ background: "#000", color: "#fff", padding: "4rem 0" }}>
        <Container maxWidth="lg">
          <Grid container spacing={6}>
            {/* Left Column: Product Images Carousel */}
            <Grid item xs={12} md={6}>
              <Swiper
                modules={[Navigation, Pagination]}
                navigation
                pagination={{ clickable: true }}
                style={{ width: "100%", borderRadius: "0px", border: "1px solid", borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1" }}
              >
                {product.images.map((img, index) => (
                  <SwiperSlide key={index}>
                    <img src={img} alt={`Product ${index}`} style={{ width: "100%", height: "500px", objectFit: "cover", borderRadius: "0px" }} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>

            {/* Right Column: Product Details */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{
                  width: "100%",
                  fontSize: "48px",
                  lineHeight: "81px",
                  fontFamily: "'Plus Jakarta Sans', sans-serif",
                  fontStyle: "normal",
                  fontWeight: 700,
                  textAlign: "left",
                  margin: "0 auto 2rem",
                  background: "linear-gradient(90deg, #72ECEA 18.06%, #AE3DEF 81.65%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                }}
              >{product.title}</Typography>
              <Typography variant="h5" sx={{ color: "#72ECEA", fontWeight: "bold", margin: "10px 0" }}>
                {product.price}
              </Typography>
              <Typography variant="body1" sx={{ opacity: 0.8, marginBottom: "1rem" }}>
                {product.description}
              </Typography>
              <Typography variant="h6" sx={{ color: "#72ECEA", fontWeight: "bold" }}>
                Limited Stock! Order Now!
              </Typography>

              {/* Color Selector */}
              <Box sx={{ background: "transparent", margin: "1rem 0" }}>
                <Typography variant="body1" fontWeight="bold">Choose a Color:</Typography>
                <Box sx={{ background: "transparent", display: "flex", gap: "10px", marginTop: "8px" }}>
                  {product.colors.map((color) => (
                    <Button
                      key={color}
                      variant={selectedColor === color ? "contained" : "outlined"}
                      onClick={() => setSelectedColor(color)}
                      sx={{ minWidth: "60px", color: "#fff", borderColor: "#fff" }}
                    >
                      {color}
                    </Button>
                  ))}
                </Box>
              </Box>

              {/* Size Selector */}
              <Box sx={{ background: "transparent", margin: "1rem 0" }}>
                <Typography variant="body1" fontWeight="bold">Choose a Size:</Typography>
                <Select
                  value={selectedSize}
                  onChange={(e) => setSelectedSize(e.target.value)}
                  fullWidth
                  sx={{ background: "#fff", borderRadius: "4px", color: "#000" }}
                >
                  {product.sizes.map((size) => (
                    <MenuItem key={size} value={size}>{size}</MenuItem>
                  ))}
                </Select>
              </Box>

              {/* Quantity Selector */}
              <Box sx={{ background: "transparent", display: "flex", alignItems: "center", gap: 2, margin: "1rem 0" }}>
                <Typography variant="body1" fontWeight="bold">Quantity:</Typography>
                <IconButton onClick={() => setQuantity(Math.max(1, quantity - 1))} sx={{ color: "#fff" }}>
                  <RemoveIcon />
                </IconButton>
                <Typography>{quantity}</Typography>
                <IconButton onClick={() => setQuantity(quantity + 1)} sx={{ color: "#fff" }}>
                  <AddIcon />
                </IconButton>
              </Box>

              {/* Add to Cart Button */}
              <FullWidthGradientButton variant="contained" fullWidth>
                Add to Cart
              </FullWidthGradientButton>
            </Grid>
          </Grid>

          {/* Bottom Section */}
          <Box sx={{ background: "transparent", marginTop: "4rem" }}>
            {/* You May Also Like */}
            <Typography variant="h4" textAlign="center" fontWeight="bold" gutterBottom>
              You May Also Like
            </Typography>

            <Swiper
              modules={[Navigation]}
              spaceBetween={20}
              slidesPerView={4}
              navigation
              style={{ width: "100%", maxWidth: "1000px", margin: "0 auto" }}
            >
              {relatedProducts.map((item) => (
                <SwiperSlide key={item.id}>
                  <Box sx={{ background: "transparent", textAlign: "center" }}>
                    <img src={item.image} alt={item.title} style={{ border: "1px solid",
        borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1", width: "100%", borderRadius: "0px" }} />
                    <Typography variant="body1" fontWeight="bold">{item.title}</Typography>
                    <Typography variant="body2" sx={{ color: "#72ECEA" }}>{item.price}</Typography>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
        </Container>
      </Box>
      <Footer />
    </div>
  );
};

export default SingleProductPage;
