import React from "react";
import { Button } from "@mui/material";

const SmallBorderTealButton = ({ children, onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 19px",
        gap: "10px",
        width: "100px",
        height: "37px",
        border: "1px solid #72ECEA",
        borderRadius: "4px",
        color: "#72ECEA",
        fontWeight: "bold",
        textTransform: "none",
        background: "transparent",
        "&:hover": {
          background: "#72ECEA",
          color: "#000",
        },
      }}
    >
      {children}
    </Button>
  );
};

export default SmallBorderTealButton;
