import React from 'react';
import Navbar from '../../components/Frontend/Header/Navbar';
import Footer from '../../components/Frontend/Footer/PrimaryFooter';
import ThinBanner from '../../components/Frontend/Banner/ThinBanner';
import LeftTextHeroBanner from '../../components/Frontend/Banner/LeftTextBanner';
import IconSection from '../../components/Frontend/Sections/IconSection';
import EventListSection from '../../components/Frontend/Sections/EventListSection';
import NewsRoomBanner from '../../components/Frontend/Banner/NewsRoomBanner';
import backgroundImage from "../../assets/img/frontend/background-purple-news.webp";

function EventsPage(){
    return(
        <>
        <Navbar/>
        <NewsRoomBanner
        accent="Scheduled"
        title="Poker Events" 
        backgroundImage={backgroundImage}
        />
        <EventListSection type="Current"/>
        <IconSection/>
        <LeftTextHeroBanner/>
        <ThinBanner/>
        <Footer/>
        </>
    );
}
export default EventsPage;