import React, { useState } from 'react';
import { Tabs, Tab, Box, Grid, useMediaQuery, useTheme, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Navbar from '../../components/Frontend/Header/Navbar';
import Footer from '../../components/Frontend/Footer/PrimaryFooter';
import ThinBanner from '../../components/Frontend/Banner/ThinBanner';
import DynamicFrontendTable from '../../components/Frontend/Tables/DynamicFrontendTable';
import CustomText from '../../components/Frontend/Tyopgraphy/CustomText';
import CustomText2 from '../../components/Frontend/Tyopgraphy/CustomText2';
import GradientH1 from '../../components/Frontend/Tyopgraphy/GradientH1';
import backgroundImage from "../../assets/img/frontend/productAndPolicies.webp";
import CustomButton from '../../components/Frontend/Button/CustomButton';
import overlayImage from '../../assets/img/frontend/woman.png';
import GradientH3 from '../../components/Frontend/Tyopgraphy/GradientH3';
import SingleAccordion from '../../components/Frontend/Sections/AccordionSection';

// Import multiple JSON data sets
import oneLifeData from '../../data/oneLifeData.json';
import rebuyData from '../../data/rebuy.json';
import SupCupDate from '../../data/SupCupData.json';
import MiniMainData from '../../data/miniMain.json';
import PlayOfTheYear from '../../data/playOfTheYear.json';
import ultimateTournament from '../../data/ultimateTournament.json'
import highRoller from '../../data/highRoller.json'
import mainEvent from '../../data/mainEvent.json'
import MainD1Day from '../../data/mainD1Day.json'
import Turbo from '../../data/Turbo.json'
import ButtonRow from '../../components/Frontend/Button/ButtonRow';
import WarningBanner from './WarningBanner';
import EventCard from './EventCard';


import SAPokerOpen1 from "../../assets/img/frontend/CaledonBanner.png";
import SAPokerOpen2 from "../../assets/img/frontend/SAOpenPokerEvent2.png";
import SAPokerOpen3 from "../../assets/img/frontend/SAOpenPokerEvent3.png";
import SAPokerOpen4 from "../../assets/img/frontend/Splendido12.png";

import Win1 from "../../assets/img/frontend/Win3.png";
import Win2 from "../../assets/img/frontend/Win2.png";
import Win3 from "../../assets/img/frontend/Win3.png";
//import Wes from "../../assets/img/frontend/WesBlom.webp"

// import Win4 from "../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
// import Win5 from "../../assets/img/frontend/MarkoNortje.jpeg";
// import Win6 from "../../assets/img/frontend/ODWSugen.jpeg";
// import Win7 from "../../assets/img/frontend/KirithHaria.jpeg";
// import Win8 from "../../assets/img/frontend/AhmedKarriem.jpeg";
// import Win9 from "../../assets/img/frontend/Evasan.jpeg";
// import Win10 from "../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
// import Win11 from "../../assets/img/frontend/CaledonGallery/MuazzGani-HighRoller.jpeg";
// import Win12 from "../../assets/img/frontend/Win3.png";


import SAP1 from "../../assets/img/frontend/SAPoker/SAP1.jpg";
import SAP2 from "../../assets/img/frontend/SAPoker/SAP2.jpg";
import SAP3 from "../../assets/img/frontend/SAPoker/SAP3.jpg";
import SAP4 from "../../assets/img/frontend/SAPoker/SAP4.jpg";
import SAP5 from "../../assets/img/frontend/SAPoker/SAP5.jpg";
import SAP6 from "../../assets/img/frontend/SAPoker/SAP6.jpg";
import SAP7 from "../../assets/img/frontend/SAPoker/SAP7.jpg";

import Win4 from "../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
import Win5 from "../../assets/img/frontend/MarkoWinnersPhoto.jpeg";
import Win6 from "../../assets/img/frontend/ODWSugen.jpeg";
import Win13 from "../../assets/img/frontend/WesBlom.webp"
import Win7 from "../../assets/img/frontend/KirithHaria.jpeg";
import Win8 from "../../assets/img/frontend/AhmedKarriem.jpeg";
import Win9 from "../../assets/img/frontend/Evasan.jpeg";
import Win10 from "../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
import Win11 from "../../assets/img/frontend/CaledonGallery/MuazzGani-HighRoller.jpeg";
import Win12 from "../../assets/img/frontend/Win3.png";
import Wes from "../../assets/img/frontend/WesBlom.webp";
import Benjamin from "../../assets/img/frontend/Benjamin.jpeg"

import SP1 from "../../assets/img/frontend/SP1.png";
import SP2 from "../../assets/img/frontend/SP2.png";
import SP3 from "../../assets/img/frontend/SP3.png";
import SP4 from "../../assets/img/frontend/SP4.png";
import SP5 from "../../assets/img/frontend/SP5.jpeg";
import SP6 from "../../assets/img/frontend/SP6.jpeg";
import SP7 from "../../assets/img/frontend/SP7.jpeg";
import SP8 from "../../assets/img/frontend/SP8.jpeg";

import CG1 from "../../assets/img/frontend/CaledonGallery/CG1.png";
import CG2 from "../../assets/img/frontend/CaledonGallery/CG2.png";
import CG3 from "../../assets/img/frontend/CaledonGallery/CG3.png";
import CG4 from "../../assets/img/frontend/CaledonGallery/CG4.png";
import CG6 from "../../assets/img/frontend/CaledonGallery/QB_04218.jpeg";
import CG11 from "../../assets/img/frontend/CaledonGallery/QB_04195.jpeg";
import CG12 from "../../assets/img/frontend/CaledonGallery/QB_04203.jpeg";
import CG14 from "../../assets/img/frontend/CaledonGallery/QB_04156.jpeg";
import CG15 from "../../assets/img/frontend/CaledonGallery/QB_04154.jpeg";
import CG16 from "../../assets/img/frontend/CaledonGallery/QB_04161.jpeg";
import CG17 from "../../assets/img/frontend/CaledonGallery/QB_04148.jpeg";
import CG18 from "../../assets/img/frontend/CaledonGallery/QB_04146.jpeg";
import CG19 from "../../assets/img/frontend/CaledonGallery/QB_04144.jpeg";
import CG20 from "../../assets/img/frontend/CaledonGallery/QB_04144.jpeg";
import CG21 from "../../assets/img/frontend/CaledonGallery/QB_04176.jpeg";

import B1 from "../../assets/img/frontend/B1.png";
import B2 from "../../assets/img/frontend/B2.png";
import B3 from "../../assets/img/frontend/B3.png";
import B4 from "../../assets/img/frontend/B4.png";
import BG1 from "../../assets/img/frontend/BG1.png";
import BG2 from "../../assets/img/frontend/BG2.png";
import BG3 from "../../assets/img/frontend/BG3.png";
import BG4 from "../../assets/img/frontend/BG4.png";
import BG5 from "../../assets/img/frontend/BG5.png";
import BG6 from "../../assets/img/frontend/BG6.png";
import BG7 from "../../assets/img/frontend/BG7.png";
import BG8 from "../../assets/img/frontend/BG8.png";
import { Shop2 } from '@mui/icons-material';

const events = [
  {
    event: "ONE LIFE",
    eventSeries: "Splendido Poker Series 12",
    Brochure: `images/OneLifeS12.pdf`
  },
  {
    event: "REBUY TOURNAMENT",
    eventSeries: "Splendido Poker Series 12",
    Brochure: `images/Rebuy.pdf`
  },
  {
    event: "THE SHUFFLEUP CHAMPIONSHIP CUP",
    eventSeries: "Splendido Poker Series 12",
    Brochure: `images/Champ.pdf`
  },
  {
    event: "MINI MAIN MORE CHIPS",
    eventSeries: "Splendido Poker Series 12",
    Brochure: `images/Mini.pdf`
  },
  {
    event: "PLAYER OF THE YEAR",
    eventSeries: "Splendido Poker Series 12",
    Brochure: `images/Player.pdf`
  },
  {
    event: "THE ULTIMATE TOURNAMENT",
    eventSeries: "Splendido Poker Series 12",
    Brochure: `images/Ultimate.pdf`
  },
  {
    event: "HIGH ROLLER",
    eventSeries: "Splendido Poker Series 12",
    Brochure: `images/HighRoller.pdf`
  },
  {
    event: "MAIN EVENT",
    eventSeries: "Splendido Poker Series 12",
    Brochure: `images/Main.pdf`
  },
  {
    event: "MAIN EVENT DAY 1D",
    eventSeries: "Splendido Poker Series 12",
    Brochure: `images/Main1.pdf`
  },
  {
    event: "THE BOUNTY TURBO",
    eventSeries: "Splendido Poker Series 12",
    Brochure: `images/Turbo.pdf`
  },

];

// Custom scroll button component for the Tabs
function ScrollButton(props) {

  const { direction, visible, onClick } = props;
  if (!visible) return null;
  return (
    <IconButton onClick={onClick} sx={{ color: "white" }}>
      {direction === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
  );
}

// A simple TabPanel component for rendering tab content.
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function EventsStructure({ event }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [tabIndex, setTabIndex] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  // Array of datasets with a label and the actual data
  const datasets = [
    { label: 'One Life', data: oneLifeData },
    { label: 'Rebuy Tournament', data: rebuyData },
    { label: 'SUP Cup Data', data: SupCupDate },
    { label: 'Play Of The Year', data: PlayOfTheYear },
    { label: 'Ultimate Tournament', data: ultimateTournament },
    { label: 'High Roller', data: highRoller },
    { label: 'Main Event', data: mainEvent},
    { label: 'Main Event D1 Day', data: MainD1Day },
    { label: 'Turbo', data: Turbo }
  ];

  const  buttons = [
    { "type": "dark", "text": "Players Handbook", "url": `${process.env.PUBLIC_URL}/images/sup_players_handbook_splendido.pdf`,target: "_blank"  },
    { "type": "dark", "text": "Schedule", "url": "/events/schedule" },
    { "type": "teal", "text": "Enquire Now", "url": "/events/enquiry" }
   ];
   
  return (
    <>
      <Navbar />
      {/* Top Banner Section */}
      <Box
        sx={{
          marginTop: "-5px",
          marginBottom: "0px",
          width: "100%",
          height: isMobile ? "40vh" : "60vh",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          color: "#fff",
          padding: "1rem"
        }}
      >
        <GradientH1>Official</GradientH1>
        <CustomText sx={{ marginBottom: "0px", paddingBottom: "0px" }}>
          Structure
        </CustomText>
        <ButtonRow buttons={buttons} onEnquireNoClick={handleOpenModal} />
        </Box>

      {/* Overlay Section Above the Table */}
      <Box sx={{ background: "#000", padding: "0rem 3rem", width: "100%" }}>
        <Box
          sx={{
            maxWidth: "1000px",
            width: "100%",
            background: "transparent",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: "0rem"
          }}
        >
          <Box
            sx={{
              background: "transparent",
              color: "#fff",
              width: isMobile? "100%":"70%",
              paddingLeft: '20px',
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: isMobile?"center":"flex-start",
              zIndex: 1
            }}
          >
            <GradientH3 sx={{ textAlign: "left", marginLeft: "0px" }}>
              Official Structure
            </GradientH3>
            <CustomText2
              sx={{
                textAlign: "left",
                marginTop: "0.5rem",
                marginBottom: "2rem",
                marginLeft: isMobile? '30px':'-20px',
                transform: isMobile ? "" : "translateX(165px)"
              }}
            >
              {event?.name || "Splendido Poker Series 12"}
            </CustomText2>
            {/* Render buttons from event.buttons */}
            <Box sx={{ display: 'flex', flexWrap: "wrap", justifyContent: isMobile ? "center" : "flex-start", gap: 2, mt: 2 }}>
              {event?.buttons && event.buttons.map((btn, index) => (
                <CustomButton key={index} type={btn.type} text={btn.text} url={btn.url} />
              ))}
            </Box>
          </Box>

          <Box
            component="img"
            src={overlayImage}
            alt="Overlay Decorative"
            sx={{
              background: "transparent",
              position: isMobile ? "static" : "absolute",
              display: isMobile? "none":"flex",
              right: isMobile ? "auto" : "200px",
              bottom: isMobile ? "auto" : "-125px",
              width: isMobile ? "100%" : "400px",
              height: isMobile ? "auto" : "400px",
              objectFit: "contain",
              zIndex: 2,
              mt: isMobile ? 2 : 0
            }}
          />
        </Box>

        {/* Tabbed Table Section */}
        <Box sx={{ backgroundColor: '#000', borderTop: '1px solid white', px: { xs: 2, sm: "3rem" }, pb: { xs: 2, sm: "3rem" }, position: "relative" }}>
        <EventCard events={events}/>
        </Box>
        <Box sx={{ display: "block", background: "transparent", marginLeft: "auto", marginRight: "auto", justifyContent: "center", alignContent: "center"}}>
        <WarningBanner/>
        </Box>
        <SingleAccordion />
      </Box>

      <ThinBanner />
      <Footer />
    </>
  );
}
