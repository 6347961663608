import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import CustomText from "../Tyopgraphy/CustomText";
import GradientH4 from "../Tyopgraphy/GradientH4";
import GradientH1 from "../Tyopgraphy/GradientH1";
import ButtonRow from "../Button/ButtonRow";
import CenteredImage from "../../../assets/img/frontend/small-s.svg";

const customButtons = [
  {
    text: "Custom 1",
    url: "/custom1",
    visible: true,
    slugs: ['/events/splendido-12-poker-series', "/events", "/page2"]
  },
  {
    text: "Custom 2",
    url: "/custom2",
    visible: true,
    slugs: ['/events/splendido-12-poker-series', '/events']
  },
  {
    text: "Custom 3",
    url: "/custom3",
    visible: true,
    slugs: ['/events/splendido-12-poker-series', '/events']
  }
];

const tealButton = {
  text: "Teal Button",
  url: "/teal",
  visible: true,
  slugs: ["/events/splendido-12-poker-series", "/home", "/about"]
};


const NewsRoomBanner = ({
  accent,
  title,
  subtitle,
  description,
  backgroundImage,
  height
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        marginTop: "-5px",
        marginBottom: "-10px",
        width: "100%",
        height: isMobile ? "50vh" : "60vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: "#fff",
        padding: "1rem",
      }}
    >
      {/* Title */}
      <GradientH1 sx={{ marginBottom: "0px", paddingBottom: "0px" }}>{accent}</GradientH1>
      <CustomText variant="h2" fontWeight="bold" sx={{ marginTop: "0px", paddingTop: "0px" }}>
        {title}
      </CustomText>

      {/* <ButtonRow 
        overallVisible={true}
        customButtons={customButtons}
        tealButton={tealButton}/> */}

      {/* Subtitle */}
      {subtitle && (
        <GradientH4 variant="h6" sx={{ fontSize: "21px !important", marginTop: "1rem", fontWeight: "500" }}>
          {subtitle}
        </GradientH4>
      )}

      {/* Description */}
      {description && (
        <Typography variant="body1" sx={{ marginTop: "1rem", maxWidth: "800px" }}>
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default NewsRoomBanner;
