import React from "react";
import { Typography } from "@mui/material";

const GradientH6 = ({ text }) => {
  return (
    <Typography
      variant="h6"
      sx={{
        fontFamily: "'Oswald', sans-serif",
        fontStyle: "italic",
        fontWeight: 700,
        fontSize: "28.1019px",
        lineHeight: "36px",
        textAlign: "center",
        background: "linear-gradient(90deg, #72ECEA 0%, #AE3DEF 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        flex: "none",
        alignSelf: "stretch",
        flexGrow: 0,
      }}
    >
      {text}
    </Typography>
  );
};

export default GradientH6;
