import React, { useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CustomButton from "./CustomButton";
import TealButton from "./TealButton";
import { Link } from "react-router-dom";
import RegistrationModal from "../Modals/RegistrationModal";

const ButtonRow = ({ buttons = [] }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (!buttons || buttons.length === 0) return null;

  return (
    <>
      <Box
        sx={{
          background: "transparent",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 2,
          paddingBottom: "2rem",
          width: isMobile ? "100%" : "auto",
        }}
      >
        {buttons.map((btn, index) => {
          // Check if button text equals "Enquire Now" (ignoring case and extra spaces)
          if (btn.text.trim().toLowerCase() === "enquire now") {
            return btn.type === "teal" ? (
              <TealButton
                key={index}
                style={{ width: isMobile ? "100%" : "177px", color: "#000" }}
                onClick={() => setModalOpen(true)}
              >
                {btn.text}
              </TealButton>
            ) : (
              <CustomButton key={index} onClick={() => setModalOpen(true)}>
                {btn.text}
              </CustomButton>
            );
          }

          // If the button has a target property, open URL in a new tab.
          if (btn.target) {
            return btn.type === "teal" ? (
              <TealButton
                key={index}
                style={{ width: isMobile ? "100%" : "177px", color: "#000" }}
                onClick={() => window.open(btn.url, btn.target)}
              >
                {btn.text}
              </TealButton>
            ) : (
              <CustomButton
                key={index}
                as={Link}
                to={btn.url}
                target={btn.target}
              >
                {btn.text}
              </CustomButton>
            );
          }

          // Default behavior: only render buttons with a URL
          if (!btn.url) return null;

          return btn.type === "teal" ? (
            <TealButton
              key={index}
              style={{ width: isMobile ? "100%" : "177px", color: "#000" }}
              onClick={() => (window.location.href = btn.url)}
            >
              {btn.text}
            </TealButton>
          ) : (
            <CustomButton key={index} as={Link} to={btn.url}>
              {btn.text}
            </CustomButton>
          );
        })}
      </Box>
      <RegistrationModal open={modalOpen} handleClose={() => setModalOpen(false)} />
    </>
  );
};

export default ButtonRow;
