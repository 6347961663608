import React, { useState, useContext } from "react";
import { CartContext } from "../../../Context/CartContext";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  Select,
  MenuItem,
  Divider,
  useMediaQuery,
  useTheme
} from "@mui/material";
import CustomButton from "../Button/CustomButton";
import { Link } from "react-router-dom";
import bannerImage from "../../../assets/img/frontend/banner-image.png";
import FullWidthGradientBtnSmall from "../Button/FullWidthGradientBtnSmall";

import BomberJacket from "../../../assets/img/frontend/BomberJacket.png";
import SUPShirts from "../../../assets/img/frontend/SUPShirts.png";
import LTDPuffer from "../../../assets/img/frontend/LTDPuffer.png";
import PokerPro from "../../../assets/img/frontend/PokerPro.png";
import SUPCaps from "../../../assets/img/frontend/SUPCap.png";
import SUPTops from "../../../assets/img/frontend/SUPTops.png";
import CustomMerch from "../../../assets/img/frontend/CustomMerch.png";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Example size and colour arrays
const sizes = ["XSS", "XS", "S", "M", "L", "XL", "XXL"];
const colors = ["Black", "White", "Red", "Blue", "Green", "Yellow"];

// Dummy products array (Note: Add soldOut flag where appropriate)
const products = [
  {
    id: 1,
    image: BomberJacket,
    title: "Bomber Jacket",
    price: "R200.00",
    shipsIn: "5 - 7 working days",
    slug: "bomber-jacket",
    soldOut: false
  },
  {
    id: 2,
    image: SUPShirts,
    title: "Custom Poker Wear",
    price: "R350.00",
    shipsIn: "5 -7 working days",
    slug: "sup-tshirts",
    soldOut: false
  },
  {
    id: 3,
    image: LTDPuffer,
    title: "Ltd Edition SA Poker Open Puffer Jacket",
    price: "R800.00",
    shipsIn: "5 -7 working days",
    slug: "ltd-puffer",
    soldOut: true
  },
  {
    id: 4,
    image: PokerPro,
    title: "Poker Pro Puffer",
    price: "R1400.00",
    shipsIn: "5 -7 working days",
    slug: "poker-pro-puffer",
    soldOut: true
  },
  {
    id: 5,
    image: SUPCaps,
    title: "SUP Caps",
    price: "R100.00",
    shipsIn: "5 -7 working days",
    slug: "sup-caps",
    soldOut: true
  },
  {
    id: 6,
    image: SUPTops,
    title: "SUP Tops",
    price: "R500.00",
    shipsIn: "5 -7 working days",
    slug: "sup-tops",
    soldOut: false
  },
  {
    id: 7,
    image: CustomMerch,
    title: "Custom Merch",
    price: "Contact To Order",
    shipsIn: "5 -7 working days",
    slug: "custom-merch",
    soldOut: false
  }
];

const ShopPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [sortBy, setSortBy] = useState("asc");
  const [visibleProducts, setVisibleProducts] = useState(12);

  const { addToCart } = useContext(CartContext);

  // Handle size selection
  const toggleSize = (size) => {
    setSelectedSizes((prev) =>
      prev.includes(size) ? prev.filter((s) => s !== size) : [...prev, size]
    );
  };

  // Handle color selection
  const toggleColor = (color) => {
    setSelectedColors((prev) =>
      prev.includes(color) ? prev.filter((c) => c !== color) : [...prev, color]
    );
  };

  // Sort products based on sortBy
  const sortedProducts = [...products].sort((a, b) => {
    if (sortBy === "asc") return a.title.localeCompare(b.title);
    if (sortBy === "desc") return b.title.localeCompare(a.title);
    if (sortBy === "price")
      return parseFloat(a.price.slice(1)) - parseFloat(b.price.slice(1));
    return 0;
  });

  // Toast message when adding product
  const handleAddToCart = (product) => {
    addToCart(product);
    toast.success(`${product.title} added to cart`);
  };

  return (
    <Box
      sx={{
        background: "#000",
        padding: isMobile ? "1rem 2rem" : "2rem 5rem",
        width: "100%",
        backgroundColor: "#000"
      }}
    >
      <Divider
        sx={{
          border: 0,
          height: "3px !important",
          background: "linear-gradient(90deg, #72ECEA, #AE3DEF)",
          margin: "2rem 0"
        }}
      />
      <Box
        sx={{
          background: "transparent",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 4,
          width: "100%",
          maxWidth: "1400px",
          margin: "0 auto",
          color: "#fff"
        }}
      >
        {/* Left Sidebar (Filters) */}
        <Box sx={{ width: isMobile ? "100%" : "20%", background: "transparent" }}>
          <Box sx={{ background: "transparent", position: "sticky", top: "20px" }}>
            <Typography variant="h6" fontWeight="bold" sx={{ background: "transparent" }}>
              Size
            </Typography>
            <Box
              sx={{
                display: "flex",
                background: "transparent",
                flexWrap: "wrap",
                gap: 1,
                marginBottom: "1rem"
              }}
            >
              {sizes.map((size) => (
                <Button
                  key={size}
                  variant={selectedSizes.includes(size) ? "contained" : "outlined"}
                  onClick={() => toggleSize(size)}
                  sx={{
                    minWidth: "50px",
                    fontSize: "12px",
                    background: "transparent",
                    ...(selectedSizes.includes(size)
                      ? {
                          backgroundColor: "#72ECEA",
                          color: "#fff",
                          borderColor: "#72ECEA",
                          "&:hover": { backgroundColor: "#72ECEA" }
                        }
                      : {
                          color: "#fff",
                          borderColor: "#fff",
                          "&:hover": { borderColor: "#72ECEA", color: "#72ECEA" }
                        })
                  }}
                >
                  {size}
                </Button>
              ))}
            </Box>
            <Typography variant="h6" fontWeight="bold" sx={{ background: "transparent" }}>
              Color
            </Typography>
            <FormGroup sx={{ background: "transparent" }}>
              {colors.map((color) => (
                <FormControlLabel
                  key={color}
                  control={
                    <Checkbox
                      sx={{
                        color: "#fff",
                        "&.Mui-checked": { color: "#72ECEA" },
                        "&:hover": { borderColor: "#72ECEA", color: "#72ECEA" }
                      }}
                      checked={selectedColors.includes(color)}
                      onChange={() => toggleColor(color)}
                    />
                  }
                  label={color}
                  sx={{
                    background: "transparent",
                    color: selectedColors.includes(color) ? "#72ECEA" : "#fff",
                    "&:hover": { color: "#72ECEA" },
                    display: isMobile ? "flex" : "",
                    flexDirection: isMobile ? "row" : ""
                  }}
                />
              ))}
            </FormGroup>
          </Box>
        </Box>

        {/* Main Content (Products & Sorting) */}
        <Box sx={{ width: isMobile ? "100%" : "60%", background: "transparent" }}>
          <Box
            sx={{
              display: "flex",
              background: "transparent",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem"
            }}
          >
            <Typography variant="h4" fontWeight="bold" sx={{ background: "transparent" }}>
              Shop
            </Typography>
            <Select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              size="small"
              sx={{
                background: "linear-gradient(180deg, #21174F 16.75%, #0E0B0E 140.05%)",
                border: "1px solid",
                borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
                color: "#fff",
                borderRadius: "4px",
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                "& .MuiSelect-icon": { color: "#fff" },
                padding: "4px 8px",
                background: "transparent"
              }}
            >
              <MenuItem value="asc">Sort A-Z</MenuItem>
              <MenuItem value="desc">Sort Z-A</MenuItem>
              <MenuItem value="price">Sort by Price</MenuItem>
            </Select>
          </Box>
          <Grid container spacing={2} sx={{ background: "transparent" }}>
            {sortedProducts.slice(0, visibleProducts).map((product) => (
              <Grid item xs={12} sm={6} md={4} key={product.id} sx={{ background: "transparent" }}>
                <Box sx={{ background: "transparent", position: "relative" }}>
                  {product.soldOut && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        background: "red",
                        color: "white",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        fontSize: "0.8rem"
                      }}
                    >
                      SOLD OUT
                    </Box>
                  )}
                  <img
                    src={product.image}
                    alt={product.title}
                    style={{
                      width: "100%",
                      borderRadius: "0px",
                      border: "1px solid",
                      borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1"
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "500", marginTop: "0.5rem", background: "transparent" }}
                  >
                    {product.title}
                  </Typography>
                  <Typography variant="body1" sx={{ background: "transparent" }}>
                    {product.price}
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.7, background: "transparent" }}>
                    Ships in: {product.shipsIn}
                  </Typography>
                  <FullWidthGradientBtnSmall
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: "1rem", background: "transparent" }}
                    onClick={() => handleAddToCart(product)}
                  >
                    Add to Cart
                  </FullWidthGradientBtnSmall>
                </Box>
              </Grid>
            ))}
          </Grid>
          {visibleProducts < products.length && (
            <Box
              sx={{
                textAlign: "center",
                marginTop: "2rem",
                background: "transparent",
                justifyContent: "center"
              }}
            >
              <CustomButton
                variant="contained"
                color="secondary"
                onClick={() => setVisibleProducts((prev) => prev + 12)}
              >
                Load More
              </CustomButton>
            </Box>
          )}
        </Box>

        {/* Right Sidebar (Skyscraper Banner) */}
        <Box sx={{ width: isMobile ? "100%" : "20%", background: "transparent" }}>
          <Box sx={{ background: "transparent", position: "sticky", top: "20px" }}>
            <Box
              component="img"
              src={bannerImage}
              sx={{
                width: "100%",
                borderRadius: "8px",
                objectFit: "cover",
                display: "block",
                background: "transparent"
              }}
            />
          </Box>
        </Box>
      </Box>
      {/* Toast container to display notifications */}
      <ToastContainer position="top-left" autoClose={3000} />
    </Box>
  );
};

export default ShopPage;
