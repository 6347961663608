import React from "react";
import { Button, useMediaQuery, useTheme } from "@mui/material";

const SmallTealButton = ({ children, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Button
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 19px",
        gap: "10px",
        width: isMobile? "100%" : "100px",
        height: "37px",
        background: "#72ECEA",
        borderRadius: "4px",
        color: "#000",
        fontWeight: "bold",
        textTransform: "none",
        "&:hover": {
          background: "#5AD4D2", // Slightly darker teal on hover
        },
      }}
    >
      {children}
    </Button>
  );
};

export default SmallTealButton;
