import React, { useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Navbar from '../../components/Frontend/Header/Navbar';
import Footer from '../../components/Frontend/Footer/PrimaryFooter';
import ThinBanner from '../../components/Frontend/Banner/ThinBanner';
import CustomText from '../../components/Frontend/Tyopgraphy/CustomText';
import CustomText2 from '../../components/Frontend/Tyopgraphy/CustomText2';
import GradientH1 from '../../components/Frontend/Tyopgraphy/GradientH1';
import backgroundImage from "../../assets/img/frontend/productAndPolicies.webp";
import CustomButton from '../../components/Frontend/Button/CustomButton';
import overlayImage from '../../assets/img/frontend/woman.png';
import GradientH3 from '../../components/Frontend/Tyopgraphy/GradientH3';
import SingleAccordion from '../../components/Frontend/Sections/AccordionSection';
import SPSchedule from "../../assets/img/frontend/SeriesScheduleSplendido.jpeg";
import ButtonRow from '../../components/Frontend/Button/ButtonRow';
import WarningBanner from './WarningBanner';

export default function EventsEnquiry({ event }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    const buttons = [
        { "type": "teal", "text": "Players Handbook", "url": `${process.env.PUBLIC_URL}/images/sup_players_handbook_splendido.pdf` },
        { "type": "dark", "text": "Structure", "url": "/events/structure" },
        { "type": "dark", "text": "Enquire Now", "url": "/events/schedule" }
    ];

    return (
        <>
            <Navbar />
            {/* Top Banner Section */}
            <Box
                sx={{
                    marginTop: "-5px",
                    marginBottom: "0px",
                    width: "100%",
                    height: isMobile ? "40vh" : "60vh",
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    color: "#fff",
                    padding: "1rem"
                }}
            >
                <GradientH1>Event</GradientH1>
                <CustomText sx={{ marginBottom: "0px", paddingBottom: "0px" }}>
                    Enquiry
                </CustomText>
                <ButtonRow buttons={buttons} onEnquireNoClick={handleOpenModal} />
                </Box>

            {/* Overlay Section Above the Schedule */}
            <Box sx={{ background: "#000", padding: "0rem 3rem", width: "100%" }}>
                <Box
                    sx={{
                        maxWidth: "1000px",
                        width: "100%",
                        background: "transparent",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        marginBottom: "0rem"
                    }}
                >
                    <Box
                        sx={{
                            background: "transparent",
                            color: "#fff",
                            width: "70%",
                            paddingLeft: '20px',
                            marginBottom: '-50px',
                            textAlign: "left",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            zIndex: 1
                        }}
                    >
                        <EventsEnquiry />
                    </Box>
                </Box>
                <WarningBanner />
                <SingleAccordion />
            </Box>
            <ThinBanner />
            <Footer />
        </>
    );
}
