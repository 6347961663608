import React from 'react';
import Navbar from '../components/Frontend/Header/Navbar';
import HeroBanner from '../components/Frontend/Banner/HeroBanner';
import HeroBannerCountdown from '../components/Frontend/Banner/CountdownBanner';
import Footer from '../components/Frontend/Footer/PrimaryFooter';
import ThinBanner from '../components/Frontend/Banner/ThinBanner';
import LeftTextHeroBanner from '../components/Frontend/Banner/LeftTextBanner';
import ImageCarouselSection from '../components/Frontend/Carousel/ImageCarousel';
import HeadlineText from '../components/Frontend/Text/HeadlineText';
import GridSection from '../components/Frontend/Sections/GridSection';
import HeadlineTextReverseShadow from '../components/Frontend/Text/HeadlineTextReverseShadow';
import BackgroundImage from "../assets/img/frontend/background-purple-full.webp"
import BackgroundImage2 from "../assets/img/frontend/home_achievements_banner.webp";
import ImageCarouselReverseSection from '../components/Frontend/Carousel/ImageCarouselReverse';
import Placeholder from "../assets/img/frontend/placeholder2.webp";
import NewsCarouselSection from '../components/Frontend/Carousel/NewsCarousel';
import ScheduleCarouselSection from '../components/Frontend/Carousel/ScheduleCarousel';
import imageRightNews from "../assets/img/frontend/imageRightNews.png";
import imageLeftNews from "../assets/img/frontend/imageLeftNews.png";

import Win4 from "../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
import Win5 from "../assets/img/frontend/MarkoWinnersPhoto.jpeg";
import Win6 from "../assets/img/frontend/ODWSugen.jpeg";
import Win13 from "../assets/img/frontend/WesBlom.webp"
import Win7 from "../assets/img/frontend/KirithHaria.jpeg";
import Win8 from "../assets/img/frontend/AhmedKarriem.jpeg";
import Win9 from "../assets/img/frontend/Evasan.jpeg";
import Win10 from "../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
import Win11 from "../assets/img/frontend/CaledonGallery/MuazzGani-HighRoller.jpeg";
import Win12 from "../assets/img/frontend/Win3.png";
import Wes from "../assets/img/frontend/WesBlom.webp";
import Benjamin from "../assets/img/frontend/Benjamin.jpeg"
import SAPokerBrand2 from '../assets/img/frontend/SAPoker/SAPokerBrand2.webp'
import SAOpen from "../assets/img/frontend/SAOpenPokerEvent.png";
import SAPokerOpen1 from "../assets/img/frontend/CaledonGallery/CG1.png"
import SAPokerOpen2 from "../assets/img/frontend/Splendido12.png";
import SAPokerOpen4 from "../assets/img/frontend/montecasino_news.webp"
import SAOpen2 from "../assets/img/frontend/SAOpenPokerEvent2.png";
import SAOpen3 from "../assets/img/frontend/SAOpenPokerEvent3.png";

import Blog1 from "../assets/img/frontend/Blog1.png";
import Blog2 from "../assets/img/frontend/Blog2.png";
import Blog3 from "../assets/img/frontend/Blog3.png";

import Win1 from "../assets/img/frontend/Win1.png";
import Win2 from "../assets/img/frontend/Win2.png";
import Win3 from "../assets/img/frontend/Win3.png";

import CoolPlay1 from "../assets/img/frontend/CoolPlay1.png";
import CoolPlay2 from "../assets/img/frontend/SeriesScheduleSplendido.jpeg";
import CoolPlay5 from "../assets/img/frontend/SplendidoLogo.png"
import GarethDuncan from "../assets/img/frontend/GarethDuncanColor.png"

function HomePage(){

    const scheduledItems = [
        {
          id: 0,
          image: `${CoolPlay5}`,
          location: "Monte Casino",
          series: "Splendido Poker Series 12",
          dateFrom: "19 Mar",
          dateTo: "30 Mar 2025",
          type: "Current",
          slug: "splendido-12-poker-series", // Slug for dynamic routing
          title: "Splendido Poker Series 12",
          description: "ShuffleUp Poker is excited to announce the Splendido Poker Series 12 at the incredible Montecasino Events & Hotels in Fourways",
          registrationDeadline: "06 Jan 2025",
          eventLink: "/events/splendido-12-poker-series",
        },
        {
          id: 1,
          image: `${SAPokerOpen1}`,
          location: "Caledon Casino & Resort",
          series: "Poker at Caledon Resort",
          dateFrom: "4 Jun",
          dateTo:"8 Jun 2025",
          type: "Past",
          slug: "poker-at-caledon-resort",
          title: "Poker at Caledon Resort",
          description: "ShuffleUp Poker is excited to kickoff the year with the Caledon Poker Series at the incredible Caledon Casino & Resort",
          registrationDeadline: "06 Jan 2025",
          eventLink: "/events/poker-at-caledon-resort",
        },
        {
          id: 2,
          image: `${SAPokerOpen4}`,
          location: "Monte Casino",
          series: "Monte Casino Invitational Poker Series",
          dateFrom: "23rd July",
          dateTo: "3rd August",
          type: "Current",
          slug: "/events/monte-casino-invitational-poker-series", // Slug for dynamic routing
          title: "Monte Casino Invitational Poker Series",
          description: "Join us at Monte Casino in Johannesburg for an unforgettable poker extravaganza from 23rd July to 3rd August. With events catering to all buy-in levels, there's something for every player, whether you're a seasoned pro or just starting out.",
          registrationDeadline: "06 Jan 2025",
          eventLink: "/events/monte-casino-invitational-poker-series",
        },
        {
          id: 4,
          image: `${SAPokerBrand2}`,
          location: "Monte Casino",
          series: "The SA Poker Open",
          dateFrom: "22 Oct",
          dateTo: "2 Nov 2025",
          type: "Current",
          slug: "/events/the-sa-poker-open", // Slug for dynamic routing
          title: "The SA Poker Open",
          description: "Get ready for the biggest poker event of the year! The SA Poker Open at Montecasino, from 22nd October to 2nd November, is set to break all previous prize pool records with 9 world-class events. This thrilling series promises to bring together top players from around the globe, offering a poker experience like no other. Whether you're chasing massive prizes or simply looking to be part of history, the SA Poker Open will deliver unforgettable action, excitement, and drama at every turn. With top-tier competition and a vibrant atmosphere, this is the must-attend poker event of the year!",
          registrationDeadline: "06 Jan 2025",
          eventLink: "/events/the-sa-poker-open",
        },
      ];

    const carouselItems = [
        {
          image: `${Win5}`,
          title: "Marko Nortje",
          event: "Main Event",
          description: "R800 000",
          awards: "SA Poker Open 2024",
        },
        {
          image: `${Win3}`,
          title: "Sugen Singh",
          event: "Main Event",
          awards: "The Caledon Poker Series 2025",
          description: "R260 000",
        },
        {
          image: `${Wes}`,
          title: "Wes Blom",
          event: "Turbo",
          awards: "The Caledon Poker Series 2025",
          description: "R92,000"
        },
        {
          image: `${Win11}`,
          title: "Muaaz Gani",
          event: "High Roller",
          awards: "The Caledon Poker Series 2025",
          description: "R320,000"
        },
        {
          image: `${Win10}`,
          title: "Gavin Sardini",
          event: "R10k One Day Event",
          awards: "SA Poker Open 2024",
          description: "R120,000"
        },
        {
          image: `${Win7}`,
          title: "Kirith Haria",
          event: "Mugsy Turbo",
          awards: "SA Poker Open 2024",
          description: "R60,000"
        },
        {
          image: `${Win8}`,
          title: "Ahmed Karrim",
          event: "High Roller",
          awards: "SA Poker Open 2024",
          description: "R1,000,000"
        },
        {
          image: `${Benjamin}`,
          title: "Benjamin Lingenfelder",
          event: "Mystery Bounty",
          awards: "SA Poker Open 2024",
          description: "R49,100"
        },
        {
          image: `${Win9}`,
          title: "Evasan Chettier",
          event: "Freezout",
          awards: "SA Poker Open 2024",
          description: "R62,000"
        },
        // {
        //   image: `${Win11}`,
        //   title: "Prenesh Naidoo",
        //   event: "One Life",
        //   awards: "SA Poker Open 2024",
        //   description: "R60,000"
        // },
        // {
        //   image: `${Win12}`,
        //   title: "Benny Qin",
        //   event: "Rebuy Slow Roll",
        //   awards: "Event Winner",
        //   description: "R120,000"
        // },
      ];

      const newsItems = 
        [{
          featuredImage: `${CoolPlay1}`,
          slug: "shuffle-up-caledon-series",
          title: "Shuffle Up Poker’s Caledon Poker Series: A Week of Excitement,Skill, and Camaraderie",
          author: "Adam Scher",
          date: "January 31, 2025",
          excerpt: "The 2025 Caledon Poker Series, hosted by Shuffle Up Poker, took place at the stunning Caledon Hotel and Casino from Wednesday, January 29th to Sunday, February 2nd. The event brought together poker enthusiasts from all corners of the country for an action-packed week filled with high stakes, thrilling tournaments, and plenty of off-the-table relaxation.",
        galleryImage: `${CoolPlay1}`
        },
        {
          featuredImage: `${SAPokerOpen4}`,
          slug: "get-ready-for-splendido",
          title: "Get Ready for the Splendido Poker Series 12 at Monte Casino!",
          author: "Adam Scher",
          date: "January 31, 2025",
          excerpt: "Johannesburg, South Africa – The highly anticipated Splendido Poker Series 12 is set to return to Monte Casino in Johannesburg from March 19th to 30th, 2025, bringing the best of poker to one of South Africa's premier entertainment destinations.",
          galleryImage: `${CoolPlay2}`
        },
        {
        featuredImage: `${GarethDuncan}`,
        slug: "shuffle-up-poker-announces-2025-brand-ambassadors",
        title: "Shuffle Up Poker Announces 2025 Brand Ambassadors: A New Era of Poker in Africa",
        author: "Adam Scher",
        date: "February 28, 2025",
        excerpt: "We’re thrilled to announce the new faces of Shuffle Up Poker for 2025! This year, we’ve brought on four extraordinary individuals as our official brand ambassadors. These talented players and poker advocates will be representing Shuffle Up Poker across South Africa and the greater African continent, bringing excitement, passion, and skill to poker tables everywhere."
        },
        {
          featuredImage: `${Placeholder}`,
          slug: "shuffle-up-presents-brand-refresh",
          title: "Shuffle Up Poker Unveils Full Brand Refresh: A New Era of Poker Excellence",
          author: "Adam Scher",
          date: "February 28, 2025",
          excerpt: "Shuffle Up Poker is excited to announce the launch of our brand-new look and feel! After months of careful planning and collaboration, we’re proud to unveil our full brand refresh for 2025",
          galleryImage: `${Placeholder}`
        },
      ];

    return(
        <>
        <Navbar/>
        <HeroBanner />
        <HeroBannerCountdown />
        <HeadlineText/>
        <GridSection />
        <HeadlineTextReverseShadow/>
        <NewsCarouselSection
        title="News"
        description="Stay up-to-date. Get the latest poker tournaments News here!"
        background={BackgroundImage}
        backgroundPosition="bottom"
        leftImage={imageLeftNews}
        rightImage={imageRightNews}
        carouselItems={newsItems}
        />
        <ScheduleCarouselSection
         title="Upcoming Events"
         description="Get ready for something special—our upcoming events are on the way!"
         carouselItems={scheduledItems}
         background="#191430"
        />
        <ImageCarouselSection  
        title="Past Winners"
        description="Explore our amazing collection of moments captured in stunning imagery."
        background={BackgroundImage2}
        backgroundPosition="top"
        carouselItems={carouselItems}
        />
        <LeftTextHeroBanner />
        <ThinBanner/>
        <Footer/>
        </>
    )
} 
export default HomePage;