import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import TealButton from "../../components/Frontend/Button/TealButton";
import LargeTealButton from "../../components/Frontend/Button/LargeTealButton";
import CustomButton from "../../components/Frontend/Button/CustomButton";

const EventCard = ({ events }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
        background: "transparent",
        justifyContent: "center",
      }}
    >
      {events.map((item, index) => (
        <Box
          key={index}
          sx={{
            background:
              "linear-gradient(180deg, #21174F 16.75%, #0E0B0E 140.05%)",
            borderRadius: "8px",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            width: "300px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Grid container spacing={1}>
            {/* Event Name */}
            <Grid item xs={12} style={{ minHeight: "100px" }}>
              <Typography variant="h6" sx={{ color: "#fff" }}>
                {item.event}
              </Typography>
            </Grid>
            {/* Event Series */}
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ color: "#fff" }}>
                {item.eventSeries}
              </Typography>
            </Grid>
          </Grid>
          {/* Download Brochure Button */}
          {item.Brochure && (
            <Button
              variant="contained"
              color="primary"
              href={item.Brochure}
              target="_blank"
              sx={{
                borderRadius: "8px",
                border: "2px solid #72ECEA",
                color: "#72ECEA",
                backgroundColor: "transparent",
                padding: "8px 16px",
                width: "100%",
                textTransform: "none",
                mt: 2,
                "&:hover": {
                  background: "transparent",  
                  color: "#72ECEA",           
                  border: "2px solid #72ECEA", 
                },
              }}
            >
              Download Brochure
            </Button>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default EventCard;
