import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import axios from 'axios';
import Box from '@mui/material/Box';
import ToastService from '../../Service/toast.service';

function EditUser() {
  const navigate = useNavigate();
  const { id } = useParams();

  /**
   * User use states
   * @since 0.1
   */
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userType, setUserType] = useState('');
  const [linkedCorporate, setLinkedCorporate] = useState('');
  const [linkedTeam, setLinkedTeam] = useState('');
  const [corporates, setCorporates] = useState([]);
  const [teams, setTeams] = useState([]);
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState('none');
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  /**
   * Handle error dialog box open
   * @since 0.1
   */
  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true);
  };

  /**
   * Handle error dialog box close
   * @since 0.1
   */
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  /**
   * useEffect to fetch users and options on load
   * @since 0.1
   */
  useEffect(() => {
    fetchUserAndOptions();
  }, []);

  /**
   * Fetch users and options data
   * @since 0.1
   */
  const fetchUserAndOptions = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    try {
      const [userRes, corporatesRes, teamsRes] = await Promise.all([
        axios.get(`${baseApi}/users/${id}`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }),
        fetch(`${baseApi}/corporates`, {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }),
        fetch(`${baseApi}/teams`, {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }),
      ]);

      if (!corporatesRes.ok || !teamsRes.ok) {
        throw new Error('Failed to fetch corporates or teams');
      }

      const userData = userRes.data;
      const corporatesData = await corporatesRes.json();
      const teamsData = await teamsRes.json();

      console.log('Fetched user data:', userData);
      console.log('Fetched corporates:', corporatesData);
      console.log('Fetched teams:', teamsData);

      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setEmail(userData.email);
      setUsername(userData.username);
      setPassword('');
      setConfirmPassword('');
      setUserType(userData.userType);

      setLinkedCorporate(
        userData.linkedCorporate
          ? userData.linkedCorporate._id || userData.linkedCorporate
          : ''
      );
      setLinkedTeam(
        userData.linkedTeam
          ? userData.linkedTeam._id || userData.linkedTeam
          : ''
      );

      setCorporates(corporatesData);
      setTeams(teamsData);
    } catch (error) {
      console.error('Error fetching user or options:', error.message);
      setErrorList(['Failed to fetch user details or options.']);
      setErrorDialogueBoxOpen(true);
    }
  };

  /**
   * useEffect for password validation and confirmation
   * @since 0.1
   */
  useEffect(() => {
    if (password.length > 0 && password.trim().length <= 6) {
      setPasswordValidationMessage('Password length must be greater than 6 characters');
    } else {
      setPasswordValidationMessage('');
    }

    if (password === confirmPassword) {
      setPasswordMatchDisplay('none');
    } else {
      setPasswordMatchDisplay('block');
    }
  }, [password, confirmPassword]);


  /**
   * Update user information
   * @since 0.1
   * @param {*} event 
   * @returns 
   */
  const updateUser = async (event) => {
    event.preventDefault();

    if (!linkedCorporate || !linkedTeam) {
      setErrorList(['Please select both a corporate and a team.']);
      setErrorDialogueBoxOpen(true);
      return;
    }

    const isValidObjectId = (id) => /^[a-fA-F0-9]{24}$/.test(id);

    const validLinkedTeam = isValidObjectId(linkedTeam) ? linkedTeam : null;
    const validLinkedCorporate = isValidObjectId(linkedCorporate) ? linkedCorporate : null;

    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    const user = {
      firstName,
      lastName,
      email,
      username,
      password,
      confirmPassword,
      userType,
      linkedCorporate: validLinkedCorporate,
      linkedTeam: validLinkedTeam,
    };

    console.log('Submitting updated user data:', user);

    try {
      const response = await axios.patch(`${baseApi}/users/${id}`, user, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      console.log('Server response:', response.data);

      if (response.status === 200 && response.data.message === 'success') {
        ToastService.success('User updated successfully.');
        navigate('/account/users');
      } else {
        ToastService.success("User updated successfully.");
        navigate('/account/users');
      }
    } catch (error) {
      console.error('Error updating user:', error.response?.data || error.message);
      setErrorList(error.response?.data?.errors || ['Failed to update user.']);
      setErrorDialogueBoxOpen(true);
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            {}
            <div className="row">
              <div className="col-lg-12">
                <h3 className="page-title">Edit User</h3>
              </div>
            </div>

            {}
            <div className="row">
              <div className="col-lg-12">
                <form id="editUserForm" name="editUserForm" onSubmit={updateUser}>
                  <div className="row">
                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          name="firstName"
                          className="form-control"
                          type="text"
                          required
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <input
                          name="lastName"
                          className="form-control"
                          type="text"
                          required
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Username <span className="text-danger">*</span>
                        </label>
                        <input
                          name="username"
                          className="form-control"
                          type="text"
                          required
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          name="email"
                          className="form-control"
                          type="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          name="password"
                          className="form-control"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {passwordValidationMessage && (
                          <small className="text-danger">
                            {passwordValidationMessage}
                          </small>
                        )}
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                          name="confirmPassword"
                          className="form-control"
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div
                          style={{
                            display: passwordMatchDisplay,
                            color: 'red',
                          }}
                        >
                          Passwords do not match
                        </div>
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Role</label>
                        <select
                          name="userType"
                          className="form-select"
                          value={userType}
                          onChange={(e) => setUserType(e.target.value)}
                        >
                          <option value="">Select Role</option>
                          <option value="Admin">Admin</option>
                          {/* <option value="Doctor">Doctor</option>
                          <option value="Patient">Patient</option> */}
                          <option value="Corporate">Corporate</option>
                          <option value="Employee">Employee</option>
                          <option value="Customer">Customer</option>
                        </select>
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Linked Corporate</label>
                        <select
                          name="linkedCorporate"
                          className="form-select"
                          value={linkedCorporate}
                          onChange={(e) => setLinkedCorporate(e.target.value)}
                        >
                          <option value="">Select Corporate</option>
                          {corporates.length > 0 ? (
                            corporates.map((corp) => (
                              <option key={corp._id} value={corp._id}>
                                {corp.registeredName || corp.legalName || 'Unnamed Corporate'}
                              </option>
                            ))
                          ) : (
                            <option value="" disabled>
                              No corporates available
                            </option>
                          )}
                        </select>
                      </div>
                    </div>

                    {}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Linked Team</label>
                        <select
                          name="linkedTeam"
                          className="form-select"
                          value={linkedTeam}
                          onChange={(e) => setLinkedTeam(e.target.value)}
                        >
                          <option value="">Select Team</option>
                          {teams.length > 0 ? (
                            teams.map((team) => (
                              <option key={team._id} value={team._id}>
                                {team.teamName || 'Unnamed Team'}
                              </option>
                            ))
                          ) : (
                            <option value="" disabled>
                              No teams available
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  {}
                  <div className="m-t-20 text-left">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={!linkedCorporate || !linkedTeam}
                    >
                      Update User
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {}
          <ErrorDialogueBox
            open={errorDialogueBoxOpen}
            handleToClose={handleDialogueClose}
            ErrorTitle="Error: Edit User"
            ErrorList={errorList}
          />
        </div>
      </div>
    </Box>
  );
}

export default EditUser;
