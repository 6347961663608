import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Modal,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "../Button/CustomButton";

const RegistrationModal = ({ open, handleClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dateFrom: "",
    dateTo: "",
    numberOfPeople: 1,
    comments: "",
    optIn1: false,
    optIn2: false,
  });

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const increasePeople = () => {
    setFormData((prev) => ({ ...prev, numberOfPeople: prev.numberOfPeople + 1 }));
  };

  const decreasePeople = () => {
    setFormData((prev) => ({
      ...prev,
      numberOfPeople: prev.numberOfPeople > 1 ? prev.numberOfPeople - 1 : 1,
    }));
  };

  const baseApiUrl = process.env.API_BASE_URL || "https://shuffleuppoker.com/";

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Prepare payload to send to your SMTP2GO endpoint.
    const emailPayload = {
      to: "anastasia@shuffleuppoker.com", // Change as needed
      subject: "New Registration Enquiry",
      body: `
Name: ${formData.firstName} ${formData.lastName}
Email: ${formData.email}
Phone: ${formData.phoneNumber}
Date From: ${formData.dateFrom}
Date To: ${formData.dateTo}
Number of People: ${formData.numberOfPeople}
Comments: ${formData.comments}
Opt In (Event Updates): ${formData.optIn1 ? "Yes" : "No"}
Opt In (Exclusive Offers): ${formData.optIn2 ? "Yes" : "No"}
      `,
    };

    try {
      const response = await fetch(`${baseApiUrl}api/api/smtp/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailPayload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(`Error sending email: ${errorData.message || "Unknown error"}`);
      } else {
        toast.success("Email sent successfully!");
        handleClose();
      }
    } catch (error) {
      toast.error("Failed to send email.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal open={open} onClose={handleClose} aria-labelledby="registration-modal-title">
        <Box
          sx={{
            position: "absolute",
            width: isMobile ? "100%" : "736px",
            maxHeight: isMobile ? "100%" : "600px",
            height: "100%",
            overflowY: "scroll",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            background: "#0E0B0E",
            boxShadow: "0px 4px 45px rgba(174, 61, 239, 0.45)",
            borderRadius: "4px",
            padding: isMobile ? "38px" : "72px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "39px",
            color: "#fff",
            border: "1px solid",
            borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
            position: "relative",
          }}
        >
          {/* Close Button */}
          <IconButton
            sx={{ position: "absolute", top: "16px", right: "16px", color: "#fff" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          {/* Title */}
          <Typography variant="h4" fontWeight="bold" id="registration-modal-title">
            Enquire Now
          </Typography>

          {/* Form */}
          <Box component="form" onSubmit={handleSubmit} sx={{ background: "transparent", width: "100%", position: "relative" }}>
            <Grid container spacing={3}>
              {/* Name Group */}
              <Grid item xs={12} sm={3}>
                <Typography variant="h6">Name</Typography>
              </Grid>
              <Grid item xs={12} sm={9} sx={{ display: "flex", gap: "1rem" }}>
                <TextField
                  fullWidth
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  value={formData.firstName}
                  onChange={handleChange}
                  sx={inputStyles}
                  disabled={isLoading}
                />
                <TextField
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  value={formData.lastName}
                  onChange={handleChange}
                  sx={inputStyles}
                  disabled={isLoading}
                />
              </Grid>

              {/* Email Group */}
              <Grid item xs={12} sm={3}>
                <Typography variant="h6">Email</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleChange}
                  sx={inputStyles}
                  disabled={isLoading}
                />
              </Grid>

              {/* Phone Number Group */}
              <Grid item xs={12} sm={3}>
                <Typography variant="h6">Phone</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  fullWidth
                  name="phoneNumber"
                  label="Phone Number"
                  variant="outlined"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  sx={inputStyles}
                  disabled={isLoading}
                />
              </Grid>

              {/* Date From */}
              <Grid item xs={12} sm={3}>
                <Typography variant="h6">Date From</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  fullWidth
                  name="dateFrom"
                  type="date"
                  variant="outlined"
                  value={formData.dateFrom}
                  onChange={handleChange}
                  sx={inputStyles}
                  InputLabelProps={{ shrink: true }}
                  disabled={isLoading}
                />
              </Grid>

              {/* Date To */}
              <Grid item xs={12} sm={3}>
                <Typography variant="h6">Date To</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  fullWidth
                  name="dateTo"
                  type="date"
                  variant="outlined"
                  value={formData.dateTo}
                  onChange={handleChange}
                  sx={inputStyles}
                  InputLabelProps={{ shrink: true }}
                  disabled={isLoading}
                />
              </Grid>

              {/* Number of People */}
              <Grid item xs={12} sm={3}>
                <Typography variant="h6">Number of People</Typography>
              </Grid>
              <Grid item xs={12} sm={9} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Button variant="outlined" onClick={decreasePeople} sx={{ color: "#fff", borderColor: "#AE3DEF" }} disabled={isLoading}>
                  -
                </Button>
                <Typography variant="h6">{formData.numberOfPeople}</Typography>
                <Button variant="outlined" onClick={increasePeople} sx={{ color: "#fff", borderColor: "#AE3DEF" }} disabled={isLoading}>
                  +
                </Button>
              </Grid>

              {/* Comments */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="comments"
                  label="Comments"
                  variant="outlined"
                  multiline
                  rows={3}
                  value={formData.comments}
                  onChange={handleChange}
                  sx={inputStyles}
                  disabled={isLoading}
                />
              </Grid>

              {/* Checkboxes */}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="optIn1"
                      checked={formData.optIn1}
                      onChange={handleChange}
                      sx={{ color: "#fff" }}
                      disabled={isLoading}
                    />
                  }
                  label="I agree to receive event updates"
                  sx={{ color: "#fff" }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="optIn2"
                      checked={formData.optIn2}
                      onChange={handleChange}
                      sx={{ color: "#fff" }}
                      disabled={isLoading}
                    />
                  }
                  label="I agree to receive exclusive offers"
                  sx={{ color: "#fff" }}
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <CustomButton type="submit" variant="contained" color="primary" disabled={isLoading}>
                  Enquire
                </CustomButton>
              </Grid>
            </Grid>

            {/* Loader Overlay */}
            {isLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 10,
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

// Common styles for input fields
const inputStyles = {
  borderRadius: "4px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "#ffffff" },
    "&:hover fieldset": { borderColor: "#AE3DEF" },
    "&.Mui-focused fieldset": { borderColor: "#AE3DEF" },
  },
  "& .MuiInputLabel-root": {
    color: "#ffffff",
    "&.Mui-focused": { color: "#AE3DEF" },
  },
};

export default RegistrationModal;
