import React from 'react';
import NewsRoomBanner from '../../components/Frontend/Banner/NewsRoomBanner';
import Navbar from '../../components/Frontend/Header/Navbar';
import Footer from '../../components/Frontend/Footer/PrimaryFooter';
import NewsGrid from '../../components/Frontend/Sections/NewsGrid';
import ThinBanner from '../../components/Frontend/Banner/ThinBanner';
import ImageCarouselSection from '../../components/Frontend/Carousel/ImageCarousel';
import backgroundImage from "../../assets/img/frontend/background-purple-news.webp";
import Placeholder from "../../assets/img/frontend/placeholder2.webp";

import Win1 from "../../assets/img/frontend/Win1.png";
import Win2 from "../../assets/img/frontend/Win2.png";
import Win3 from "../../assets/img/frontend/Win3.png";
import Win4 from "../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
import Win5 from "../../assets/img/frontend/CaledonGallery/MuazzGani-HighRoller.jpeg";
import Win6 from "../../assets/img/frontend/WesBlom.webp";
import Win7 from "../../assets/img/frontend/KirithHaria.jpeg";
import Win8 from "../../assets/img/frontend/AhmedKarriem.jpeg";
import Win9 from "../../assets/img/frontend/Evasan.jpeg";
import Win10 from "../../assets/img/frontend/CaledonGallery/GavinSardhini-R10k.jpeg";
import Win11 from "../../assets/img/frontend/CaledonGallery/MuazzGani-HighRoller.jpeg";
import Win12 from "../../assets/img/frontend/Win3.png";
import Benjamin from "../../assets/img/frontend/Benjamin.jpeg"
import Win14 from "../../assets/img/frontend/MarkoWinnersPhoto.jpeg"

function NewsPage(){

  const carouselItems = [
    {
      image: `${Win14}`,
      title: "Marko Nortje",
      event: "Main Event",
      description: "R800 000",
      awards: "SA Poker Open 2024",
    },
    {
      image: `${Win3}`,
      title: "Sugen Singh",
      event: "Main Event",
      awards: "SA Poker Open 2024",
      description: "R260 000",
    },
    {
      image: `${Win6}`,
      title: "Wes Blom",
      event: "Turbo",
      awards: "The Caledon Poker Series 2025",
      description: "R92,000"
    },
    {
      image: `${Win5}`,
      title: "Muaaz Gani",
      event: "High Roller",
      awards: "The Caledon Poker Series 2025",
      description: "R320,000"
    },
    {
      image: `${Win4}`,
      title: "Gavin Sardini",
      event: "R10k One Day Event",
      awards: "The Caledon Poker Series 2025",
      description: "R120,000"
    },
    {
      image: `${Win7}`,
      title: "Kirith Haria",
      event: "Mugsy Turbo",
      awards: "SA Poker Open 2024",
      description: "R60,000"
    },
    {
      image: `${Win8}`,
      title: "Ahmed Karrim",
      event: "High Roller",
      awards: "SA Poker Open 2024",
      description: "R1,000,000"
    },
    {
      image: `${Benjamin}`,
      title: "Benjamin Lingenfelder",
      event: "Mystery Bounty",
      awards: "SA Poker Open 2024",
      description: "R49,100"
    },
    {
      image: `${Win10}`,
      title: "Evasan Chettier",
      event: "Freezout",
      awards: "SA Poker Open 2024",
      description: "R62,000"
    },
    {
      image: `${Win11}`,
      title: "Prenesh Naidoo",
      event: "One Life",
      awards: "SA Poker Open 2024",
      description: "R60,000"
    },
    // {
    //   image: `${Win12}`,
    //   title: "Benny Qin",
    //   event: "Rebuy Slow Roll",
    //   awards: "Event Winner",
    //   description: "R120,000"
    // },
  ];

    return(
    <>
    <Navbar/>
    <NewsRoomBanner 
        title="Latest News" 
        backgroundImage={backgroundImage}
    />   
    <NewsGrid/>
    <ImageCarouselSection  
        title="Past Winners"
        description="Explore our amazing collection of moments captured in stunning imagery."
        background={backgroundImage}
        backgroundPosition="bottom"
        carouselItems={carouselItems}
     />
    <ThinBanner/>
    <Footer/>
    </>
    );
}
export default NewsPage;