import React from "react";
import { Box, Typography, Grid, Container, useMediaQuery, useTheme } from "@mui/material";
import DecorativePNG from "../../../assets/img/frontend/decorative.png"; // Decorative PNG asset
// Inline Top Left SVG Component
const TopLeftSVG = () => (
  <svg
    width="86"
    height="102"
    viewBox="0 0 86 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ display: "block", background: "none" }}
  >
    <path
      d="M1.64844 101.973V21.4168L85.5202 1.5719"
      stroke="url(#paint0_linear_47_184)"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <defs>
      <linearGradient
        id="paint0_linear_47_184"
        x1="0.0947539"
        y1="51.0212"
        x2="85.8725"
        y2="51.0212"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AC3DED" />
        <stop offset="0.13" stopColor="#A356EC" />
        <stop offset="0.55" stopColor="#89A6EB" />
        <stop offset="0.85" stopColor="#78D8EA" />
        <stop offset="1" stopColor="#72ECEA" />
      </linearGradient>
    </defs>
  </svg>
);

// Inline Bottom Right SVG Component
const BottomRightSVG = () => (
  <svg
    width="86"
    height="102"
    viewBox="0 0 86 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ display: "block", background: "none" }}
  >
    <path
      d="M84.3518 0.0267334V80.5832L0.47998 100.428"
      stroke="url(#paint0_linear_47_183)"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <defs>
      <linearGradient
        id="paint0_linear_47_183"
        x1="85.8964"
        y1="50.9788"
        x2="0.118657"
        y2="50.9788"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#72ECEA" />
        <stop offset="0.49" stopColor="#908FEB" />
        <stop offset="0.83" stopColor="#A454EC" />
        <stop offset="1" stopColor="#AC3DED" />
      </linearGradient>
    </defs>
  </svg>
);

const gridItems = [
  { title: "12 Unique Tournaments", subtitle: "Events", description: "Per Series" },
  { title: "R21 Million", subtitle: "Buy-ins", description: "Per Series" },
  { title: "1700 Per Series", subtitle: "Entries", description: "500 For Main Events" },
  { title: "Cash Prize: R5.6 Million", subtitle: "Record", description: "" },
  { title: "Global Celebrities", subtitle: "Hosted Poker Stars", description: "Worth R100 Million+" }
];

const GridSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        position: "relative",
        background: "linear-gradient(to bottom, #0F0C10 0%, black 100%)",
        padding: isMobile? "0rem 0":"4rem 0",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {/* Decorative PNG Overlay */}
      <Box
        component="img"
        src={DecorativePNG}
        alt="Decorative Overlay"
        sx={{
          position: "absolute",
          background: "transparent",
          top: isMobile? "-450px":"-325px",   // Adjust vertical position as needed
          right: isMobile? "100px":"250px", // 75px from the right edge
          zIndex: 2,    // Place behind the grid content but above the base background
          width: "75px", // Adjust size as needed
          height: "auto",
          opacity: 0.8,
        }}
      />

      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {gridItems.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} sx={{ marginBottom: "80px", justifyContent: "center", alignContent: "center", marginLeft: isMobile? "-15px": "" }}>
              <Box
                sx={{
                  position: "relative",
                  background: "transparent",
                  padding: "1rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                  borderRadius: "10px",
                  textAlign: "center",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  justifyContent: "center",
                  color: "#FFFFFF",
                }}
              >
                {/* Top Left SVG: Reduced size and negative offset for better alignment */}
                <Box
                  sx={{
                    position: "absolute",
                    top: "-25px",
                    left: "30px",
                    width: "30px",
                    height: "30px",
                    background: "none",
                  }}
                >
                  <TopLeftSVG />
                </Box>

                {/* Bottom Right SVG: Reduced size and negative offset for better alignment */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: isMobile? "50px":"75px",
                    right: isMobile? "60px":"30px",
                    width: "30px",
                    height: "30px",
                    background: "none",
                  }}
                >
                  <BottomRightSVG />
                </Box>

                <Box sx={{ background: "transparent", marginLeft: "40px"}}>
                {/* Subtitle */}
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 300,
                    fontSize: "18px",
                    fontFamily: "Oswald, sans-serif",
                    textTransform: "uppercase",
                    color: "#72ECEA",
                    padding: "20px 0 0 0",
                    marginBottom: "0.25rem",
                    opacity: 0.8,
                  }}
                >
                  {item.subtitle}
                </Typography>

                {/* Headline */}
                <Typography variant="h4" fontWeight="bold" sx={{ marginBottom: "0.5rem" }}>
                  {item.title}
                </Typography>

                {/* Description */}
                <Typography variant="body1" sx={{ opacity: 0.7 }}>
                  {item.description}
                </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default GridSection;
