import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

const ImageOnlyCarousel = ({ images, slideDelay = 3000, darkBackground = "#000" }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        background: darkBackground,
        padding: isMobile?'1rem 1.5rem 2rem':'2rem 3rem 4rem',
      }}
    >
      <Swiper
        modules={[Autoplay]}
        autoplay={{ delay: slideDelay, disableOnInteraction: false }}
        loop={true}
        slidesPerView={isMobile? 2:4}
        spaceBetween={20}
        navigation={false}  // No navigation arrows
        pagination={false}  // No pagination dots
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <Box
              component="img"
              src={image}
              alt={`Slide ${index + 1}`}
              sx={{
                width: '100%',
                height: '250px',
                objectFit: 'cover',
                paddingTop: "0px",
                borderRadius: '0px',
                display: 'block',
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default ImageOnlyCarousel;
