
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';

const CancelledPage = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="100vh"
    >
      <CancelIcon sx={{ color: '#E53935', fontSize: 80 }} />
      <Typography variant="h4" mt={2}>
        Payment Cancelled
      </Typography>
      <Typography variant="h6" mt={1}>
        Your payment has been cancelled. If this was a mistake, please try again.
      </Typography>
      <Button
        variant="contained"
        sx={{
          mt: 4,
          backgroundColor: '#170B3B',
          color: '#fff',
          '&:hover': { backgroundColor: '#603f8b' },
        }}
        onClick={() => navigate('/checkout')}
      >
        Back to Checkout
      </Button>
    </Box>
  );
};

export default CancelledPage;
