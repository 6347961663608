import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Box, Button, TextField, Typography, Checkbox, FormControlLabel, Chip } from "@mui/material";
import { Facebook, Google } from "@mui/icons-material";
import ErrorDialogueBox from "../MUIDialogueBox/ErrorDialogueBox";
import ToastService from "../../Service/toast.service";
import BackgroundImage from "../../assets/img/frontend/background-purple-fullSize.png"; // Background Image

function SignupPage() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState("none");
  const [passwordValidationMessage, setPasswordValidationMessage] = useState("");
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const handleDialogueOpen = () => setErrorDialogueBoxOpen(true);
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const baseApi = process.env.REACT_APP_API_BASE_URL || "http://localhost:5001/";

    const user = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      password,
      confirmPassword,
      userType: "Customer",
    };

    try {
      const response = await fetch(`${baseApi}/api/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(user),
      });

      const data = await response.json();
      if (response.ok && data.message === "success") {
        ToastService.success("Successfully signed up.");
        navigate("/");
      } else {
        setErrorList(data.errors || ["An unexpected error occurred."]);
        handleDialogueOpen();
      }
    } catch (error) {
      console.error("Error during signup:", error);
      setErrorList(["Failed to connect to the server. Please try again later."]);
      handleDialogueOpen();
    }
  };

  useEffect(() => {
    if (password.length > 0 && password.trim().length <= 6) {
      setPasswordValidationMessage("Password length must be greater than 6 characters");
    } else {
      setPasswordValidationMessage("");
    }
    setPasswordMatchDisplay(password === confirmPassword ? "none" : "block");
  }, [password, confirmPassword]);

  return (
    <Box
      sx={{
        width: "100vw",
        overflowY: "scroll",
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Signup Box */}
      <Box
        sx={{
          position: "relative",
          width: "412px",
          height: "650px",
          background: "#0E0B0E",
          borderRadius: "12px",
          padding: "2rem",
          marginTop: "4rem",
          marginBottom: "4rem",
          boxShadow: "0px 4px 45px rgba(174, 61, 239, 0.45)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Coming Soon Badge */}
        <Chip 
          label="Coming Soon" 
          sx={{ 
            position: "absolute", 
            top: "10px", 
            left: "0px", 
            backgroundColor: "#ff9800", 
            borderRadius: 0,
            color: "#fff", 
            fontWeight: "bold" 
          }} 
        />

        {/* Title */}
        <Typography variant="h5" sx={{ color: "#fff", fontWeight: "bold", marginBottom: "1rem" }}>
          Create an Account
        </Typography>

        {/* Signup Form */}
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Box sx={{ background: "transparent", display: "flex", gap: "1rem", marginBottom: "1rem" }}>
            <TextField fullWidth label="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} sx={inputStyles} readOnly/>
            <TextField fullWidth label="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} sx={inputStyles} readOnly/>
          </Box>
          <TextField fullWidth label="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} sx={inputStyles} readOnly/>
          <TextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ ...inputStyles, marginTop: "1rem" }}
            readOnly
          />
          <Typography sx={{ color: "red", fontSize: "12px", marginTop: "5px" }}>{passwordValidationMessage}</Typography>
          <TextField
            fullWidth
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{ ...inputStyles, marginTop: "1rem" }}
            readOnly
          />
          <Typography sx={{ color: "red", fontSize: "12px", marginTop: "5px", display: passwordMatchDisplay }}>
            Passwords do not match
          </Typography>

          {/* Terms and Conditions Checkbox */}
          <FormControlLabel
            control={<Checkbox required sx={{ color: "#72ECEA" }} />}
            label={<Typography sx={{ color: "#fff", fontSize: "14px" }}>I agree to the terms and conditions</Typography>}
            sx={{ marginTop: "1rem" }}
          />

          {/* Signup Button */}
          <Button
            type="submit"
            variant="contained"
            sx={{
              width: "100%",
              marginTop: "1.5rem",
              background: "linear-gradient(88.1deg, #72ECEA 47.05%, #AE3DEF 116.99%)",
              fontWeight: "bold",
              borderRadius: "8px",
              color: "#000"
            }}
          >
            Sign Up
          </Button>
        </form>

        {/* Redirect to Login */}
        <Typography sx={{ color: "#fff", fontSize: "0.9rem", marginTop: "1rem" }}>
          Already have an account?{" "}
          <NavLink to="/login" style={{ color: "#72ECEA", textDecoration: "none", fontWeight: "bold" }}>
            Sign in
          </NavLink>
        </Typography>

        {/* Error Dialog */}
        <ErrorDialogueBox open={errorDialogueBoxOpen} handleToClose={handleDialogueClose} ErrorTitle="Signup Error" ErrorList={errorList} />
      </Box>
    </Box>
  );
}

// Common input field styles
const inputStyles = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: "#fff" },
    "&:hover fieldset": { borderColor: "#72ECEA" },
    "&.Mui-focused fieldset": { borderColor: "#72ECEA" },
  },
  "& .MuiInputLabel-root": {
    color: "#fff",
    "&.Mui-focused": { color: "#72ECEA" },
  },
  input: { color: "#fff" },
};

export default SignupPage;
