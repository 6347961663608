import React from 'react';
import Footer from '../components/Frontend/Footer/PrimaryFooter';
import Navbar from '../components/Frontend/Header/Navbar';
import ThinBanner from '../components/Frontend/Banner/ThinBanner';
import LeftTextHeroBanner from '../components/Frontend/Banner/LeftTextBanner';
import LiveFeedSection from '../components/Frontend/Sections/LiveFeedSection';
import NewsRoomBanner from '../components/Frontend/Banner/NewsRoomBanner';
import backgroundImage from "../assets/img/frontend/background-purple-news.webp";
function LiveFeedPage(){

    return(
        <>
        <Navbar/>
        <NewsRoomBanner 
        accent="Live"
        title="Reporting" 
        backgroundImage={backgroundImage}
        />           
        <LiveFeedSection/>
        <LeftTextHeroBanner/>
        <ThinBanner/>
        <Footer/>
        </>
    );
}
export default LiveFeedPage;