import React from "react";
import { Box, Typography, Container } from "@mui/material";
import BackgroundImage from "../../../assets/img/frontend/background-purple-dark.webp";
import CenteredImage from "../../../assets/img/frontend/small-s.svg";
import CustomText from "../Tyopgraphy/CustomText";
import useResponsive from "../../../utils/useResponsive";

const ContainerBanner = () => {
  const isMobile = useResponsive();

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: isMobile ? "50vh" : "80vh",
        maxHeight: isMobile ? "50vh" : "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: 0,
        background: "transparent",
      }}
    >
      {/* Background Image */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: isMobile? "100%" : "100%",
          maxHeight: isMobile? "100%" : "100%",
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: -2,
        }}
      />

      {/* Gradient Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: isMobile? "100%" : "100%",
          maxHeight: isMobile? "100%" : "100%",
          background: "linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%)",
          zIndex: -1,
        }}
      />

      {/* Content */}
      <Container maxWidth="md">
        {/* Centered Image */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: isMobile ? "1rem" : "2rem",
            background: "transparent",
          }}
        >
          <img
            src={CenteredImage}
            alt="Centered"
            style={{
              maxWidth: isMobile ? "80%" : "100%",
              height: "auto",
            }}
          />
        </Box>

        {/* Headline */}
        <CustomText
          variant={isMobile ? "h4" : "h2"}
          fontWeight="bold"
          sx={{ color: "#FFFFFF", marginBottom: isMobile ? "0.5rem" : "1rem" }}
        >
          Contact Us
        </CustomText>
      </Container>
    </Box>
  );
};

export default ContainerBanner;
