import React from "react";
import { Box, Typography, Button, useMediaQuery, useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import GradientH3 from "../Tyopgraphy/GradientH3";
import { Link } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import CustomButton from "../Button/CustomButton";
import { ReactComponent as OverlayIcon } from "../../../assets/img/frontend/small-s.svg";

const truncateDescription = (text, wordLimit = 30) => {
  if (!text) return ""; // Ensure it handles undefined or null values
  return text.split(" ").slice(0, wordLimit).join(" ") + (text.split(" ").length > wordLimit ? "..." : "");
};

const ScheduleCarouselSection = ({ title, description, background, carouselItems = [] }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // Detect if background is an image URL or a solid color
  const isImage =
    typeof background === "string" &&
    (background.startsWith("http") || /\.(jpg|jpeg|png|webp|gif)$/i.test(background));

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        padding: "4rem 0",
        background: isImage ? "transparent" : background,
      }}
    >
      {/* Background Image */}
      {isImage && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            zIndex: -2,
          }}
        />
      )}

      {/* Section Title */}
      <GradientH3 variant="h3" fontWeight="bold">
        {title}
      </GradientH3>

      {/* Section Description */}
      <Typography
        variant="h6"
        sx={{
          opacity: 1,
          maxWidth: "600px",
          margin: "0 auto 2rem",
          color: "#fff",
        }}
      >
        {description}
      </Typography>

      {/* Swiper Carousel */}
      <Box sx={{ background: "transparent", position: "relative", width: "100%", maxWidth: "1200px", padding: "1rem" }}>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={20}
          slidesPerView={isMobile? 1:3}
          navigation={{
            nextEl: ".custom-swiper-next",
            prevEl: ".custom-swiper-prev",
          }}
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          loop
        >
          {carouselItems.map((item, index) => (
            <SwiperSlide key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: "transparent",
                  padding: "1.5rem",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
              >
                {/* Image Container */}
                <Box
                  sx={{
                    position: "relative", // Make the image container relative
                    width: "100%",
                    maxWidth: "600px",
                    background: "transparent",
                    marginBottom: "1rem",
                  }}
                >
                  <Box
                    component="img"
                    src={item.image}
                    alt={item.title}
                    sx={{
                      width: "100%",
                      height: "225px",
                      objectFit: "cover",
                      paddingTop: "0px",
                      border: "1px solid",
                      background: "transparent",
                      borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
                      borderRadius: "0px",
                      display: "block",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute", // Position relative to the image container
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "30px",
                      background: "rgba(0, 0, 0, 0.5)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <OverlayIcon style={{ marginTop: "-30px", width: "30px", height: "30px" }} />
                  </Box>
                </Box>

                {/* Event Title */}
                <Typography variant="h5" fontWeight="bold" gutterBottom sx={{ color: "white" }}>
                  {item.title}
                </Typography>

                {/* Event Location */}
                <Typography variant="body1" sx={{ color: "#72ECEA", fontWeight: "bold", textTransform: 'uppercase' }}>
                  {item.location || "Location Unavailable"}
                </Typography>

                {/* Event Date */}
                <Typography variant="body2" sx={{ color: "white", opacity: 0.8, marginBottom: "1rem" }}>
                  {item.dateFrom ? `${item.dateFrom} - ${item.dateTo || "TBD"}` : "Date Unavailable"}
                </Typography>

                {/* Read More Button */}
                <CustomButton
                  component={Link}
                  to={item.slug || "/events"}
                  variant="contained"
                >
                  Read More
                </CustomButton>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Custom Navigation Arrows */}
        <Box
          className="custom-swiper-prev"
          sx={{
            position: "absolute",
            top: "50%",
            left: "-40px",
            background: "transparent",
            transform: "translateY(-50%)",
            cursor: "pointer",
            zIndex: 10,
            fontSize: "30px",
            color: "teal",
          }}
        >
          <FaChevronLeft />
        </Box>
        <Box
          className="custom-swiper-next"
          sx={{
            position: "absolute",
            top: "50%",
            right: "-40px",
            transform: "translateY(-50%)",
            cursor: "pointer",
            zIndex: 10,
            background: "transparent",
            fontSize: "30px",
            color: "teal",
          }}
        >
          <FaChevronRight />
        </Box>
      </Box>
    </Box>
  );
};

export default ScheduleCarouselSection;
