import React, { useState } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Container,
  Grid,
  Card,
  CardContent,
  Button,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const events = [];

const LiveFeedSection = () => {
  const [selectedEvent, setSelectedEvent] = useState("All");
  const [sortBy, setSortBy] = useState("date");
  const [expandedEvent, setExpandedEvent] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const openEvent = expandedEvent ? events.find((e) => e.id === expandedEvent) : null;
  const openEventName = openEvent ? openEvent.title : "All";

  const filteredEvents = selectedEvent === "All" ? events : events.filter((event) => event.type === selectedEvent);

  const sortedEvents = [...filteredEvents].sort((a, b) => {
    if (sortBy === "asc") return a.title.localeCompare(b.title);
    if (sortBy === "desc") return b.title.localeCompare(a.title);
    if (sortBy === "date") return new Date(a.date) - new Date(b.date);
    return 0;
  });

  return (
    <Box sx={{ background: "#000", padding: "1rem 0 6rem", color: "#fff" }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            background: "transparent",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "0.5rem",
          }}
        >
          <Box sx={{ width: isMobile ? "100%" : "80%", textAlign: "left", background: "transparent" }}>
            <Typography variant="h5" fontWeight="bold" sx={{ textTransform: "uppercase" }}>
              <i>Stay in the Action</i>
            </Typography>
            <Typography variant="body1" fontWeight="bold" sx={{ fontWeight: "400", opacity: "0.8" }}>
              With ShuffleUp Poker’s live reporting, dive into the action now below!
            </Typography>
          </Box>

          <Box sx={{ background: "transparent", display: "flex", gap: 2, alignItems: "center" }}>
            <Select
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value)}
              sx={{
                border: "1px solid",
                borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
                color: "#fff",
                borderRadius: "4px",
                padding: "4px 8px",
              }}
            >
              <MenuItem value="All">All Events</MenuItem>
              <MenuItem value="Tournament">Tournaments</MenuItem>
              <MenuItem value="Cash Game">Cash Games</MenuItem>
            </Select>

            <Select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              sx={{
                border: "1px solid",
                borderImage: "linear-gradient(0deg, #72ECEA 0%, #AE3DEF 100%) 1",
                color: "#fff",
                borderRadius: "4px",
                padding: "4px 8px",
              }}
            >
              <MenuItem value="asc">Sort A-Z</MenuItem>
              <MenuItem value="desc">Sort Z-A</MenuItem>
              <MenuItem value="date">Sort by Date</MenuItem>
            </Select>
          </Box>
        </Box>

        <Box
          sx={{
            background: "transparent",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "2rem",
            textAlign: "left",
          }}
        >
          <Typography variant="subtitle1" sx={{ color: "#72ECEA", fontWeight: "bold" }}>
            Event:
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: "600", fontSize: "21px" }}>
            {openEventName}
          </Typography>
        </Box>

        {sortedEvents.length === 0 ? (
          <Typography variant="body1">No events to show - exciting games coming soon!</Typography>
        ) : (
          sortedEvents.map((event) => (
            <Box sx={{ background: "transparent", marginBottom: "1rem" }} key={event.id}>
              <Box
                sx={{
                  background: "transparent",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "1rem 0",
                  borderBottom: "1px solid #AE3DEF",
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  {event.title}
                </Typography>
                <Button
                  onClick={() => setExpandedEvent(expandedEvent === event.id ? null : event.id)}
                  sx={{
                    color: "#fff",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    "&:hover": { color: "#AE3DEF" },
                  }}
                >
                  {expandedEvent === event.id ? "Close" : "Open"}
                </Button>
              </Box>

              {expandedEvent === event.id && (
                <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        padding: "2rem",
                        maxHeight: "800px",
                        overflowY: "auto",
                        background: "linear-gradient(180deg, rgba(33, 23, 79, 0.5) 16.75%, rgba(14, 11, 14, 0.5) 140.05%)",
                        color: "#fff",
                      }}
                    >
                      <CardContent>
                        {event.feed.length === 0 ? (
                          <Typography variant="body1">No current live feeds to show</Typography>
                        ) : (
                          event.feed.map((item) => (
                            <Box key={item.id} sx={{ marginBottom: "1rem", background: "transparent" }}>
                              <Typography variant="caption" sx={{ opacity: 0.7 }}>
                                {item.timestamp}
                              </Typography>
                              {item.type === "text" && (
                                <Typography variant="body1">{item.content}</Typography>
                              )}
                              {item.type === "image" && (
                                <Box
                                  component="img"
                                  src={item.content}
                                  alt="Event Image"
                                  sx={{ width: "100%", borderRadius: "8px" }}
                                />
                              )}
                              {item.type === "video" && (
                                <Box component="video" controls sx={{ width: "100%", borderRadius: "8px" }}>
                                  <source src={item.content} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </Box>
                              )}
                            </Box>
                          ))
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
            </Box>
          ))
        )}
      </Container>
    </Box>
  );
};

export default LiveFeedSection;
