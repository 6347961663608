import React from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";

const CustomH3White = ({ children, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Typography
      variant="h3"
      {...props}
      sx={{
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        fontStyle: "italic",
        fontWeight: 800,
        marginTop: "-20px",
        paddingTop: "0px",
        fontSize: isMobile?"42px":"52.6952px",
        textTransform: "uppercase",
        background: "white",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        flex: "none",
        order: "1",
        alignSelf: "stretch",
        flexGrow: "0",
      }}
    >
      {children}
    </Typography>
  );
};

export default CustomH3White;
